import { useRef, useState } from "react";
import {
  useCustomizeColorOfHigilightedPoint,
  useHandleClickOnPoint,
  useInitializeCustomLayersListControl,
  useInitializeMap,
  usePaintAllGlobalLayersOnMap,
  usePaintAllPointsOnMap,
} from "../hooks";

import TrashIcon from "assets/icons/HeroIcons/TrashIcon";

import { CustomizedSwitches } from "view/components/CustomizedSwitches";
import Map from "./Map";
import useLocationFormatter from "hooks/useLocationFormatter";

type QuestionElemet = {
  title?: string;
  name: string;
  isRequired: boolean;
  id: string;
  selectedPoints: any[];
  selectedLayers: any[];
  answer: any[];
  selectMultiple: boolean;
  description?: string;
  groupQuestionName?: string;
};
interface I_PointPicker {
  editMode: boolean;
  element: QuestionElemet;
  answer: any[];
  setFieldValue: any;
  eleName: string;
  required?: boolean;
  error?: any;
  touched?: any;
  label?: string;
  panel?: any;
}

const PointPicker = ({
  editMode,
  element,
  answer,
  setFieldValue,
  eleName,
  required,
  error,
  touched,
  label,
  panel,
}: I_PointPicker) => {
  const [doShowMap, setDoShowMap] = useState(false);

  // handlers
  const handleClickOnLayerRemoveBtn = (layerId: string) => {
    const updatedAnswers = answer.filter((ans: any) => ans.id !== layerId);
    // draw.delete([layerId]);
    // console.log({shapes:draw.getAll(),layerId})
    setFieldValue(eleName, updatedAnswers);
  };

  const isVantagePoint = element.name === "VantagePoint";

  return (
    <div className="p-4 rounded-lg dark:bg-secondaryLight bg-bgPrimaryLight" id={`${element?.groupQuestionName || element?.name}${panel?.sectionId}`}>
      <div className="flex justify-between items-start text-textAnswer mb-3">

        <div className="flex flex-col flex-1">
          <Name
            editMode={editMode}
            label={element.title}
            name={element.name}
            required={element.isRequired}
          />

          <ListView list={answer} hideList={editMode} />
        </div>

        {isVantagePoint &&
          <>
            <div className="flex flex-col flex-1">
              <Name
                editMode={editMode}
                label="Latitude"
                name={element.name}
                required={false}
              />

              <LatLng value={answer?.[0]?.mapData?.features?.[0]?.geometry?.coordinates[0] || ""} isLongitude={false} />
            </div>

            <div className="flex flex-col flex-1">
              <Name
                editMode={editMode}
                label="Longitude"
                name={element.name}
                required={false}
              />
              <LatLng value={answer?.[0]?.mapData?.features?.[0]?.geometry?.coordinates[1] || ""} isLongitude={true} />
            </div>
          </>
        }

        <div className="flex-1 flex-1 flex justify-end">
          <CustomizedSwitches
            label="View map"
            onChange={(e: any) => setDoShowMap(e.target.checked)}
            labelClassName="text-secondary"
          />
        </div>

      </div>

      {editMode && (
        <div className="flex flex-col w-full gap-2 px-3 py-6">
          {/* {answer?.length > 0 && (
            <h3 className="mb-1 text-base font-semibold text-textMid font-Overpass">
              Draw polygon
            </h3>
          )} */}

          {answer &&
            Array.isArray(answer) &&
            answer?.map((layer: any) => {
              return (
                <LayerEditBar
                  key={layer.id}
                  defaultValue={layer.name}
                  onRemove={() => handleClickOnLayerRemoveBtn(layer.id)}
                />
              );
            })}
        </div>
      )}
      {doShowMap && (
        <Map
          answer={answer}
          eleName={eleName}
          setFieldValue={setFieldValue}
          editMode={editMode}
          element={element}
        />
      )}
      {editMode && <Error error={error} />}
    </div>
  );
};

export { PointPicker };

const Name = ({ editMode, label, name, required }: any) => {
  return (
    <p
      className={`w-full pb-1 text-md text-left capitalize font-Overpass ${editMode
        ? "text-primary dark:text-caption font-medium "
        : "text-primary dark:text-caption font-regular "
        }`}
    >
      {label || name} {required && <span>*</span>}
    </p>
  );
};

const Error = ({ error }: any) => {
  return (
    <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
      {error && (
        <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
          {error}
        </p>
      )}
    </div>
  );
};

const ListView = ({ list, hideList }: any) => {
  if (Array.isArray(list) && !hideList) {
    return (
      <ul className="list-inside list-disc pb-1 pr-5">
        {list.map((item: any, index: number) => {
          return (
            <li key={item.id} className="font-Overpass text-textAnswer break-words">
              {item.name}
            </li>
          );
        })}
      </ul>
    );
  }
  return !hideList && <p className="text-textNoSelection font-normal font-Overpass text-[15px] italic">
    (No Selection)
  </p>;
};

const LayerEditBar = ({ defaultValue, onRemove }: any) => {
  return (
    <div className="flex w-full">
      <input
        className="flex-1 px-3 py-2 mr-4 text-base border rounded outline-none border-lineDark text-textNormal font-Overpass dark:text-caption "
        placeholder="Region name"
        defaultValue={defaultValue}
        readOnly={true}
      />
      <button type="button" onClick={onRemove}>
        <TrashIcon />
      </button>
    </div>
  );
};


const LatLng = ({ value, isLongitude }: any) => {
  const { formatLocation } = useLocationFormatter();
  return (
    <div className="flex w-full">
      <p>{value && formatLocation(value, "DMM", isLongitude)}</p>
    </div>
  );

}