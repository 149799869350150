import getAllBrands from "./getAllBrands";
import getBrand from "./getBrand";
import getAllOrganizations from "./getAllOrganizations";
import addBrand from "./addBrand";
import archiveOrRestoreBrand from "./archiveOrRestoreBrand";
import updateBrand from "./updateBrand";
import getDnsRecords from "./getDnsRecords";

const Brands = {
  getAllBrands,
  getBrand,
  getAllOrganizations,
  addBrand,
  updateBrand,
  archiveOrRestoreBrand,
  getDnsRecords
};

export default Brands;
