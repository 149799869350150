function __drawShapeByGeoJsonOnMap__(draw: any, drawId: any, geoJson: any) {
  if (!draw || !drawId || !geoJson) {
    return;
  }
  // get previous drawn shapes features and append new into it
  const currentFeatures = draw.getAll();

  const comingFeatures = geoJson?.features.map((f: any) => {
    if (!f.id) {
      f.id =
        "feature#" + Math.random().toString(36).substr(2, 9) + "#" + drawId;
    } else if (f.id) {
      f.id = "feature#" + f.id + "#" + drawId;
    }

    return f;
  });

  const isFeatureIdPresent = (feature: any, currentFeatures: any) => {
    return currentFeatures.some(
      (currentFeature: any) => currentFeature.id === feature.id
    );
  };

  const uniqueFeatures = comingFeatures.filter(
    (feature: any) => !isFeatureIdPresent(feature, currentFeatures.features)
  );

  currentFeatures.features = [...currentFeatures.features, ...uniqueFeatures];

  draw.set(currentFeatures);
}

export { __drawShapeByGeoJsonOnMap__ };
