import { useState } from "react";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import brandPlaceHolder from "assets/images/Placeholders/brandPlaceholderImg.png";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const [colorsForStatus, setColorsForStatus] = useState<string | null>();

  // function to change the backGround color of and text color of values
  const getTextBackGroundColors = (name: string): string => {
    switch (name) {
      case "private":
        return "bg-primaryExtraLight text-primaryMid";
      case "public":
      case "completed":
        return "bg-tertiaryExtraLight text-tertiaryMid";
      case "pending":
        return "bg-accent_1Light text-accent_1Dark";
      default:
        return "white";
    }
  };

  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "brand"
  ) {
    return (
      <div className="flex justify-start w-[250px] items-center relative group cursor-pointer">
        <img
          src={cellValue?.image?.thumb || cellValue?.image?.original || brandPlaceHolder}
          alt=""
          height="100"
          width="100"
          className="object-contain mx-2 Img_user_Data"
          style={{ flexShrink: 0 }}
        />
        <div
          style={{ flexWrap: "wrap" }}
          className="flex flex-col flex-wrap w-full justify-start items-start relative gap-0.5"
        >
          <p className="text-sm w-[200px] text-left break-all text-textMid capitalize dark:group-hover:text-secondary">
            {ellipsize(`${cellValue?.name}`, 60)}{" "}
          </p>
        </div>
      </div>
    );
  } else if (
    (columnDef.id === "type" || columnDef.id === "domainPropagation") &&
    cellValue !== null
  ) {
    return (
      <div className="flex justify-start">
        <p
          className={`text-sm py-1 px-2 rounded-lg ${columnDef.id === "domainPropagation" ? "capitalize" : ""} ${getTextBackGroundColors(cellValue)}`}
        >
          {cellValue}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <p
        className={`text-sm text-left text-textMid whitespace-nowrap dark:group-hover:text-secondary`}
      >
        {ellipsize(`${cellValue}`, 60)}
      </p>
    );
  } else {
    return null;
  }
};
