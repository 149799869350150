import React from "react";
// Components
import { CustomizedSwitches } from "view/components/CustomizedSwitches";

interface IProps {
  handleChange: any;
  setFieldValue: any;
  values: any;
  viewMode?: boolean;
}

export const Interoperability: React.FC<IProps> = ({
  handleChange,
  setFieldValue,
  values,
  viewMode
}) => {
  return (
    <div>
      <p className="text-lg font-semibold font-Overpass text-secondaryMid py-2">
        ISN Interoperability
      </p>
      <p className="text-sm text-secondaryMid font-Overpass py-1">
        Enable or disable interoperability. By enabling interoperability
        observers in this brand will be able to see and interact with the ISN
        programs they are a part of in their branded app and vice versa. When
        disabled, observers will only see programs of this specific brand in
        their branded app.
      </p>
      <div className="flex justify-start ml-[-33px] items-center">
        <CustomizedSwitches
          checked={values.interoperability}
          onChange={(e) => {
            const { checked } = e.target;
            setFieldValue("interoperability", checked);
          }}
          value="Interoperability"
          label=""
          className="disabled:cursor-not-allowed font-Overpass"
          disabled={false || viewMode}
        />
        <p className="text-sm text-secondaryMid font-Overpass ml-3">Interoperability</p>
      </div>
    </div>
  );
};
