import React, { useEffect, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { setThemeLoaded } from "store/theme/reducer.actions";

interface ThemeProviderProps {
    children: ReactNode;
}

interface ThemeColors {
    primary?: string;
    secondary?: string;
    primaryExtraLight?: string;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    const reduxThemeColors = useSelector<RootState, ThemeColors>(
        (state) => state.theme.colors
    );
    const dispatch: any = useDispatch();

    useEffect(() => {
        const root = document.documentElement;

        // Set default values in case Redux doesn't provide them
        const primary = reduxThemeColors?.primary || "#005C89";
        const primaryExtraLight = reduxThemeColors?.primaryExtraLight || "#EBF2F6";

        // const secondary = reduxThemeColors?.secondary || "#202123";

        // Update CSS variables dynamically
        root.style.setProperty("--primary", primary);
        root.style.setProperty("--primaryExtraLight", primaryExtraLight);

        // root.style.setProperty("--secondary", secondary);
        
        dispatch(setThemeLoaded());
    }, [reduxThemeColors]);

    return children;
};

export default ThemeProvider;
