import React, { useCallback, useEffect } from "react";
// React Lib
import Dropzone from "react-dropzone";
// Assets
import { CloudUploadIcon, TrashIcon } from "assets/icons/HeroIcons";
import brandPlaceHolder from "assets/images/Placeholders/brandPlaceHolder.png";
import apiLibrary from "services/api";
import { defaultImage } from "./defaultImage";
import { Toasts } from "view/components/Toasts";

interface IProps {
  setFieldValue: any;
  values: any;
  errors: any;
  acceptedFileTypes: any;
  text: string;
  fieldValue: string;
}

export const DropZone: React.FC<IProps> = ({
  setFieldValue,
  values,
  errors,
  acceptedFileTypes,
  text,
  fieldValue,
}) => {
  // Function to handle Drag and Drop files
  const handleOnDropFiles = useCallback(
    async (acceptedFiles: any, fileRejections: any) => {

      fileRejections.forEach((file: any) => {
        if (file.errors[0].code === "file-too-large") {
          Toasts.error(`File is too large. Max size is 25MB. File size is ${(file.file.size / 1024 / 1024).toFixed(2)} MB.`);
        }
      });

      if (acceptedFiles.length > 0) {
        const newFile = {
          file: acceptedFiles[0],
        };
        if (newFile) {
          try {
            const uploadResponse = await apiLibrary.file.fileUpload(
              newFile.file
            );
            setFieldValue(fieldValue, uploadResponse.data);
          } catch { }
        }
        // Update the field with the new file
      }
    },
    [setFieldValue]
  );

  // useEffect(() => {
  //   if (!values?.id) {
  //     setFieldValue(fieldValue, defaultImage);
  //   }
  // }, [values]);
  const maxSize = 25 * 1024 * 1024; // 25 MB in bytes

  return (
    <div className="w-full flex gap-4 items-start">
      <div className="w-52">
        {/* Drop zone to select the image or icon */}
        <Dropzone onDrop={handleOnDropFiles} accept={acceptedFileTypes} maxSize={maxSize}>
          {({ getRootProps, getInputProps }) => (
            <div>
              <div
                className={`w-full flex flex-col justify-center items-center cursor-pointer px-4 py-3 rounded-lg bg-bgWhite border  dark:bg-secondaryLight ${errors
                  ? "border-accent_1Dark "
                  : "border-lineDark dark:border-lineLight"
                  }  border-dashed`}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="w-full">
                  <div className="flex items-center justify-center">
                    <CloudUploadIcon />
                  </div>
                  <p
                    className={`font-Overpass text-center mt-1 ${errors ? "text-accent_1Dark " : "dark:text-textMain"
                      } text-sm text-secondaryMid`}
                  >
                    Drag and drop your files here or click in this area
                  </p>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        <p className="text-xs text-secondaryMidLight font-Overpass leading-4 mt-2">{text}</p>
        {fieldValue === "faviconImageId" && <a href="https://realfavicongenerator.net/" target="blank" className="text-primary font-Overpass font-bold text-xs">https://realfavicongenerator.net/.</a>}
      </div>
      {/* Display the selected image or Icon */}
      <FileBox
        img={values}
        setFieldValue={setFieldValue}
        fieldValue={fieldValue}
      />
    </div>
  );
};
// File box to display the selected logo or Icon
const FileBox = ({ img, setFieldValue, fieldValue }: any) => {
  return (
    <div className="flex flex-col justify-start gap-1 w-32 mr-2 relative">
      <div className="gap-1 p-1 border border-lineDark dark:border-lineLight border-dashed rounded-lg bg-bgWhite dark:bg-secondaryLight dark:border-lineLight">
        <div className="relative w-full h-[87px]">
          <div className="w-full h-full object-cover">
            <img
              src={img?.file?.thumb || brandPlaceHolder}
              alt=""
              className={`w-full h-full mx-auto  object-cover
                  `}
            />
          </div>
        </div>
        {/* Button to delete the selected Img */}
        <button
          className="absolute right-0 bottom-[-30px]"
          type="button"
          onClick={() => setFieldValue(fieldValue, null)}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};
