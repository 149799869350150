import React from "react";
import brandPlaceHolder from "assets/images/Placeholders/brandPlaceholderImg.png";

interface IProps {
    values: any;
}

export const ViewLogos: React.FC<IProps> = ({ values }) => {
    return (
        <div className="w-full bg-bgWhite py-6 rounded-lg flex">

            <div className="w-2/4 flex items-center gap-3">
                <p className="text-sm font-Overpass text-textMidLight">Logo</p>
                <div className="w-12 h-12 p-2 overflow-hidden rounded-full">
                    <img
                        className="w-full h-full object-contain rounded-full"
                        src={values.logoId?.file?.thumb || values.logoId?.file?.original || brandPlaceHolder}
                        alt="Brand Logo"
                    />
                </div>

            </div>
            {/* Upload Favicon */}
            <div className="w-2/4 flex items-center gap-3">
                <p className="text-sm font-Overpass text-textMidLight">Favicon</p>
                <div className="w-12 h-12 p-2 overflow-hidden rounded-full">
                    <img
                        className="w-full h-full object-contain rounded-full"
                        src={values.faviconImageId?.file?.thumb || values.faviconImageId?.file?.original || brandPlaceHolder}
                        alt="Brand Favicon"
                    />
                </div>
            </div>
        </div>
    );
};
