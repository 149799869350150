import { apiClient } from "../config";

async function getAllBrands(queryParams: any) {
  const res = await apiClient.get(`/admin/brands`, {
    params: queryParams,
  });
  return res.data;
}

export default getAllBrands;
