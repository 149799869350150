import {
  FETCH_SURVEYJS_FORM_DETAILS_START,
  FETCH_SURVEYJS_FORM_DETAILS_SUCCESS,
  FETCH_SURVEYJS_FORM_DETAILS_FAILURE,
  RESET_SURVEYJS_FORM_DATA,
  FETCH_SURVEYJS_FORM_DETAILS_COMPLETE,
} from "./action-types";

import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import { Toasts } from "view/components/Toasts";
import { fetchDynamicFormLayers } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/RefreshDynamicFormGeographyBtn";

export const fetchSurveyJSFormDetails =
  (formDetails: any) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_SURVEYJS_FORM_DETAILS_START });
    dispatch(setLoadingProgress(getRandomProgress()));

    try {
      const { data } = await apiLibrary.Forms.getSurveyJsFormDetails(formDetails.id);

      localStorage.setItem("formProgramId", data.id);
      dispatch({
        type: FETCH_SURVEYJS_FORM_DETAILS_SUCCESS,
        payload: data,
      });

      await Promise.all([
        fetchDynamicFormLayers(formDetails.id, "point"),
        fetchDynamicFormLayers(formDetails.id, "shape"),
        fetchDynamicFormLayers(formDetails.id, "layers"),
      ]);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({ type: RESET_SURVEYJS_FORM_DATA });
      dispatch({ type: FETCH_SURVEYJS_FORM_DETAILS_FAILURE });
      console.log("An error occurred while fetching the form details:", error);
    } finally {
      dispatch({ type: FETCH_SURVEYJS_FORM_DETAILS_COMPLETE });
      dispatch(setLoadingProgress(100));
    }
  };

export const resetSurveyJSFormDetailsAction = () => {
  return {
    type: RESET_SURVEYJS_FORM_DATA,
  };
};
