import React from 'react';
import ISNLogo from 'assets/logo/ISNLogo';
import SIGNLogo from 'assets/logo/SIGNLogo';
import { brandNames, getCurrentBranding } from 'utils/customBranding';

export const brandLogos: any = {
  isn_admin: <ISNLogo width={42} height={42} />,
  sign_admin: <SIGNLogo width={52} height={52} />,
};

const brandStyles: any = {
  isn_admin: 'text-primaryDark dark:text-primary',
  sign_admin: 'text-[#444444] dark:text-[#444444]',
};
const FormHeader = () => {
  const currentBrand = getCurrentBranding();
  const CURRENT_BRAND_LOGO = brandLogos[currentBrand];
  const CURRENT_BRAND_NAME = brandNames[currentBrand];
  const CURRENT_BRAND_STYLE = brandStyles[currentBrand];

  return (
    <div className='flex justify-center items-center gap-3 pt-[62px] pb-[60px] xl:pt-[72px] xl:pb-[70px] bg-white'>
      <div className='flex justify-start items-center'>
        {CURRENT_BRAND_LOGO}
      </div>
      <p className={`text-lg font-bold ${CURRENT_BRAND_STYLE}`}>{CURRENT_BRAND_NAME}</p>
    </div>
  );
};

export default FormHeader;
