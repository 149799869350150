import { Box, Stack } from "@mui/material";
import DashboardCardHeading from "../DashboardCardHeading";
import { IHorizontalBarGraphProps } from "./types";
import React from "react";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { filtersInitialState } from "store/filters/initialState";
import { getHBGStateAndName } from "helpers/dashboard";
import { IHorizontalBarGraph } from "store/dashboard/horizontalBarGraph/initialState";
import { hexCodeTransparency } from "constants/hexCodeTransparency";
import { HBGModule } from "store/dashboard/horizontalBarGraph/types";
import CommunityNoData from "../imgs/CommunityNoData";
import FormNoData from "../imgs/FormNoData";
import ProgramNoData from "../imgs/ProgramNoData";
import { TailSpin } from "react-loader-spinner";

const ColorBar = (props: { width: number; color?: string }) => (
  <Box
    sx={{
      width: `${props.width}%`,
      height: 16,
      bgcolor: props.color,
      ":first-of-type": {
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
      },
      ":last-of-type": {
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
      },
    }}
  />
);

const ContentBox = (props: {
  name: string;
  percent: number;
  color?: string;
}) => (
  <div className="flex items-center justify-start py-1">
    <div
      className={`inline-flex w-3 h-3 mr-4 rounded-full bg-${props.color}`}
      style={{
        backgroundColor: props.color,
      }}
    />
    <p className="text-sm font-normal leading-tight text-textMid dark:text-textMain font-Overpass">
      {props.name}
    </p>

    <p className="ml-auto text-right text-textMid text-[15px] font-semibold font-Overpass dark:text-caption leading-tight">
      {props.percent}%
    </p>
  </div>
);

const HorizontalBarGraph = (props: IHorizontalBarGraphProps) => {
  const {
    dashboard,
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

    // Access the theme colors from the Redux store
    const { primary } = useSelector((state: any) => state.theme.colors);

  React.useEffect(() => {
    dispatch(dashboardActions.fetchHorizontalBarGraph(props.moduleName));
  }, [dashboardFilter]);

  const { stateObj, color } = getHBGStateAndName(props.moduleName, dashboard);
  const totalPercentage = stateObj?.data?.reduce(
    (a: number, b: IHorizontalBarGraph) => a + b.percent,
    0
  );

  return (
    <Box height={"100%"}>
      
      <DashboardCardHeading text={props.name} widgetId={props?.widgetId} />

      {stateObj.stateIs == "Idle" && stateObj.data.length === 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          <NoDataIcon moduleName={props.moduleName} />
        </div>
      ) : stateObj.stateIs === "Pending" ? (
        <div className="flex items-center justify-center w-full h-full loader">
          <TailSpin
            height="50"
            width="50"
            color={primary}
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <>
      <Stack direction={"row"} spacing={0}>
        {stateObj?.data.map((item: IHorizontalBarGraph, index: number, array: IHorizontalBarGraph[]) => {
          // Calculate the total width based on the item's percentage
          const totalWidth = item.percent / totalPercentage * 100;

          // Calculate the number of opacity intervals
          const numIntervals = array.length - 1; // Subtract 1 because the last item should have 100% opacity

          // Calculate the opacity increment for each interval
          // If the first item's percentage is 0, start from 0% opacity
          // Otherwise, start from 20% opacity
          const opacityIncrement = (item.percent === 0 && index === 0) ? 0 : (80 / numIntervals);

          // Calculate the opacity for the current item
          // Add 20% because the first item starts from 20% opacity, unless it's 0
          const currentOpacity = (item.percent === 0 && index === 0) ? 0 : 20 + (opacityIncrement * index);

          // Convert the current opacity to a hex value
          const opacityHex = Math.round(currentOpacity * 2.55).toString(16).padStart(2, '0');

          // Construct the color with the calculated opacity
          const barColor = `${color}${opacityHex}`;

          return (
            <ColorBar key={index} width={totalWidth} color={barColor} />
          );
        })}
      </Stack>

        <Stack spacing={1.75} mt={3}>
          {stateObj?.data.map((item: IHorizontalBarGraph, index: number, array: IHorizontalBarGraph[]) => {
            const totalWidth = (item.percent * 100) / totalPercentage;

            // Define the starting and ending opacities
            const startOpacity = (array[0].percent === 0) ? 0 : 20; // Start at 0% if the first item's percent is 0, otherwise start at 20%
            const endOpacity = 100; // End at 100% opacity

            // Calculate the opacity step size, considering the special case for startOpacity being 0
            const stepSize = (endOpacity - startOpacity) / (array.length - ((startOpacity === 0) ? 1 : 0));

            // Determine the opacity for this item
            const currentOpacity = startOpacity + stepSize * index;

            // Convert the current opacity to a hex value
            const opacityHex = Math.round(currentOpacity * 2.55).toString(16).padStart(2, '0');

            // Construct the color with the calculated opacity
            const barColor = `${color}${opacityHex}`;

            return (
              <ContentBox
                key={index}
                name={item.name}
                percent={item.percent}
                color={barColor}
              />
            );
          })}
        </Stack>
        </>
      )}
    </Box>
  );
};

export default HorizontalBarGraph;

const NoDataIcon = (props: { moduleName: HBGModule }) => {
  switch (props.moduleName) {
    case HBGModule.community:
      return <CommunityNoData />;
    case HBGModule.form:
      return <FormNoData />;
    case HBGModule.program:
      return <ProgramNoData />;
    default:
      return <ProgramNoData />;
  }
};
