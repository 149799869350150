function __paintRasterLayerOnMap__(
  map: any,
  sourceId: string,
  layerId: string,
  layerSinppet: any
) {
  if (!map || !sourceId || !layerId || !layerSinppet) {
    return;
  }

  if (!map.getSource(sourceId)) {
    map.addSource(sourceId, {
      type: "raster",
      tiles: [layerSinppet],
      tileSize: 256,
    });
  }

  if (!map.getLayer(layerId)) {
    map.addLayer(
      {
        id: layerId,
        type: "raster",
        source: sourceId,
        paint: {},
      },
    );
  }
}

export { __paintRasterLayerOnMap__ };
