import React, { useState } from "react";
import DotCentralIcon from "assets/icons/HeroIcons/DotCentralIcon";
import coverImage from "assets/images/Auth/img-placeholder.png";
import profileImg from "assets/images/Auth/community-circleImg.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IfetchCommunities } from "store/communities/initialState";
import useActions from "hooks/useActions";
import PerimeterIcon from "assets/icons/HeroIcons/PerimeterIcon";
import PopOverMenu from "view/components/PopOverMenu";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import ellipsize from "ellipsize";
import "./Gridcard.scss";
import { sentenceCase } from "change-case";
import usePermissions from "hooks/usePermissions";
import useRoles from "hooks/roles";

export const GridCard = () => {
  const { data } = useSelector<RootState, IfetchCommunities>(
    (state) => state?.allCommunities
  );
  const [showIcon, setShowIcon] = useState(false);
  const { communityActions } = useActions();
  const { communities } = usePermissions();
  const { isInternalReviewerCommunityAdmin } = useRoles();
  const getCommunityActions = (data: any) => {
    const modalData = {
      status: data.communityName.status,
      id: data.communityName.id,
      accessibleForSuperAdmin: data.communityName.accessibleForSuperAdmin,
      name: data.communityName.name,
      communityAdmins: data.communityAdmins,
      profileImage: data.communityName.profileImage,
      path: "directory",
      openAccess: data.openAccess,
    };

    return communityActions(modalData);
  };
  const handleClickBtn = () => {
    setShowIcon(true);
  };
  return (
    <>
      {/* <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-4 md:grid-cols-2"> */}
      <div className="flex flex-wrap w-full gap-4">
        {data.communities.map((items: any, index: number) => {
          const navigateTo =
            items.communityName.accessibleForSuperAdmin &&
            communities.canViewViewCommunities &&
            isInternalReviewerCommunityAdmin()
              ? `/community/${items.communityName.id}/profile`
              : "";

          return (
            <div
              key={index}
              className="overflow-hidden shadow-md transform hover:scale-105 transition-transform bg-bgWhite dark:bg-secondaryLight rounded-lg w-full  md:w-[calc(50%-16px)] xl:w-[calc(33.3%-16px)] 2xl:w-[calc(25%-16px)]"
            >
              <div className="relative flex flex-col justify-start bg-white rounded-lg ">
                <Link to={navigateTo} className="w-full h-[136px]">
                  <img
                    src={
                      items.communityName.backgroundImage
                        ? items.communityName.backgroundImage
                        : coverImage
                    }
                    className="object-cover w-full h-full"
                    alt="CommunityImage"
                  />
                </Link>
                <div className="flex gap-3 p-3 bg-bgWhite dark:bg-secondaryLight w-full rounded-lg">
                  <div className="flex w-full">
                    <div className="flex flex-col items-start justify-start w-full">
                      <div className="h-[50px] flex justify-between w-full">
                        <div className="flex gap-3">
                          <div>
                            <Link to={navigateTo} className="w-full ">
                              <img
                                src={
                                  items.communityName.profileImage
                                    ? items.communityName.profileImage
                                    : profileImg
                                }
                                className="object-cover w-9 h-9 rounded-full"
                                alt="CommunityImage"
                              />
                            </Link>
                          </div>
                          <div>
                            <Link
                              to={navigateTo}
                              // style={{ overflowWrap: "anywhere" }}
                              className="flex flex-wrap break-all w-[170px] text-base font-medium text-left capitalize text-secondary dark:text-textMain pr-1"
                            >
                              {ellipsize(`${items.communityName.name}`, 30)}
                            </Link>
                          </div>
                        </div>
                        <div className="">
                          {getCommunityActions(items).some(
                            (action: any) => action.isVisible === true
                          ) ? (
                            <PopOverMenu
                              style={"0px"}
                              icon={<PerimeterIcon className={` `} />}
                            >
                              <ActionDropDownMenu
                                data={items}
                                actions={getCommunityActions(items)}
                              />
                            </PopOverMenu>
                          ) : (
                            <PerimeterIcon className="" />
                          )}
                        </div>
                      </div>
                      <div
                        className={`flex items-center w-full gap-3 px-12 py-1`}
                      >
                        <div className="bg-primaryExtraLight dark:bg-primary px-1.5 py-0.5 rounded">
                          <p className="text-secondaryMidLight dark:text-textMain">
                            {sentenceCase(items.type)}
                          </p>
                        </div>
                        <div className="flex">
                          <div
                            className={`flex justify-start items-center relative gap-[3px] ml-1 ${items.members > 1000 || items.programs > 1000 ? "mb-1" : ""}`}
                          >
                            <p className="text-xs text-left break-all text-textMid dark:text-caption whitespace-nowrap">
                              {items.programs === 1
                                ? `${items.programs} Program`
                                : `${items.programs}  Programs`}
                            </p>
                          </div>
                          <DotCentralIcon />
                          <div
                            className={`flex items-center justify-start flex-grow-0 flex-shrink-0 ${items.members > 1000 || items.programs > 1000 ? "mb-1" : ""}`}
                          >
                            <p className="flex-grow-0 flex-shrink-0 text-xs text-left text-textMid dark:text-caption">
                              {items.members === 1
                                ? `${items.members} Member`
                                : `${items.members} Members`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
