import React from "react";
import KpiCard from "./KpiCard";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IOrganization } from "store/organizationProfile/initialState";
import { useNavigate } from "react-router-dom";
import FolderFileIcon from "assets/icons/HeroIcons/FolderFileIcon";
import AdminIcon from "assets/icons/HeroIcons/AdminIcon";
import CommunityKpiIcon from "assets/icons/HeroIcons/CommunityKpiIcon";
import MembersKpiIcon from "assets/icons/HeroIcons/MembersKpiIcon";


export const OrganizationKpis = () => {
  const organizationProfile = useSelector<RootState, IOrganization>(
    (state) => state?.organizationProfile
  );
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-start justify-start flex-grow gap-4">
      <div
        className={`grid w-full gap-3 justify-evenly lg:grid-cols-3 xl:grid-cols-2`}
      >
        <KpiCard
          icon={<MembersKpiIcon />}
          title="Members"
          count={organizationProfile.noOfMembers}
          redirectLinkAction={() => { navigate(`/organizations/${organizationProfile.id}/users`) }}
        />
        <KpiCard
          icon={<CommunityKpiIcon fill="#2C3236"/>}
          title="Communities"
          count={organizationProfile.noOfCommunities}
          redirectLinkAction={() => { navigate(`/organizations/${organizationProfile.id}/communities`) }}
        />
        <KpiCard
          icon={<AdminIcon />}
          title="Admins"
          count={organizationProfile.noOfAdmins}
          redirectLinkAction={() => {
            const state = { isAdminRole: true };
            localStorage.setItem('kpiState', JSON.stringify(state));
            navigate(`/organizations/${organizationProfile.id}/users`)
          }}
        />

        <KpiCard
          icon={<FolderFileIcon />}
          title="Programs"
          count={organizationProfile.noOfPrograms}
          redirectLinkAction={() => { navigate(`/organizations/${organizationProfile.id}/programs`) }}
        />
      </div>
    </div>
  );
};
