import { useDispatch } from "react-redux";
import { LayerBox } from "../index";
import {
  displayShapeLayerOnMap,
  doFetchAllLayers,
  openAddShapeScreen,
  openEditShapeLayerScreen,
  openViewShapeLayerScreen,
  updateSelectedItemToZoom,
  updateSelectedLayersItems,
} from "store/geography/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CurrentOpenToggleType, T_ShapeLayersData } from "../LayersListing";
import usePermissions from "hooks/usePermissions";

interface I_ShapeLayerBox {
  data: T_ShapeLayersData[] | undefined;
  actionsList: {
    label: string;
  }[];
  global?: boolean;
  setEntityType?: Dispatch<SetStateAction<string | null>>;
  setPage?: Dispatch<SetStateAction<any>>;
  entityType?: null | string;
  page?: any;
  totalPages?: number;
  totalItems?: number;
  loading: boolean;
  currentOpenToggle: CurrentOpenToggleType;
  // setCurrentOpenToggle: Dispatch<SetStateAction<CurrentOpenToggleType>>;
}

const ShapeLayerBox = ({
  data,
  actionsList,
  setEntityType,
  setPage,
  entityType,
  totalPages,
  page,
  totalItems,
  loading,
  currentOpenToggle,
  // setCurrentOpenToggle,
}: I_ShapeLayerBox) => {
  const [isScrollEndEventTriggered, setIsScrollEndEventTriggered] =
    useState(false);
  const [layers, setLayers] = useState<T_ShapeLayersData[] | undefined>();
  const [doShowDataList, setDoShowDataList] = useState(true);
  const { geographies, communities } = usePermissions();
  const dispatch = useDispatch();
  const { selectedLayersItems } = useSelector(
    (state: RootState) => state.geography
  );
  const { communityId } = useParams();

  const heading = `${!totalItems || totalItems === 0 ? "" : totalItems} Shapes`;

  useEffect(()=>{
    setDoShowDataList(currentOpenToggle === 'shape')
  },[currentOpenToggle])

  useEffect(() => {
    setLayers(data);
  }, [data]);

  useEffect(() => {
    if (isScrollEndEventTriggered) {
      if (
        setPage &&
        setEntityType &&
        totalPages &&
        page &&
        totalPages >= page.shape + 1
      ) {
        setPage((prev: any) => {
          return { ...prev, shape: prev.shape + 1 };
        });

        if (entityType !== "shape") {
          setEntityType("shape");
        }
      }

      setIsScrollEndEventTriggered(false);
    }
  }, [isScrollEndEventTriggered]);

  // handlers
  const handleClickOnMenuAction = async (action: any, dataItem: any) => {
    const currentAction = action.label.toLowerCase();

    const selectedItem = selectedLayersItems?.find(
      (itm: any) => itm?.item?.id === dataItem?.id
    );

    switch (currentAction) {
      case "edit":
        // open edit screen of raster layer
        dispatch(openEditShapeLayerScreen(dataItem));
        break;

      case "view information":
        // open detail screen of raster layer
        dispatch(openViewShapeLayerScreen(dataItem));
        break;

      case "zoom to selection":
        // update selected item to be dominant on map
        dispatch(updateSelectedItemToZoom({ ...(selectedItem ?? {}) }));
        break;

      case "restore":
      case "archive":
        case "archive":
          if (communityId) {
            const isArchive = currentAction === "archive";
            try {
              // Update layer status
              await apiLibrary.geography.updateLayerStatus(
                communityId,
                dataItem.id,
                isArchive
              );
              dispatch(doFetchAllLayers(true));
    
              // Update selected layers items
              const layersItems = selectedLayersItems || [];
    
              const itemIndex = layersItems.findIndex(
                (itm: any) => itm.item.id === dataItem.id
              );
    
              if (itemIndex !== -1) {
                layersItems[itemIndex].status = false;
                dispatch(updateSelectedLayersItems([...layersItems]));
              }
            } catch (error:any) {
              console.error("Failed to update layer status:", error);
              Toasts.error(error.response.data.message??error.message);
              // Optionally, handle errors e.g., show a notification
            }
          }
        break;
    }
  };
  const handleClickOnAddButton = () => {
    // open add screen of raster layer
    dispatch(openAddShapeScreen());
    dispatch(displayShapeLayerOnMap({}));
  };
  const handleClickOnCheckBox = async (e: any, item: any) => {
    const isChecked = e.target.checked;
    const layersItems = selectedLayersItems ?? [];
    const itemIndex = layersItems.findIndex(
      (itm: any) => itm.item.id === item.id
    );
    const alreadyExistedItem = layersItems[itemIndex];
    let upadatedItems;

    if (isChecked) {
      if (alreadyExistedItem) {
        // update item status to true
        alreadyExistedItem.status = true;

        layersItems[itemIndex] = alreadyExistedItem;
        upadatedItems = layersItems;
      } else {
        if (communityId) {
          await apiLibrary.geography
            .getSingleLayer(communityId, item.id)
            .then((data) => {
              const newItem = {
                item: data.data,
                itemType: "shape",
                status: true,
              };
              upadatedItems = [...layersItems, newItem];

              setLayers((prev: any) => {
                const itemIndex = prev.findIndex(
                  (itm: any) => itm?.id === item?.id
                );
                if (itemIndex >= 0) {
                  prev[itemIndex] = newItem.item;
                }

                return prev;
              });
            })
            .catch((error) => {
              Toasts.error(error.message);
            });
        }
      }
    } else {
      // update item status to false on unchecking the checkbox
      alreadyExistedItem.status = false;

      layersItems[itemIndex] = alreadyExistedItem;
      upadatedItems = layersItems;
    }

    dispatch(updateSelectedLayersItems([...upadatedItems]));
  };
  const handleOnScrollEnd = () => {
    setIsScrollEndEventTriggered(true);
  };
  const handleClickOnTogglerBtn = () => {
    setDoShowDataList(!doShowDataList)
    // if (currentOpenToggle && currentOpenToggle !== "shape") {
    //   setCurrentOpenToggle("shape");
    // }
  };

  return (
    <LayerBox
      heading={heading}
      dataList={communities.canViewGeographyCommunities ? layers : null}
      actionList={actionsList}
      hideItemThumb={true}
      layerType="shape"
      loading={loading}
      hideAddBtn={!geographies.canViewVectorLayers}
      doShowDataList={doShowDataList}
      handleClickOnMenuAction={handleClickOnMenuAction}
      handleClickOnAddButton={handleClickOnAddButton}
      handleClickOnCheckBox={handleClickOnCheckBox}
      handleOnScrollEnd={handleOnScrollEnd}
      handleClickOnTogglerBtn={handleClickOnTogglerBtn}
    />
  );
};

export { ShapeLayerBox };
