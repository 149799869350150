import React from "react";
// Components
import { Date, SelectedCreatedBy } from "./Components";

import { useParams } from "react-router-dom";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { geographyResetFiltersAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting geographyFilter state from Redux store
  const { geographyFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const dispatch = useDispatch();



  const clearAllFilters = () => {
    dispatch(geographyResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyGeographyFilterApplied(geographyFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-start justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <SelectedCreatedBy />

          <Date
            name="dateAdded"
            from={geographyFilter.dateAdded?.from}
            to={geographyFilter.dateAdded?.to}
            isApplied={geographyFilter.dateAdded?.applied}
          />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;



export function isAnyGeographyFilterApplied (geographyFilter: any): boolean  {
  const { dateAdded, createdByIds } = geographyFilter;
  const applied = dateAdded?.applied || createdByIds?.applied;

  return applied;
};