// import {
//   GET_OBSERVATION_PROFILE,
//   GET_OBSERVATION_REVIEWERS,
//   RESET_OBSERVATION_PROFILE,
//   START_LOADING_OBSERVATION,
//   STOP_LOADING_OBSERVATION,
//   SWITCH_TO_LOCAL_TIME_ZONE,
// } from "./action-types";
// import { Dispatch } from "redux";
// import apiLibrary from "services/api";
// import { convertKeysToCamelCase } from "utils/caseConvertor";
// import { Toasts } from "view/components/Toasts";
// import { filterSelectedItems } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/RefreshDynamicFormGeographyBtn";
// import { appendMapDataToPointsIfTheyDontHaveMapData } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/hooks/useFetchAllCommunityPoints";
// import * as surveyJsApi from "services/api/surveyJS";
// import questionTypes from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes";
// import { panelsName } from "view/pages/Observations/ObservationProfile/staticComponents/panelsTitle";

// export const fetchObservationProfileAction =
//   (observationId: any) => async (dispatch: Dispatch, getState: any) => {
//     dispatch({ type: START_LOADING_OBSERVATION });
//     try {
//       const { data } =
//         await apiLibrary.Observations.getObservationProfile(observationId);
//       const isGeoSpetialComponentExist = verifyExistenceOfGeospatialQuestions(
//         data.sections
//       );

//       if (isGeoSpetialComponentExist) {
//         const [
//           allCommunityPoints,
//           allCommunityLayers,
//           allGlobalCommunityLayers,
//         ] = await fetchDynamicFormLayers(data.dynamicForm.id);

//         function createUpdatedElements(
//           elements: any,
//           allCommunityPoints: any,
//           allCommunityLayers: any,
//           allGlobalCommunityLayers: any
//         ) {
//           return elements.map((element: any) => {
//             const updatedElement = updateSinglePicker(
//               element,
//               allCommunityPoints,
//               allCommunityLayers,
//               allGlobalCommunityLayers
//             );
//             return updatedElement;
//           });
//         }
//         const newData = {
//           ...data,
//           sections: data.sections.map((section: any) => ({
//             ...section,
//             templateElements: section.templateElements
//               ? createUpdatedElements(
//                   section.templateElements,
//                   allCommunityPoints,
//                   allCommunityLayers,
//                   allGlobalCommunityLayers
//                 )
//               : section.templateElements,
//             elements: section.elements
//               ? createUpdatedElements(
//                   section.elements,
//                   allCommunityPoints,
//                   allCommunityLayers,
//                   allGlobalCommunityLayers
//                 )
//               : section.elements,
//           })),
//         };

//         dispatch(
//           convertKeysToCamelCase({
//             type: GET_OBSERVATION_PROFILE,
//             payload: newData,
//           })
//         );
//       }

//       dispatch(
//         convertKeysToCamelCase({
//           type: GET_OBSERVATION_PROFILE,
//           payload: data,
//         })
//       );
//     } catch (error: any) {
//       const errorMsg = error?.response?.data?.message ?? error?.message;
//       Toasts.error(errorMsg);
//       window.location.href = "/observations/list";
//     } finally {
//       dispatch({ type: STOP_LOADING_OBSERVATION });
//     }
//   };

// export const fetchObservationReviewersAction =
//   (programId: any) => async (dispatch: Dispatch, getState: any) => {
//     try {
//       const queryParams = {
//         id: programId,
//         page: 1,
//         limit: 1000,
//       };

//       const { data } = await apiLibrary.Managers.getAllManagers(queryParams);

//       dispatch({
//         type: GET_OBSERVATION_REVIEWERS,
//         payload: data,
//       });
//     } catch (error: any) {
//       const errorMsg = error?.response?.data?.message ?? error?.message;
//       Toasts.error(errorMsg);
//     }
//   };

// export const resetObservationProfileAction = () => {
//   return {
//     type: RESET_OBSERVATION_PROFILE,
//   };
// };

// export const switchToLocalTimeZoneAction = (value: boolean) => {
//   return {
//     type: SWITCH_TO_LOCAL_TIME_ZONE,
//     payload: value,
//   };
// };

// function verifyExistenceOfGeospatialQuestions(sections: any[]) {
//   const geospatialComponentsName = [
//     questionTypes.shapePicker,
//     questionTypes.pointPicker,
//     questionTypes.drawPolygon,
//     questionTypes.dropPin,
//   ];

//   for (let index = 0; index < sections?.length; index++) {
//     const element = sections[index];

//     if (
//       geospatialComponentsName.includes(element.type) ||
//       element.name === panelsName.HARVEST ||
//       element.name === panelsName.GEOGRAPHICAL_INFORMATION
//     ) {
//       return true;
//     }

//     if (
//       element.type === questionTypes.paneldynamic ||
//       element.type === questionTypes.panel
//     ) {
//       const panelElements =
//         element.type === questionTypes.paneldynamic
//           ? element.templateElements
//           : element.elements;

//       for (let index = 0; index < panelElements?.length; index++) {
//         const panelEle = panelElements[index];

//         if (geospatialComponentsName.includes(panelEle.type)) {
//           return true;
//         }
//       }
//     }
//   }

//   return false;
// }

// export const pickerKeyMap: Record<
//   string,
//   {
//     selectedItemsToHighlight: string;
//     defaultLayers: string;
//   }
// > = {
//   pointpicker: {
//     selectedItemsToHighlight: "selected_points",
//     defaultLayers: "selected_layers",
//   },
//   shape_picker: {
//     selectedItemsToHighlight: "selected_shapes",
//     defaultLayers: "selected_layers",
//   },
//   drawpolygon: {
//     selectedItemsToHighlight: "",
//     defaultLayers: "selected_layers",
//   },
//   droppin: {
//     selectedItemsToHighlight: "",
//     defaultLayers: "selected_layers",
//   },
// };

// export function updateSinglePicker(
//   picker: any,
//   allCommunityLayers: any[] = [],
//   allCommunityMarkers: any[] = [],
//   globalLayers: any[] = []
// ) {
//   const keys = pickerKeyMap[picker.type];
//   if (!keys) {
//     console.warn(
//       `Unknown picker type: ${picker.type}. Returning original picker.`
//     );
//     return picker;
//   }

//   const geospatialComponentsName = [
//     questionTypes.shapePicker,
//     questionTypes.pointPicker,
//     questionTypes.drawPolygon,
//     questionTypes.dropPin,
//   ];
//   if (!geospatialComponentsName.includes(picker.type)) {
//     return picker;
//   }
//   const { selectedItemsToHighlight, defaultLayers } = keys;

//   const selectedItems = picker[selectedItemsToHighlight] || [];
//   const selectedDefaultLayers = picker[defaultLayers] ?? [];

//   const allCommunityItems =
//     picker.type === "pointpicker" ? allCommunityMarkers : allCommunityLayers;

//   const filteredSelectedItems =
//     allCommunityItems.length > 0
//       ? filterSelectedItems(selectedItems, allCommunityItems)
//       : [];

//   const filteredSelectedLayers =
//     globalLayers.length > 0
//       ? filterSelectedItems(selectedDefaultLayers, globalLayers)
//       : [];

//   // Create updated picker object
//   const updatedPicker = { ...picker };

//   // Update selected items only if necessary
//   updatedPicker[selectedItemsToHighlight] = filteredSelectedItems;
//   updatedPicker[defaultLayers] = filteredSelectedLayers;

//   return updatedPicker;
// }

// export async function fetchDynamicFormLayers(formId: number) {
//   const layerTypes = ["shape", "point", "layers"];

//   try {
//     const promises = layerTypes.map(async (layerType) => {
//       const response = await surveyJsApi.default.fetchLayers(formId, layerType);
//       if (response && response.data) {
//         if (layerType === "point") {
//           return appendMapDataToPointsIfTheyDontHaveMapData(response.data);
//         } else {
//           return response.data;
//         }
//       }
//       return [];
//     });

//     const [allCommunityPoints, allCommunityLayers, allGlobalCommunityLayers] =
//       await Promise.all(promises);

//     return [allCommunityPoints, allCommunityLayers, allGlobalCommunityLayers];
//   } catch (error) {
//     console.error("Failed to fetch layers:", error);
//     return [[], [], []];
//   }
// }

import {
  GET_OBSERVATION_PROFILE,
  GET_OBSERVATION_REVIEWERS,
  RESET_OBSERVATION_PROFILE,
  START_LOADING_OBSERVATION,
  STOP_LOADING_OBSERVATION,
  SWITCH_TO_LOCAL_TIME_ZONE,
} from "./action-types";
import { Dispatch } from "redux";
import apiLibrary from "services/api";
import { convertKeysToCamelCase } from "utils/caseConvertor";
import { Toasts } from "view/components/Toasts";
import { filterSelectedItems } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/RefreshDynamicFormGeographyBtn";
import { appendMapDataToPointsIfTheyDontHaveMapData } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/hooks/useFetchAllCommunityPoints";
import * as surveyJsApi from "services/api/surveyJS";
import questionTypes from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes";
import { panelsName } from "view/pages/Observations/ObservationProfile/staticComponents/panelsTitle";

/* -------------------------------------------------------------------------- */
/*                           MAIN ACTION: FETCH PROFILE                        */
/* -------------------------------------------------------------------------- */

/**
 * Fetch an observation profile based on the provided observation ID.
 * This action:
 *   1. Starts a loading state.
 *   2. Fetches the observation profile from the server.
 *   3. Checks for geospatial questions and, if any exist, fetches
 *      additional layer data and updates the profile.
 *   4. Dispatches the final updated profile to the Redux store.
 *   5. Handles errors with user-facing toasts and redirection if needed.
 *   6. Ends the loading state.
 *
 * @param observationId - The ID of the observation to fetch.
 * @returns A thunk action that can be dispatched.
 */
export const fetchObservationProfileAction =
  (observationId: any) => async (dispatch: Dispatch) => {
    // 1. Indicate loading has started
    dispatch({ type: START_LOADING_OBSERVATION });

    try {
      // 2. Fetch the raw observation profile data
      const data = await getObservationProfileData(observationId);

      // 3. If the data contains geospatial questions, fetch layer data and update elements
      const updatedData = await updateGeospatialQuestionsIfNeeded(data);

      // 4. Dispatch the final data (in camelCase format) to the Redux store
      dispatchObservationProfile(dispatch, updatedData);
    } catch (error) {
      // 5. Handle errors (show toast, redirect to list)
      handleFetchProfileError(error);
    } finally {
      // 6. Stop the loading indicator
      dispatch({ type: STOP_LOADING_OBSERVATION });
    }
  };

/* -------------------------------------------------------------------------- */
/*                                HELPER FUNCTIONS                            */
/* -------------------------------------------------------------------------- */

/**
 * Performs the API call to retrieve a single observation profile by ID.
 *
 * @param observationId - The ID of the observation to fetch.
 * @returns The data object representing the observation profile.
 * @throws Any network or server-side error encountered.
 */
async function getObservationProfileData(observationId: number | string) {
  const { data } =
    await apiLibrary.Observations.getObservationProfile(observationId);
  return data;
}

/**
 * Checks if the observation data contains any geospatial components:
 *   - If geospatial questions exist, fetches dynamic form layers
 *     (shapes, points, and global layers) and updates the observation
 *     sections accordingly.
 *   - If no geospatial questions are found, returns the original data.
 *
 * @param data - The raw observation profile data.
 * @returns The updated observation profile data if geospatial items exist,
 *          otherwise the original data.
 */
async function updateGeospatialQuestionsIfNeeded(data: any): Promise<any> {
  // Check if any geospatial questions exist in sections
  const hasGeoSpatial = verifyExistenceOfGeospatialQuestions(data?.sections);

  // If no geospatial questions, return original data immediately
  if (!hasGeoSpatial) return data;

  // Fetch the necessary layers for geospatial items
  const [communityPoints, communityLayers, globalCommunityLayers] =
    await fetchDynamicFormLayers(data?.dynamicForm?.id);

  // Update each section with geospatial info
  const updatedSections = data.sections.map((section: any) => ({
    ...section,
    templateElements: updateElementsArray(
      section.templateElements,
      communityPoints,
      communityLayers,
      globalCommunityLayers
    ),
    elements: updateElementsArray(
      section.elements,
      communityPoints,
      communityLayers,
      globalCommunityLayers
    ),
  }));

  // Return a copy of the original data with updated sections
  return { ...data, sections: updatedSections };
}

/**
 * Safely updates templateElements or elements arrays with geospatial data.
 * If the array is undefined, returns it as-is.
 *
 * @param elements - An array of elements or templateElements from a section.
 * @param communityPoints - Community points data.
 * @param communityLayers - Community layer data.
 * @param globalCommunityLayers - Global layer data.
 * @returns A new array of updated elements, or the original array if undefined.
 */
function updateElementsArray(
  elements: any[] | undefined,
  communityPoints: any[],
  communityLayers: any[],
  globalCommunityLayers: any[]
) {
  // If there are no elements, no updates are needed
  if (!elements) return elements;

  // Map each element through updateSinglePicker to inject layer data
  return elements.map((element: any) =>
    updateSinglePicker(
      element,
      communityPoints,
      communityLayers,
      globalCommunityLayers
    )
  );
}

/**
 * Converts the final observation data to camelCase and dispatches it
 * under the GET_OBSERVATION_PROFILE action.
 *
 * @param dispatch - The Redux dispatch function.
 * @param data - The (possibly updated) observation data to store.
 */
function dispatchObservationProfile(dispatch: Dispatch, data: any) {
  dispatch(
    convertKeysToCamelCase({
      type: GET_OBSERVATION_PROFILE,
      payload: data,
    })
  );
}

/**
 * Displays an error message using Toasts and redirects the user to the
 * observations list view.
 *
 * @param error - The error object thrown by any failing operation in the action.
 */
function handleFetchProfileError(error: any) {
  const errorMsg = error?.response?.data?.message ?? error?.message;
  Toasts.error(errorMsg);
  window.location.href = "/observations/list";
}

/* -------------------------------------------------------------------------- */
/*                            REMAINING ACTIONS                               */
/* -------------------------------------------------------------------------- */

/**
 * Fetch observation reviewers based on the program ID.
 * Dispatches the retrieved data under GET_OBSERVATION_REVIEWERS.
 *
 * @param programId - The ID of the program to fetch reviewers for.
 */
export const fetchObservationReviewersAction =
  (programId: number | string) => async (dispatch: Dispatch) => {
    try {
      const queryParams = { id: programId, page: 1, limit: 1000 };
      const { data } = await apiLibrary.Managers.getAllManagers(queryParams);

      dispatch({
        type: GET_OBSERVATION_REVIEWERS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

/**
 * Resets the observation profile data in the Redux store.
 */
export const resetObservationProfileAction = () => ({
  type: RESET_OBSERVATION_PROFILE,
});

/**
 * Switches between using local time and UTC (server) time in the UI.
 *
 * @param value - A boolean value indicating whether to use local time (true)
 *               or UTC (false).
 */
export const switchToLocalTimeZoneAction = (value: boolean) => ({
  type: SWITCH_TO_LOCAL_TIME_ZONE,
  payload: value,
});

/* -------------------------------------------------------------------------- */
/*                          INTERNAL UTILITY FUNCTIONS                        */
/* -------------------------------------------------------------------------- */

/**
 * Verifies whether the provided sections contain any geospatial question types,
 * or if their names indicate geospatial panels. Returns true if any geospatial
 * questions are found, otherwise false.
 *
 * @param sections - An array of sections from the observation data.
 * @returns A boolean indicating the presence of any geospatial question types.
 */
function verifyExistenceOfGeospatialQuestions(sections: any[]): boolean {
  const geospatialComponentsName = [
    questionTypes.shapePicker,
    questionTypes.pointPicker,
    questionTypes.drawPolygon,
    questionTypes.dropPin,
  ];

  // Iterate over each section to look for direct geospatial types or panel names
  for (const section of sections ?? []) {
    // Direct match or special panel name check
    if (
      geospatialComponentsName.includes(section.type) ||
      section.name === panelsName.HARVEST ||
      section.name === panelsName.GEOGRAPHICAL_INFORMATION
    ) {
      return true;
    }

    // If it's a panel or paneldynamic, check nested templateElements/elements
    if (
      section.type === questionTypes.paneldynamic ||
      section.type === questionTypes.panel
    ) {
      const panelElements =
        section.type === questionTypes.paneldynamic
          ? section.templateElements
          : section.elements;

      // Check each element in the panel for geospatial question types
      for (const panelElem of panelElements ?? []) {
        if (geospatialComponentsName.includes(panelElem.type)) {
          return true;
        }
      }
    }
  }
  return false;
}

/**
 * A mapping from picker types to the fields used for storing
 * selected items or default layers.
 */
export const pickerKeyMap: Record<
  string,
  {
    selectedItemsToHighlight: string;
    defaultLayers: string;
  }
> = {
  pointpicker: {
    selectedItemsToHighlight: "selectedPoints",
    defaultLayers: "selectedLayers",
  },
  shape_picker: {
    selectedItemsToHighlight: "selectedShapes",
    defaultLayers: "selectedLayers",
  },
  drawpolygon: {
    selectedItemsToHighlight: "",
    defaultLayers: "selectedLayers",
  },
  droppin: {
    selectedItemsToHighlight: "",
    defaultLayers: "selectedLayers",
  },
};

/**
 * Updates a single "picker" object (e.g., pointPicker, shapePicker, etc.)
 * with its corresponding community/global layers, ensuring only valid
 * selections remain. Returns a new picker object.
 *
 * @param picker - The original picker object from the observation.
 * @param allCommunityPoints - A list of community points fetched for 'pointpicker' types.
 * @param allCommunityLayers - A list of layer data fetched for shape or other geospatial types.
 * @param globalLayers - A list of global community layers used as defaults.
 * @returns A new picker object updated with valid selected items and layers.
 */
export function updateSinglePicker(
  picker: any,
  allCommunityPoints: any[] = [],
  allCommunityLayers: any[] = [],
  globalLayers: any[] = []
) {
  // If this picker type is unknown, log a warning and return it unchanged
  const keys = pickerKeyMap[picker.type];
  if (!keys) {
    console.warn(`Unknown picker type: ${picker.type}`);
    return picker;
  }

  // Only proceed if this is indeed a geospatial question type
  const geospatialTypes = [
    questionTypes.shapePicker,
    questionTypes.pointPicker,
    questionTypes.drawPolygon,
    questionTypes.dropPin,
  ];
  // return picker;
  if (!geospatialTypes.includes(picker.type)) {
    return picker;
  }

  const { selectedItemsToHighlight, defaultLayers } = keys;

  // Determine the correct source for 'selected items'
  const allCommunityItems =
    picker.type === "pointpicker" ? allCommunityPoints : allCommunityLayers;

  // Filter out invalid items/layers
  const filteredSelectedItems = filterSelectedItems(
    picker[selectedItemsToHighlight] ?? [],
    allCommunityItems
  );
  const filteredSelectedLayers = filterSelectedItems(
    picker[defaultLayers] ?? [],
    globalLayers
  );

  // Return a new object with the updated fields
  return {
    ...picker,
    [selectedItemsToHighlight]: filteredSelectedItems,
    [defaultLayers]: filteredSelectedLayers,
  };
}

/**
 * Fetches three types of layers (shape, point, and layers) from the server
 * to support geospatial questions within the observation profile.
 *
 * @param formId - The ID of the form whose layers we want to retrieve.
 * @returns A tuple [allCommunityPoints, allCommunityLayers, allGlobalCommunityLayers].
 *          Each of these is an array of relevant layer data. If an error occurs,
 *          or formId is undefined, returns empty arrays.
 */
export async function fetchDynamicFormLayers(
  formId: number | undefined
): Promise<[any[], any[], any[]]> {
  if (!formId) return [[], [], []];

  // The layer types to fetch from the SurveyJS API
  const layerTypes = ["shape", "point", "layers"];

  try {
    // Fetch each layer type in parallel
    const promises = layerTypes.map(async (layerType) => {
      const response = await surveyJsApi.default.fetchLayers(formId, layerType);
      // If data exists, process point data with map metadata or return as-is
      if (response?.data) {
        return layerType === "point"
          ? appendMapDataToPointsIfTheyDontHaveMapData(response.data)
          : response.data;
      }
      return [];
    });

    // Await all fetches and destructure the results
    const [allCommunityPoints, allCommunityLayers, allGlobalCommunityLayers] =
      await Promise.all(promises);

    return [allCommunityPoints, allCommunityLayers, allGlobalCommunityLayers];
  } catch (error) {
    console.error("Failed to fetch layers:", error);
    return [[], [], []];
  }
}
