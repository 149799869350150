import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { useDispatch } from "react-redux";
import { closeViewDnsBrandModalAction } from "store/modals";
import { RootState } from "store";
import { useSelector } from "react-redux";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  // width: auto,
  backgroundColor: "#ffff",
};

export const viewDnsbrandModal: React.FC<any> = () => {
  const { viewDnsBrandModal } = useSelector(
    (state: RootState) => state.modals
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    dispatch(closeViewDnsBrandModalAction());
  };

  return (
    <Modal
      open={viewDnsBrandModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        {/* <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite my-5">
            {stateIs == "Idle" && data?.columnMapping?.length === 0 ? (
              <NoRecordFound />
            ) : stateIs === "Pending" ? (
              <div className="loader min-h-[45vh] justify-center items-center flex w-full">
                <TailSpin
                  height="50"
                  width="50"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            ) : (
              <Table
                flexRender={flexRender}
                table={table}
                columnOrder={columnOrder}
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setSorting={setSorting}
                sorting={sorting}
              />
            )}
            <Pagination />
          </div>

          <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
            <Button
              type="button"
              text="Cancel"
              filledColor="primary"
              outlinedColor="primary"
              textColor="textWhite"
              className="px-5 h-11"
              width="35"
              height="13"
              fontStyle="font-semibold"
              variant="outlined"
              onClick={handleClose}
            />

            <Button
              disabled={isLoading}
              type="button"
              text={
                archiveRestoreBrandModal?.data?.status === "active"
                  ? "Archive"
                  : "Restore"
              }
              filledColor="primary"
              outlinedColor="primary"
              textColor="textWhite"
              className="w-24 h-11"
              width="35"
              height="13"
              fontStyle="font-semibold"
              variant="filled"
              onClick={archiveOrRestoreBrand}
            />
          </div>
        </div> */}
      </Box>
    </Modal>
  );
};
