import { PieChart } from "@mui/x-charts/PieChart";
import React from "react";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { Box, Stack, Typography } from "@mui/material";
import DashboardCardHeading from "../DashboardCardHeading";
import { filtersInitialState } from "store/filters/initialState";
import { IQAProgress } from "store/dashboard/qaProgress/initialState";
import ReactApexChart from "react-apexcharts";
import QAProgressNoData from "../imgs/QAProgressNoData";
import { TailSpin } from "react-loader-spinner";

export default function QAProgress() {
  const {
    dashboard: { qaProgress },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  // Access the theme colors from the Redux store
  const { primary } = useSelector((state: any) => state.theme.colors);

  React.useEffect(() => {
    dispatch(dashboardActions.fetchQAProgress());
  }, [dashboardFilter]);

  return (
    <Box height={"100%"}>
      <DashboardCardHeading text="QA Progress" widgetId="qa_progress" />

      {qaProgress.stateIs == "Idle" &&
        Object.values(qaProgress.data).filter((item) => !!item.value).length ===
        0 ? (
        <div className="flex items-center justify-center w-full h-full">
          <QAProgressNoData />
        </div>
      ) : qaProgress.stateIs === "Pending" ? (
        <div className="flex items-center justify-center w-full h-full loader">
          <TailSpin
            height="50"
            width="50"
            color={primary}
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <Stack
          sx={{
            minWidth: 250,
          }}
          width={"100%"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Box width={250} height={250}>
            <ReactApexChart
              series={Object.values(qaProgress.data).map((item) => item.value)}
              type="donut"
              options={{
                stroke: {
                  width: 0,
                },
                states: {
                  hover: {
                    filter: {
                      type: "none",
                    },
                  },
                },
                chart: {
                  type: "donut",
                  width: 200,
                  toolbar: {
                    show: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200,
                      },
                      legend: {
                        show: false,
                      },
                    },
                  },
                ],
                labels: Object.values(qaProgress.data).map(
                  (item) => item.label
                ),
                colors: Object.values(qaProgress.data).map(
                  (item) => item.color
                ),
                legend: {
                  show: false,
                },
                tooltip: {
                  style: {
                    fontFamily: "overpass",
                  },
                  y: {
                    formatter(val, opts) {
                      return val + "%";
                    },
                    title: {
                      formatter: function (seriesName) {
                        return "";
                      },
                    },
                  },
                },
              }}
            />
          </Box>
          <Legends data={qaProgress.data} />
        </Stack>
      )}
    </Box>
  );
}

const Legends = (props: { data: IQAProgress }) => {
  const legendsArr = Object.values(props.data);
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"flex-start"}
      flexWrap={"wrap"}
      width={"100%"}
      justifyContent={"space-evenly"}
    >
      {legendsArr.map((item, index) => {
        return (
          <Stack
            key={index}
            flexDirection={"row"}
            alignItems={"flex-start"}
            mr={1}
            mb={1}
            sx={{
              "&:last-child": { mr: 0 },
            }}
          >
            <Box
              sx={{
                width: "9px",
                height: "9px",
                bgcolor: item.color,
                mr: "6px",
                mt: 0.5,
                borderRadius: "100%",
              }}
            />
            <Stack flexDirection={"column"} alignItems={"flex-start"}>
              <p className="text-sm font-normal leading-5 text-textMid font-Overpass dark:text-caption ">
                {item.label}
              </p>
              <p className="text-xs font-normal leading-4 text-textMidDark font-poppins dark:text-textMain ">{`${item.value}%`}</p>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
