import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { handlePaintingOfVectorRegionShapeLayersOnMap } from "../handlers";
import { handlePaintingOfRasterLayersOnMap } from "./handlePaintingOfRasterLayersOnMap";
import { handleCreationOfMarkerOnMap } from "../handlers/handleCreationOfMarkerOnMap";

const usePaintAllGlobalLayersOnMap = (
  layers: any,
  map: any,
  isMapFullyLoaded: boolean,
  LAYER_DEFAULT_COLOR: string,
  draw?: any,
  popups?: any[],
  setPopups?: Dispatch<SetStateAction<any[]>>,
  setNewPopup?: Dispatch<SetStateAction<any[]>>,
  isEditMode?: boolean,
  type: string = "",
  globalMarkers: any[] = [],
  setGlobalMarkers?: any
) => {
  useEffect(() => {
    if (layers && map && isMapFullyLoaded) {
      layers.forEach((layer: any) => {
        const status = setStatusIs(type, layer.status, isEditMode);
        const item = {
          item: layer,
          itemType: layer.type,
          status,
          shapeType: layer.shapeType,
        };

        if (item.item.shapeType === "point") {
          handleCreationOfMarkerOnMap(
            item,
            map,
            popups,
            setPopups,
            setNewPopup,
            isEditMode,
            (event: any, layerId: any) => {
              // dispatch(setSelectedPointLayerToPointSelectForm(event.lngLat));
              // setClickedPointForSelectPointForm({ event, layerId });
            },
            LAYER_DEFAULT_COLOR,
            globalMarkers,
            setGlobalMarkers
          );
        } else {
          handlePaintingOfVectorRegionShapeLayersOnMap(
            item,
            map,
            draw,
            LAYER_DEFAULT_COLOR,
            (item: any) => {},
            popups,
            setPopups,
            setNewPopup,
            isEditMode
          );
        }
      });
    }
  }, [map, isMapFullyLoaded, layers, isEditMode, type]);

  useEffect(() => {
    if (isEditMode) {
      // // Remove all popups when entering edit mode
      popups?.forEach((popup) => {
        popup?.popup?.remove();
      });

      // // Optionally, clear the popups from state to reflect the UI accurately
      if (setPopups) {
        setPopups([]);
      }
    }
  }, [isEditMode, setPopups, popups]);

  return null;
};

export { usePaintAllGlobalLayersOnMap };

function setStatusIs(
  type: any = null,
  layerStatus: boolean,
  isEditMode: boolean = false
) {
  if (type && ["drawpolygon", "droppin"].includes(type)) {
    return isEditMode === true ? false : layerStatus;
  } else {
    return layerStatus;
  }
}
