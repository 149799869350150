import React, { useEffect, useState } from "react";
import apiClient from "services/apiClient";
import { conditionalityForObservations } from "utils/conditionalityForObservations";
import mapToDropdownValues from "utils/mapDropdownValues";
import CustomCheckbox from "view/components/CheckBox";

interface CheckboxesProps {
  label: string;
  error?: any;
  name: string;
  editMode?: boolean;
  value: any;
  data: any[];
  handleChange?: any;
  handleBlur?: any;
  touched?: any;
  required?: boolean;
  choicesByUrl: {
    url: string;
    valueName: string;
    titleName: string;
  } | null;

  visibleIf?: any;
  enableIf?: Boolean;
  setValueIf?: Boolean;
  setValueExpression?: Boolean;
  requiredIf?: Boolean;
  answers: any;
  columns?: number;
  panel?: any;
  element?: any;
}

export const Checkboxes: React.FC<CheckboxesProps> = ({
  label,
  error,
  name,
  editMode = true,
  value,
  data,
  handleChange,
  handleBlur,
  touched,
  required,
  choicesByUrl = null,
  visibleIf = true,
  enableIf = false,
  setValueIf,
  setValueExpression,
  requiredIf,
  answers,
  columns = 1,
  panel,
  element,
}) => {
  const isVisible = true;

  const isEnabled = false;

  if (!isVisible) {
    return;
  }

  const [option, setOption] = useState([]);

  const getChecboxOptions = async (choicesByUrl: any) => {
    try {
      const { data } = await apiClient.get(choicesByUrl.url);
      const dropdownOptions = mapToDropdownValues(
        data,
        choicesByUrl.valueName,
        choicesByUrl.titleName,
        choicesByUrl.valueName,
        choicesByUrl.titleName
      );
      setOption(dropdownOptions);
    } catch (error: any) {
      return [];
    }
  };
  useEffect(() => {
    if (choicesByUrl !== null && editMode) {
      getChecboxOptions(choicesByUrl);
    }
  }, [editMode]);

  const onChange = (selectedItem: any) => {
    // Ensure value is treated as an array, defaulting to an empty array if null or undefined
    const currentValues = Array.isArray(value) ? value : [];

    let newValue = [...currentValues];

    if (typeof selectedItem === 'string') {
      // Handle array of strings
      const selectedIndex = currentValues.indexOf(selectedItem);

      if (selectedIndex === -1) {
        // Not found in the array, add it
        newValue.push(selectedItem);
      } else {
        // Found in the array, remove it
        newValue.splice(selectedIndex, 1);
      }
    } else {
      // Handle array of objects
      const refVal = choicesByUrl !== null ? choicesByUrl?.valueName : "value";
      const selectedIndex = currentValues.findIndex((item: any) => item[refVal] === selectedItem[refVal]);

      if (selectedIndex === -1) {
        // Not found in the array, add it
        newValue.push(selectedItem);
      } else {
        // Found in the array, remove it
        newValue.splice(selectedIndex, 1);
      }
    }

    handleChange(newValue);
  };


  return (
    <div className="flex flex-col flex-grow w-full rounded-lg dark:bg-secondaryLight bg-bgPrimaryLight p-4" id={`${element?.groupQuestionName || element?.name}${panel?.sectionId}`}>
      <div className="relative flex flex-col py-2">
        <p
          className={`w-full pb-1 text-md text-left capitalize font-Overpass ${editMode ? "text-primary dark:text-caption font-medium " : "text-primary dark:text-caption font-regular "
            }`}
        >
          {label || name} {required && <span>*</span>}
        </p>
        {editMode ? (
          <>
            <div
              className={`grid w-full border-secondary focus:outline-none gap-1.5 px-3 py-2 rounded dark:bg-secondaryLight`}
              style={{ gridTemplateColumns: `repeat(${columns === 0 ? 1 : columns}, minmax(0, 1fr))` }}
            >
              {choicesByUrl !== null ? (
                <RenderCheckboxesFromApi
                  items={option}
                  value={value}
                  handleChange={onChange}
                  required={required}
                  choicesByUrl={choicesByUrl}
                  isEnabled={isEnabled}
                />
              ) : (
                <RenderSimpleCheckboxes
                  items={data}
                  value={value}
                  handleChange={onChange}
                  required={required}
                  isEnabled={isEnabled}
                />
              )}
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {touched && error && (
                <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                  {error}
                </p>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              {(value && value.length > 0) ?
                <p className="w-full max-w-full max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] text-left font-normal font-Overpass text-textAnswer dark:text-textMain ">
                  {value && Array.isArray(value) ? choicesByUrl !== null ? extractAndJoinByKey(value, choicesByUrl?.titleName) : extractAndJoinByKey(value, "text") : getItemLabel(value, choicesByUrl)}
                </p>
                :
                <p className="text-textNoSelection font-Overpass font-normal text-[15px] italic">
                  (No Selection)
                </p>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Checkboxes;

interface IChoiceByUrl {
  url: string;
  valueName: string;
  titleName: string;
}

interface ICheckboxItem {
  [key: string]: any;
}

interface RenderSimpleCheckboxesProps {
  items: ICheckboxItem[];
  value: any;
  handleChange: any;
  required: any;
  isEnabled: boolean;
}

const RenderSimpleCheckboxes: React.FC<RenderSimpleCheckboxesProps> = ({
  items,
  value,
  handleChange,
  required,
  isEnabled,
}) => {
  const isChecked = (item: any) => {
    // If value is an array, check if any item in the array matches
    if (Array.isArray(value)) {
      return value.some((selectedItem: any) => selectedItem === item);
    }
    // If value is a single object, check if it matches the item directly
    return value?.value === item.value;
  };

  return (
    <>
      {items && items?.map((item: any, index) => {
        const itemLabel = getItemLabel(item, null);
        const itemValue = getItemValue(item, null);
        return (
          <CustomCheckbox
            className="disabled:cursor-not-allowed"
            disabled={isEnabled}
            key={index}
            onChange={() => {
              handleChange(item);
            }}
            checked={isChecked(item)}
            value={item}
            required={required}
            label={
              <span className="text-sm font-normal text-gray-800 font-overpass dark:text-caption">
                {itemLabel}
              </span>
            }
            labelPlacement="end"
          />
        );
      })}
    </>
  );
};

interface RenderCheckboxesFromApiProps {
  items: ICheckboxItem[];
  value: any;
  handleChange: any;
  required: any;
  choicesByUrl: IChoiceByUrl;
  isEnabled: boolean;
}

const RenderCheckboxesFromApi: React.FC<RenderCheckboxesFromApiProps> = ({
  items,
  value,
  handleChange,
  required,
  choicesByUrl,
  isEnabled,
}) => {

  const isChecked = (item: any) => {
    // If value is an array, check if any item in the array matches
    if (Array.isArray(value)) {
      return value.some((selectedItem: any) => selectedItem[choicesByUrl.valueName] === item[choicesByUrl.valueName]);
    }
    else {
      if (value) {
        // If value is a single object, check if it matches the item directly
        return value[choicesByUrl.valueName] === item[choicesByUrl.valueName];
      }
    }

  };


  return (
    <>
      {items.map((item: ICheckboxItem, index) => {
        const itemLabel = getItemLabel(item, choicesByUrl);
        return (
          <CustomCheckbox
            disabled={isEnabled}
            className="w-full disabled:cursor-not-allowed"
            key={index}
            onChange={(e: any) => {
              handleChange(item);
            }}
            checked={isChecked(item)}
            required={required}
            label={
              <span
                className={isEnabled ? "cursor-pointer" : "cursor-not-allowed"}
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#2C3236",
                }}
              >
                {itemLabel}
              </span>
            }
            labelPlacement="end"
          />
        );
      })}
    </>
  );
};

function getItemLabel(item: any, choicesByUrl: any) {
  if (item && typeof item === "object") {
    return choicesByUrl !== null ? item[choicesByUrl.titleName] : item?.text;
  }
  return item;
}
function getItemValue(item: any, choicesByUrl: any) {
  if (item && typeof item === "object") {
    return choicesByUrl !== null ? item[choicesByUrl.valueName] : item?.value;
  }
  return item;
}

function isItemChecked(item: any, value: any, choicesByUrl: any) {
  if (value === null) {
    return false;
  }

  if (typeof item === "object" && typeof value === "object") {
    return choicesByUrl !== null
      ? value[choicesByUrl.valueName] === item[choicesByUrl.titleName]
      : value?.value === item?.text;
  }

  return item === value;
}

function extractAndJoinByKey(dataArray: any[], keyToExtract: string) {
  // Map through the array, extract the key values if the item is an object, or use the item itself if it's a string
  let extractedValues = dataArray.map(item => typeof item === 'object' ? item[keyToExtract] : item).filter(value => value !== undefined);

  // Join the extracted values into a single string
  let joinedString = extractedValues.join(', ');

  return joinedString;
}
