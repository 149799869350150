import { useState } from "react";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import { CustomizedSwitches } from "view/components/CustomizedSwitches";
import Map from "./Map";
import { TextField } from "../../Components";

type QuestionElemet = {
  title?: string;
  name: string;
  isRequired: boolean;
  id: string;
  answer: {
    lat: number;
    lng: number;
  };

  description?: string;
  type: string;
  groupQuestionName?: string;
};
interface I_GetGpsData {
  formikProps: any;
  editMode: boolean;
  element: QuestionElemet;
  answer: {
    lat: number;
    lng: number;
  };
  setFieldValue: any;
  eleName: string;
  required?: boolean;
  error?: any;
  touched?: any;
  label?: string;
  panel?: any;
}

const GetGpsData = ({
  formikProps,
  editMode,
  element,
  answer = {
    lat: 0,
    lng: 0,
  },
  setFieldValue,
  eleName,
  required,
  error,
  touched,
  label,
  panel,
}: I_GetGpsData) => {
  const [doShowMap, setDoShowMap] = useState(false);
  return (
    <div className="p-4 rounded-lg dark:bg-secondaryLight bg-bgPrimaryLight" id={`${element?.groupQuestionName || element?.name}${panel?.sectionId}`}>
      <div className="flex items-center justify-between">
        <Name
          editMode={editMode}
          label={element.title}
          name={element.name}
          required={element.isRequired}
        />
        <div className="flex justify-end flex-1 basis-1/2">
          <CustomizedSwitches
            label="View map"
            onChange={(e: any) => setDoShowMap(e.target.checked)}
            labelClassName="text-secondary"
          />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6">
          <TextField
            label={"Latitude"}
            type="number"
            name={eleName}
            value={answer?.lat}
            handleChange={(value: any) => {
              const coordiantes = { ...answer };
              let newValue = value;

              const parsedValue = parseFloat(newValue);
              if (
                !isNaN(parsedValue) &&
                (parsedValue < -90 || parsedValue > 90)
              ) {
                return;
              }

              coordiantes.lat = parsedValue;
              // Update the formik state with the new value
              setFieldValue(eleName, coordiantes, element.name);
            }}
            handleBlur={() => formikProps.setFieldTouched(eleName, true, true)}
            editMode={editMode}
            placeholder={`Enter Latitude`}
            required={true}
            error={!answer?.lat && "Latitude is required"}
            touched={!answer?.lat && true}
          />
        </div>
        <div className="col-span-6">
          <TextField
            label={"Longitude"}
            type="number"
            name={eleName}
            value={answer?.lng}
            handleChange={(value: any) => {
              const coordiantes = { ...answer };
              let newValue = value;
              const parsedValue = parseFloat(newValue);
              if (
                !isNaN(parsedValue) &&
                (parsedValue < -180 || parsedValue > 180)
              ) {
                return;
              }

              coordiantes.lng = parsedValue;
              // Update the formik state with the new value
              setFieldValue(eleName, coordiantes, element.name);
            }}
            handleBlur={() => formikProps.setFieldTouched(eleName, true, true)}
            editMode={editMode}
            placeholder={`Enter Longitude`}
            required={true}
            error={!answer?.lng && "Longitude is required"}
            touched={!answer?.lng && true}
          />
        </div>
      </div>

      {doShowMap && <Map coordiantes={answer} editMode={editMode} />}

      <Error error={error} />
    </div>
  );
};

export { GetGpsData };

const Name = ({ editMode, label, name, required, drawMultiple }: any) => {
  return (
    <div>
      <p
        className={`w-full text-md text-left capitalize font-Overpass ${
          editMode
            ? "text-primary dark:text-caption font-medium "
            : "text-primary dark:text-caption font-regular "
        }`}
      >
        {label || name} {required && <span>*</span>}
      </p>
    </div>
  );
};

const Error = ({ error }: any) => {
  return (
    <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
      {error && (
        <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
          {error}
        </p>
      )}
    </div>
  );
};
