import { apiClient } from "../config";

async function getTheme(queryParams?: any) {
  const res = await apiClient.get(`/admin/theme`, {
    params: queryParams,
  });
  return res.data;
}

export default getTheme;
