import React, { useRef, useState } from 'react';
import { FormikProps, FormikValues } from 'formik';
import InformationCircleIcon from 'assets/images/information-circle.svg';
import CameraIcon from 'assets/icons/HeroIcons/CameraIcon';
import Button from 'view/components/Button';
import XCloseIcon from 'assets/icons/HeroIcons/XCloseIcon';
import TextInput from 'view/pages/MyProfile/Components/Inputs/TextInput';
import apiLibrary from 'services/api';
import { Toasts } from 'view/components/Toasts';
import { MAXIMUM_FILE_SIZE } from 'constants/FileSize';
import CustomCheckbox from 'view/components/CheckBox';
import InputField from 'view/components/InputField';
import MultiSelect from 'view/components/Multiselect';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { MaterialUiSelect } from 'view/components/Select';
import { getBrandPlaceholder, invitationAppNames, programInvitationApps } from 'utils/customBranding';
import useRoles from 'hooks/roles';

interface AddProgramProps extends FormikProps<FormikValues> {
  handleClose: any;
  activeStep: any;
  isLastStep: any;
  isFormLoading: any;
  organizations: any;
  fetchLeadOrganizations: () => void;
  handleLeadOrganizationSearch: any;
}

export const AddProgram: React.FC<AddProgramProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  handleClose,
  activeStep,
  isLastStep,
  isFormLoading,
  organizations,
  fetchLeadOrganizations,
  handleLeadOrganizationSearch,
}) => {
  const [isLoading, setIsLoading] = useState({
    profileImage: false,
    coverImage: false,
  });
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [coverImageUrl, setCoverImageUrl] = useState('');
  const coverImageRef = useRef<HTMLInputElement>(null);
  const profileImageRef = useRef<HTMLInputElement>(null);
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: 'profile' | 'cover'
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      if (!validImageTypes.includes(file.type)) {
        return Toasts.error('Please select a valid image file (jpeg/jpg/png)');
      }
      try {
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          [`${imageType}Image`]: true,
        }));

        const fileSize = file.size / 1024 / 1024; // convert bytes to mb

        if (fileSize > MAXIMUM_FILE_SIZE) {
          return Toasts.error('File size must be less than 10 mb!');
        }

        const uploadResponse = await apiLibrary.file.fileUpload(file);

        const objectUrl = URL.createObjectURL(file);

        if (imageType === 'cover') {
          setCoverImageUrl(objectUrl);

          setFieldValue('coverImageId', uploadResponse.data.id);
        } else {
          setProfileImageUrl(objectUrl);
          setFieldValue('profileImage', objectUrl);

          setFieldValue('profileImageId', uploadResponse.data.id);
        }
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errMsg);
      } finally {
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          profileImage: false,
          coverImage: false,
        }));
      }
    }
  };
  const handleClick = (imageType: 'profile' | 'cover') => {
    const inputRef = imageType === 'cover' ? coverImageRef : profileImageRef;
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const handleAddOrganizationSelector = (options: any) => {
    setFieldValue('leadingOrganizationId', options);
    setFieldValue('adminIds', []);
  };
  const handleAddTimeZoneSelector = (options: any) => {
    setFieldValue('timeZone', options);
  };

  return (
    <div className='flex flex-col justify-start items-start w-[700px] rounded-lg'>
      <div
        className='relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite'
        style={{
          boxShadow:
            '0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)',
        }}
      >
        <Heading handleClose={handleClose} />

        <div className='overflow-y-auto px-1 w-full max-h-[60vh]'>
          <ImageUploaderBar
            isLoading={isLoading}
            handleImageUpload={handleImageUpload}
            profileImageUrl={profileImageUrl}
            handleClick={handleClick}
            coverImageRef={coverImageRef}
            coverImageUrl={coverImageUrl}
            profileImageRef={profileImageRef}
          />
          <InputFields
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            handleAddOrganizationSelector={handleAddOrganizationSelector}
            organizations={organizations}
            handleLeadOrganizationSearch={handleLeadOrganizationSearch}
            handleAddTimeZoneSelector={handleAddTimeZoneSelector}
            fetchLeadOrganizations={fetchLeadOrganizations}
          />
        </div>

        <SubmissionButtons
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          activeStep={activeStep}
          values={values}
          isFormLoading={isFormLoading}
          isLastStep={isLastStep}
        />
      </div>
    </div>
  );
};
const Heading = ({ handleClose }: any) => {
  return (
    <div className='flex justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5'>
      <div className='relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0'>
        <p className='flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain'>
          Add Program
        </p>
      </div>
      <button onClick={handleClose} type='button'>
        <XCloseIcon width={24} height={24} viewBox='0 0 24 24' />
      </button>
    </div>
  );
};
const ImageUploaderBar = ({
  isLoading,
  handleImageUpload,
  profileImageUrl,
  handleClick,
  coverImageRef,
  coverImageUrl,
  profileImageRef,
}: any) => {
  return (
    <div className='relative flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-2'>
      <div className='relative w-1/4 h-28 '>
        <div className='absolute top-0 left-0 rounded-full w-28 h-28'>
          {isLoading.profileImage && (
            <div className='absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
              <svg
                className='w-8 h-8 animate-spin text-primaryExtraLight'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                ></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                ></path>
              </svg>
            </div>
          )}
          <input
            disabled={isLoading.profileImage}
            type='file'
            name='profile'
            className='absolute top-0 left-0 hidden opacity-0 cursor-pointer'
            multiple={false}
            ref={profileImageRef}
            accept='.jpg, .jpeg, .png'
            onChange={(event) => handleImageUpload(event, 'profile')}
          />

          <img
            className='absolute left-[-1px] top-[-1px] w-full h-full rounded-full object-cover'
            src={profileImageUrl ? profileImageUrl : getBrandPlaceholder("profile")}
          />
        </div>
        <button
          disabled={isLoading.profileImage}
          type='button'
          onClick={() => handleClick('profile')}
          className='flex justify-center items-center absolute left-[78px] top-[78px] gap-2 p-0.5 w-8 h-8 rounded-3xl bg-bgWhite dark:bg-secondaryLight'
        >
          <CameraIcon className='w-5 h-5' />
        </button>
      </div>

      <div className='flex flex-col justify-start items-start flex-grow relative gap-1.5'>
        <div className='self-stretch flex-grow-0 flex-shrink-0 h-[120px] relative overflow-hidden'>
          <div className='w-full h-[120px] absolute left-[-1px] top-[-1px]' />
          {isLoading.coverImage && (
            <div className='absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-500 bg-opacity-50'>
              <div className='inline-flex items-center'>
                <svg
                  className='w-12 h-12 mr-2 animate-spin text-primaryExtraLight'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              </div>
            </div>
          )}
          <input
            disabled={isLoading.coverImage}
            type='file'
            name='cover'
            className='absolute top-0 left-0 hidden opacity-0 cursor-pointer'
            multiple={false}
            ref={coverImageRef}
            accept='.jpg, .jpeg, .png'
            onChange={(event) => handleImageUpload(event, 'cover')}
          />

          <div className='flex justify-start items-start absolute right-0 top-0 gap-2 p-0.5 rounded-3xl bg-white'>
            <button
              type='button'
              disabled={isLoading.coverImage}
              onClick={() => handleClick('cover')}
              className='absolute right-0 flex items-center justify-center mt-1 mr-1 border-none rounded-full outline-none h-9 w-9 bg-bgWhite dark:bg-secondaryLight '
            >
              <CameraIcon className='w-7 h-7 ' />
            </button>
          </div>
          <img
            src={coverImageUrl ? coverImageUrl : getBrandPlaceholder("modalCover")}
            className='w-full h-full '
          />
        </div>

        <div className='flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5'>
          <p className='self-stretch flex-grow-0 flex-shrink-0 w-full text-sm text-left text-textMidLight dark:text-textMain '>
            Visit{' '}
            <a
              className='font-medium text-primary'
              href='https://www.iloveimg.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://www.iloveimg.com
            </a>{' '}
            to crop your image. For the best result on all devices, use a file
            of <span className='font-medium'>10 MB</span> or less.
          </p>
        </div>
      </div>
    </div>
  );
};
const InputFields = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  handleAddOrganizationSelector,
  organizations,
  handleLeadOrganizationSearch,
  fetchLeadOrganizations
}: any) => {
  const { isSuperAdmin } = useRoles();
  const isSkipperScienceSelected = values?.appName?.value === invitationAppNames.SKIPPER_SCIENCE.value;
  return (
    <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight'>
      <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight'>
        <TextInput
          label='Program Name*'
          type='text'
          placeholder='Program Name'
          name='name'
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.name}
          error={errors?.name}
          touched={touched.name}
        />
      </div>
      <div className='flex flex-col items-start justify-start w-1/2 pr-2'>
        <TextInput
          label='Program Code*'
          type='text'
          placeholder='XXXX'
          name='code'
          handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            if (value.length > 4) {
              e.preventDefault();
              return;
            }
            handleChange(e);
          }}
          onKeyDown={preventNonAlphabetic}
          handleBlur={handleBlur}
          value={values.code}
          error={errors?.code}
          touched={touched.code}
        />
      </div>
      <div className='mb-2'>
        <InfoLabel message='Enter a 4 letter program code, this code will be used to generate observation codes for observations made in this program.' />
      </div>
      <div className='grid items-center w-full grid-cols-2 gap-4'>
        <InputField
          type='date'
          label='Start Date*'
          placeholder='YYYY/MM/DD'
          value={values.startDate}
          className={'py-[-2px] w-full'}
          name='startDate'
          error={errors?.startDate}
          onChange={(values: any) => setFieldValue('startDate', values)}
          onBlur={handleBlur}
        />
        <InputField
          type='date'
          label='End Date'
          placeholder='YYYY/MM/DD'
          value={values.endDate}
          className={'py-[-2px] w-full'}
          name='endDate'
          error={errors?.endDate}
          onChange={(values: any) => setFieldValue('endDate', values)}
          onBlur={handleBlur}
        />
      </div>
      <div className='mb-2'>
        <InfoLabel message='The program will only become active once the start date has been reached.' />
      </div>

      {isSuperAdmin() ? (
        <div className='w-full my-4'>
          <MaterialUiSelect
            options={programInvitationApps}
            placeholder={'Select An App'}
            labelKey='label'
            valueKey='value'
            handleChange={(item: any) => {
              setFieldValue('appName', item);
              // fetchLeadOrganizations(item?.value);
            }}
            initialValue={values?.appName?.value}
            error={errors?.appName?.label}
          />
        </div>
      ) : null}

      <div className='w-full mb-4'>
        <TextInput
          label='Description'
          type='text'
          rows={3}
          fieldAs='textarea'
          placeholder='Add your description here...'
          name='description'
          handleChange={handleChange}
          handleBlur={handleBlur}
          value={values.description}
          error={errors?.description}
          touched={touched.description}
        />
        {!errors?.description && (
          <p className='flex-grow text-xs text-left text-textMidLight dark:text-textMain'>
            6000 Max Characters
          </p>
        )}
      </div>
      {isSkipperScienceSelected && <div>
        <CustomCheckbox
          name='isGlobalForSkipper'
          checked={values.isGlobalForSkipper}
          onChange={handleChange}
          // indeterminate={isFormSectionAllChecked? false: isFormSectionSomeChecked}
          label={
            <p className='text-secondaryMid font-Overpass dark:text-textMain'>
              This program should be available to all Skipper Science observers.
            </p>
          }
          labelPlacement='end'
        />
      </div>}
      <div>
        <CustomCheckbox
          name='requireQa'
          checked={values.requireQa}
          onChange={handleChange}
          // indeterminate={isFormSectionAllChecked? false: isFormSectionSomeChecked}
          label={
            <p className='text-secondaryMid font-Overpass dark:text-textMain'>
              Observations in this program do not need to go through a QA
              process.
            </p>
          }
          labelPlacement='end'
        />
      </div>
      <div className='mb-4'>
        <CustomCheckbox
          name='isPublic'
          checked={values.isPublic}
          onChange={handleChange}
          // indeterminate={isFormSectionAllChecked? false: isFormSectionSomeChecked}
          label={
            <p className='text-secondaryMid font-Overpass dark:text-textMain'>
              Program metadata publicly accessible.
            </p>
          }
          labelPlacement='end'
        />
      </div>
      {/* {programs.canViewOrganizationsDropdown && ( */}
      <AddOrganization
        touched={touched}
        handleAddOrganizationSelector={handleAddOrganizationSelector}
        organizations={organizations}
        errors={errors}
        values={values}
        handleLeadOrganizationSearch={handleLeadOrganizationSearch}
      />
      {/* )} */}
    </div>
  );
};

const SubmissionButtons = ({
  handleClose,
  handleSubmit,
  activeStep,
  values,
  isFormLoading,
  isLastStep,
}: any) => {
  return (
    <div className='flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2'>
      <Button
        type='button'
        text='Cancel'
        filledColor='primary'
        outlinedColor='primary'
        textColor='textWhite'
        className='px-5 py-2'
        width='35'
        height='13'
        fontStyle='font-semibold'
        variant='outlined'
        onClick={handleClose}
      />
      <Button
        type='button'
        onClick={handleSubmit}
        disabled={
          (activeStep === 1 && values.adminIds.length === 0) ||
          (activeStep === 2 && values.organizationIds.length === 0) ||
          isFormLoading
        }
        text={isLastStep ? 'Save' : 'Next'}
        filledColor='primary'
        outlinedColor='primary'
        textColor='textWhite'
        className='px-5 py-2'
        width='35'
        height='13'
        fontStyle='font-semibold'
        variant='filled'
      />
    </div>
  );
};
const InfoLabel = ({ message }: any) => {
  return (
    <div className='flex items-center gap-2'>
      <img src={InformationCircleIcon} alt='Info' />
      <p className='text-sm text-secondaryMid font-Overpass dark:text-textMain'>
        {message}
      </p>
    </div>
  );
};
const AddOrganization = ({
  touched,
  errors,
  handleAddOrganizationSelector,
  handleLeadOrganizationSearch,
  organizations,
  values,
  isDisbled,
}: any) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState({
    label: '',
    value: '',
  });
  const { addProgramModal } = useSelector((state: RootState) => state.modals);

  return (
    <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2'>
      <div className='flex items-center self-stretch justify-start w-full'>
        <div className='flex flex-col items-start self-stretch justify-start flex-grow gap-1 rounded'>
          <div className='relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pt-1'>
            <p className='flex-grow w-[596px] text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption '>
              Search to add Organization that is leading the Program*{' '}
            </p>
          </div>
          <div
            className={`flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5 px-3  rounded bg-white border ${touched.leadingOrganizationId &&
              errors.leadingOrganizationId?.value
              ? 'border-accent_1Dark'
              : 'border-lineDark dark:border-lineLight'
              }`}
          >
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='relative flex-grow-0 flex-shrink-0 w-6 h-6'
              preserveAspectRatio='xMidYMid meet'
            >
              <path
                d='M21.7495 20.6895L16.0855 15.0255C17.4466 13.3914 18.1253 11.2956 17.9805 9.17389C17.8356 7.05219 16.8784 5.06801 15.3079 3.6341C13.7374 2.2002 11.6745 1.42697 9.54844 1.47528C7.42236 1.52359 5.39674 2.38971 3.89298 3.89347C2.38922 5.39723 1.5231 7.42284 1.47479 9.54893C1.42648 11.675 2.19971 13.7379 3.63361 15.3084C5.06752 16.8789 7.0517 17.8361 9.1734 17.981C11.2951 18.1258 13.391 17.4471 15.025 16.086L20.689 21.75L21.7495 20.6895ZM2.99948 9.74996C2.99948 8.41494 3.39536 7.1099 4.13706 5.99987C4.87876 4.88983 5.93296 4.02467 7.16636 3.51378C8.39976 3.00289 9.75696 2.86921 11.0663 3.12966C12.3757 3.39011 13.5784 4.03299 14.5224 4.97699C15.4665 5.921 16.1093 7.12373 16.3698 8.4331C16.6302 9.74248 16.4966 11.0997 15.9857 12.3331C15.4748 13.5665 14.6096 14.6207 13.4996 15.3624C12.3895 16.1041 11.0845 16.5 9.74948 16.5C7.95987 16.498 6.24414 15.7862 4.9787 14.5207C3.71326 13.2553 3.00146 11.5396 2.99948 9.74996Z'
                fill='#9B9FA5'
              />
            </svg>
            <div className='flex flex-col items-start justify-start w-full'>
              <MultiSelect
                inputValue={inputValue}
                onInputChange={(value: string) => {
                  setInputValue(value);
                  handleLeadOrganizationSearch(value);
                }}
                isDisable={addProgramModal.data !== null}
                options={organizations}
                defaultValue={{ value: '', label: '' }}
                isMulti={false}
                value={[
                  selectedOption?.value
                    ? selectedOption
                    : values.leadingOrganizationId,
                ]}
                transformY='translateY(-123%)'
                onChange={
                  (option: any) => {
                    handleAddOrganizationSelector(option);
                    setSelectedOption(option);
                  }
                  // handleCommunityChange(
                  //   selectedOptions,
                  //   setFieldValue
                  // )
                }
              />
            </div>
          </div>
          <div className='flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5'>
            {touched.leadingOrganizationId &&
              errors.leadingOrganizationId?.value && (
                <p className='flex-grow w-[1/2] text-xs text-left text-accent_1Dark'>
                  {errors.leadingOrganizationId?.value}
                </p>
              )}
          </div>
        </div>
      </div>
      <div className='relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0'>
        <p className='self-stretch flex-grow-0 flex-shrink-0 w-[596px] text-sm text-left text-secondaryMidLight dark:text-textMain'>
          Any other participating organizations are chosen at a later stage.
        </p>
      </div>
    </div>
  );
};

// const AddTimeZone = ({
//   touched,
//   errors,
//   handleAddTimeZoneOnChange,
//   values,
// }: any) => {
//   const timeZones = timeZoneMoment.tz.names().map((item) => {
//     return {
//       value: item,
//       label: item,
//     };
//   });

//   const [inputValue, setInputValue] = useState("");
//   const { timeZone } = values;
//   return (
//     <div className="flex flex-col flex-grow w-full rounded-lg">
//       <div className="relative flex flex-col py-2">
//         <p
//           className={`flex-grow w-full text-sm font-medium text-left capitalize ${
//             touched.timeZone && errors.timeZone ? "text-accent_1Dark" : ""
//           }`}
//         >
//           Select Time Zone*
//         </p>

//         <>
//           <div
//             className={classNames(
//               "flex",
//               "justify-start",
//               "items-center",
//               "w-full",
//               "focus:outline-none",
//               "gap-1.5",
//               "rounded",
//               "bg-white",
//               "border",
//               "pb-1",
//               {
//                 "border-accent_1Dark": touched.timeZone && errors.timeZone,
//                 "border-lineDark": !touched.timeZone || !errors.timeZone,
//               }
//             )}
//           >
//             <MultiSelect
//               inputValue={inputValue}
//               onInputChange={(value: string) => {
//                 setInputValue(value);
//                 // handleAddProgramSearch(value);
//               }}
//               placeholder="Select Time Zone"
//               options={timeZones}
//               defaultValue={{ value: "", label: "" }}
//               isMulti={false}
//               value={[timeZone]}
//               // transformY="translateY(-130%)"
//               onChange={
//                 (option: any) => {
//                   handleAddTimeZoneOnChange(option);
//                   // setSelectedOption(option);
//                 }
//                 // handleCommunityChange(
//                 //   selectedOptions,
//                 //   setFieldValue
//                 // )
//               }
//             />
//           </div>
//           <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
//             {touched.timeZone && errors.timeZone && (
//               <p className="flex-grow capitalize w-[1/2] text-xs text-left text-accent_1Dark">
//                 {errors.timeZone.value}
//               </p>
//             )}
//           </div>
//         </>
//       </div>
//     </div>
//   );
// };

export function preventNonAlphabetic(e: React.KeyboardEvent<HTMLInputElement>) {
  const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];

  const isCopyPasteCutAllowed =
    (e.ctrlKey || e.metaKey) && ['c', 'v', 'x'].includes(e.key.toLowerCase());

  if (
    !/^[a-zA-Z]+$/.test(e.key) &&
    !allowedKeys.includes(e.key) &&
    !isCopyPasteCutAllowed
  ) {
    e.preventDefault();
  }
}
