import { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { Toasts } from "view/components/Toasts";
import { OptionsModal, PageHeading, AddProgramBtn } from "./components";

// APIs Services
import apiLibrary from "services/api";
// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import {
  openAddExistingCommunityModalAction,
  openAddProgramModalAction,
} from "store/modals/reducer.actions";
import { sentenceCase } from "change-case";
import { filtersInitialState } from "store/filters/initialState";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";

const generateDynamicOrganizationsLinks = (
  communityId: any,
  organizationId: any,
  name: any,
  label: any
) => {
  switch (true) {
    case Boolean(communityId):
      return [
        { path: `/community/list`, label: `Communities` },
        {
          path: `/community/${communityId}/profile`,
          label: name,
        },
        {
          path: `/community/${communityId}/programs`,
          label: `Programs`,
        },
      ];
    case Boolean(organizationId):
      return [
        {
          path: `/organizations/list`,
          label: `Organizations`,
        },
        {
          path: `/organizations/${organizationId}/profile`,
          label: name,
        },
        {
          path: `/organizations/${organizationId}/programs`,
          label: `Programs`,
        },
      ];
    default:
      return [
        { path: `/programs/list`, label: `Programs` },
        //
      ];
  }
};

export const Header = () => {
  const [BreadcrumbData, setBreadcrumbData] = useState<any>({
    name: "",
    id: "",
    openAccess: false,
  });

  const [IsShowExistingOptionsModal, setIsShowExistingOptionsModal] =
    useState(false);
  const { programsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { programs } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { organizationId, communityId } = useParams<{
    organizationId: string;
    communityId: string;
  }>();

  const dynamicProgramsLinks = generateDynamicOrganizationsLinks(
    communityId ?? "",
    organizationId ?? "",
    BreadcrumbData?.name ?? "",
    programsFilter.status
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (organizationId) {
      getOrganizationDetails(organizationId)
        .then((data) => {
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/organizations/list");
        });
    } else if (communityId) {
      getCommunityDetails(communityId)
        .then((data) => {
          setBreadcrumbData(data);
        })
        .catch((error) => {
          const errMsg = error?.response?.data?.message ?? error.message;
          Toasts.error(errMsg);
          navigate("/community/list");
        });
    }
  }, [organizationId, communityId]);

  // Handlers

  // displaying models based on communityId
  const handleAddProgramModal = () => {
    // if (communityId) {
    //   setIsShowExistingOptionsModal(!IsShowExistingOptionsModal);
    // } else {
    //   dispatch(openAddProgramModalAction());
    // }
    if (organizationId) {
      dispatch(
        openAddProgramModalAction({
          organizationName: BreadcrumbData?.name ?? null,
          organizationId: BreadcrumbData?.id ?? null,
        })
      );
    } else {
      dispatch(openAddProgramModalAction(null));
    }
  };
  // displaying PersonModal and hiding OptionsModal
  const handleClickOnCreateNewBtn = () => {
    dispatch(openAddProgramModalAction(null));
    setIsShowExistingOptionsModal(false);
  };
  // displaying SearchCommunityModal and hiding OptionsModal
  const handleClickOnAddExistingBtn = () => {
    setIsShowExistingOptionsModal(false);
  };

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicProgramsLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading
              id={
                organizationId ? organizationId : communityId ? communityId : ""
              }
              name={BreadcrumbData?.name}
              parentName={
                organizationId
                  ? "Organizations"
                  : communityId
                    ? "Communities"
                    : ""
              }
            />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              {!communityId &&
                !BreadcrumbData?.openAccess &&
                programs.canCreateProgram && (
                  <AddProgramBtn
                    label="Add Program"
                    handleAddProgramModal={handleAddProgramModal}
                  />
                )}

              <OptionsModal
                isOpen={IsShowExistingOptionsModal}
                handleClickOnCreateNewBtn={handleClickOnCreateNewBtn}
                handleAddProgramModal={handleAddProgramModal}
                handleClickOnAddExistingBtn={handleClickOnAddExistingBtn}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderAddOrganizationBtn = ({
  breadcrumbData,
}: {
  breadcrumbData: any;
}) => {
  const { communityId, organizationId } = useParams();
  const dispatch = useDispatch();

  if (communityId) {
    return (
      <></>
      // <AddProgramBtn
      //   handleAddProgramModal={() => {
      //     dispatch(
      //       openAddExistingOrgzanizationsModalAction({
      //         communityId,
      //         entity: "Community",
      //       })
      //     );
      //   }}
      //   label={"Connect  Communities"}
      // />
    );
  } else if (organizationId) {
    if (breadcrumbData.showAddButton)
      return (
        <AddProgramBtn
          handleAddProgramModal={() => {
            dispatch(openAddExistingCommunityModalAction(organizationId));
          }}
          label={"Connect  Organizations"}
        />
      );
  } else {
    return (
      <AddProgramBtn
        handleAddProgramModal={() => {
          dispatch(
            openAddProgramModalAction({
              organizationName: breadcrumbData?.name ?? null,
              organizationId: breadcrumbData?.id ?? null,
            })
          );
        }}
        label={"Add Program"}
      />
    );
  }
};
// controllers
async function getOrganizationDetails(organizationId: string) {
  try {
    const { data } =
      await apiLibrary.OrganizationDirectory.getOrganizationProfile(
        organizationId ? organizationId : ""
      );
    return data;
  } catch (error: any) {
    throw error;
  }
}

async function getCommunityDetails(communityId: string) {
  try {
    const { data } = await apiLibrary.Communities.getCommunityProfile(
      communityId ? communityId : ""
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
