import fileUpload from "./fileUpload";
import fileDownload from "./fileDownload";
import surveyFileUpload from "./surveyFileUpload"
import getFileViaId from "./getFileViaId";
const file = {
  fileUpload,
  fileDownload,
  surveyFileUpload,
  getFileViaId
};

export default file;
