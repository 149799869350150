import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeEditProgramModalAction } from "store/modals/reducer.actions";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import CameraIcon from "assets/icons/HeroIcons/CameraIcon";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { MAXIMUM_FILE_SIZE } from "constants/FileSize";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import CustomCheckbox from "view/components/CheckBox";
import MultiSelect from "view/components/Multiselect";
import { fetchProgramProfileAction } from "store/programProfile/reducer.actions";
import moment from "moment";
import InformationCircleIcon from "assets/images/information-circle.svg";
import InputField from "view/components/InputField";
import { preventNonAlphabetic } from "../AddProgramModal/components/AddProgram";
import { formatTheDates } from "utils/formatTheDates";
import { formatTheTime } from "utils/formatTheTime";
import { TIME_FORMATS } from "constants/timeFormats";
import usePermissions from "hooks/usePermissions";
import {
  getBrandName,
  getBrandPlaceholder,
  invitationAppNames,
  programInvitationApps,
} from "utils/customBranding";
import useRoles from "hooks/roles";
import { MaterialUiSelect } from "view/components/Select";
import axios from "axios";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

interface EditProgramModalProps {}

const currentValidationSchema = Yup.object().shape({
  coverImageId: Yup.number().nullable(),
  profileImageId: Yup.number().nullable(),

  description: Yup.string().max(6000, "6000 Max Characters"),
  name: Yup.string().required("Program Name is required"),
  code: Yup.string()
    .required("Program Code is required")
    .min(4, "Program Code must be 4 character")
    .max(4, "Program Code must be 4 character"),
  appName: Yup.object().shape({
    value: Yup.string(),
    label: Yup.string(),
  }),
  startDate: Yup.mixed()
    .required("Start date is required")
    .test("startDate", "Start date must be before end date", function (value) {
      const endDate = this.parent.endDate;

      if (endDate && value) {
        return moment(value).isBefore(endDate);
      }
      return true;
    }),
  endDate: Yup.mixed()
    .nullable()
    .test("endDate", "End date must be after start date", function (value) {
      const startDate = this.parent.startDate;
      if (startDate && value) {
        return moment(value).isAfter(startDate);
      }
      return true;
    }),
  requireQa: Yup.boolean(),
  isPublic: Yup.boolean(),
});
export const EditProgramModal: React.FC<EditProgramModalProps> = ({}) => {
  const { editProgramModal } = useSelector((state: RootState) => state.modals);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [isLoading, setIsLoading] = useState({
    profileImage: false,
    coverImage: false,
  });
  const { programs } = usePermissions();
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const coverImageRef = useRef<HTMLInputElement>(null);
  const profileImageRef = useRef<HTMLInputElement>(null);
  const [searchString, setSearchString] = useState("");
  const [appName, setAppName] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [selectedValues, setSelectedValues] = useState({
    value: "",
    label: "",
  });
  const { isSuperAdmin } = useRoles();

  useEffect(() => {
    setProfileImageUrl(editProgramModal?.data?.profileImage);
    setCoverImageUrl(editProgramModal?.data?.coverImage);
    if (editProgramModal.data && editProgramModal.data.leadingOrganizationId) {
      const { id: value, name: label } =
        editProgramModal?.data?.leadingOrganizationId;
      setSelectedValues({ value, label });
    }
  }, [editProgramModal?.data]);

  // // Use Effect to run the function
  useEffect(() => {
    getBrandsData();
  }, []);

  // function to get all the brands
  const getBrandsData = async () => {
    const resultData = getBrandName("brands", searchString);
    const { data } = await axios.get(resultData.url);
    if (data) {
      const appList = data.map((item: any) => ({
        value: item.value,
        label: item.text,
      }));
      setAppName(appList);
    }
  };

  // Store the validation schema for each step separately

  const handleClose = () => {
    setProfileImageUrl("");
    setCoverImageUrl("");
    dispatch(closeEditProgramModalAction());
  };

  const { profileSettings } = useSelector(
    (state: RootState) => state.userProfileSettings
  );

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    if (!programs.canEditProgram) {
      return;
    }
    try {
      const {
        coverImageId,
        profileImageId,
        name,
        code,
        startDate,
        endDate,
        description,
        requireQa,
        isPublic,
        startTime,
        endTime,
        isGlobalForSkipper,
      } = values;

      const startOfTheDay = getMidnightInLocalTimeZoneToUtc(
        startDate,
        startTime,
        profileSettings.timezone
      );
      const endOfTheDay = getMidnightInLocalTimeZoneToUtc(
        endDate,
        endTime || "23:59:59",
        profileSettings.timezone
      );

      const res = await apiLibrary.Programs.editProgram(
        editProgramModal.data.programId,
        {
          coverImageId,
          profileImageId,
          name,
          code,
          startDate: formatTheDates(startOfTheDay, { utc: false }),
          endDate: formatTheDates(endOfTheDay, { utc: false }),
          startTime: formatTheTime(
            startOfTheDay,
            TIME_FORMATS.HOURS_MINUTES_24H,
            { utc: false }
          ),
          endTime: formatTheTime(endOfTheDay, TIME_FORMATS.HOURS_MINUTES_24H, {
            utc: false,
          }),
          description,
          requireQa,
          isPublic,
          isGlobalForSkipper,
        }
      );
      dispatch(fetchProgramProfileAction(editProgramModal.data.programId));

      Toasts.success(res.message);
      handleClose();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: "profile" | "cover",
    setFieldValue: any
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      try {
        const fileSize = file.size / 1024 / 1024; // convert bytes to mb
        if (fileSize > MAXIMUM_FILE_SIZE) {
          return Toasts.error("File size must be less than 10 mb!");
        }

        setIsLoading((prevLoading) => ({
          ...prevLoading,
          [`${imageType}Image`]: true,
        }));

        const uploadResponse = await apiLibrary.file.fileUpload(file);

        const objectUrl = URL.createObjectURL(file);
        if (imageType === "cover") {
          setCoverImageUrl(objectUrl);

          setFieldValue("coverImageId", uploadResponse.data.id);
        } else {
          setProfileImageUrl(objectUrl);

          setFieldValue("profileImageId", uploadResponse.data.id);
        }
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errMsg);
      } finally {
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          profileImage: false,
          coverImage: false,
        }));
      }
    }
  };

  const handleClick = (imageType: "profile" | "cover") => {
    const inputRef = imageType === "cover" ? coverImageRef : profileImageRef;
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const InfoLabel = ({ message }: any) => {
    return (
      <div className="flex items-center gap-2">
        <img src={InformationCircleIcon} alt="Info" />
        <p className="text-sm text-secondaryMid font-Overpass">{message}</p>
      </div>
    );
  };

  return (
    <Modal
      open={editProgramModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className=" dark:bg-secondaryLight">
        <Formik
          initialValues={editProgramModal.data}
          validationSchema={currentValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
          }) => {
            const isSkipperScienceSelected =
              values?.appName?.value ===
              invitationAppNames.SKIPPER_SCIENCE.value;
            return (
              <Form>
                <div className="w-[100%]  rounded">
                  <div className="relative flex flex-col items-start justify-start gap-4 p-6 rounded-lg bg-bgWhite dark:bg-secondaryLight">
                    <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                        <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                          Edit Program
                        </p>
                      </div>
                      <button onClick={handleClose} type="button">
                        <XCloseIcon
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                        />
                      </button>
                    </div>

                    <div className="overflow-y-auto px-1 w-full max-h-[60vh]">
                      <div className="relative flex gap-6 p-2">
                        <div className="relative w-1/5 h-28">
                          <div className="absolute top-0 left-0 rounded-full w-28 h-28">
                            {isLoading.profileImage && (
                              <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-500 bg-opacity-50">
                                <div className="inline-flex items-center">
                                  <svg
                                    className="w-12 h-12 mr-2 animate-spin text-primaryExtraLight"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            )}
                            <input
                              disabled={isLoading.profileImage}
                              type="file"
                              name="profile"
                              className="absolute top-0 left-0 hidden opacity-0 cursor-pointer"
                              multiple={false}
                              ref={profileImageRef}
                              accept=".jpg, .jpeg, .png"
                              onChange={(event) =>
                                handleImageUpload(
                                  event,
                                  "profile",
                                  setFieldValue
                                )
                              }
                            />
                            <img
                              className="absolute left-[-1px] top-[-1px] w-full h-full rounded-full"
                              src={
                                profileImageUrl
                                  ? profileImageUrl
                                  : getBrandPlaceholder("profile")
                              }
                            />
                          </div>
                          <button
                            disabled={isLoading.profileImage}
                            type="button"
                            onClick={() => handleClick("profile")}
                            className="flex justify-center items-center absolute left-[78px] top-[78px] gap-2 p-0.5 w-8 h-8 rounded-3xl bg-bgWhite dark:bg-secondaryLight"
                          >
                            <CameraIcon className="w-5 h-5" />
                          </button>
                        </div>
                        <div className="flex flex-col justify-start items-start relative gap-1.5 w-3/3">
                          <div className="w-full h-[120px] overflow-hidden">
                            {isLoading.coverImage && (
                              <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-500 bg-opacity-50">
                                <div className="inline-flex items-center">
                                  <svg
                                    className="w-12 h-12 mr-2 animate-spin text-primaryExtraLight"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            )}
                            <input
                              disabled={isLoading.coverImage}
                              type="file"
                              name="cover"
                              className="absolute top-0 left-0 hidden opacity-0 cursor-pointer"
                              multiple={false}
                              ref={coverImageRef}
                              accept=".jpg, .jpeg, .png"
                              onChange={(event) =>
                                handleImageUpload(event, "cover", setFieldValue)
                              }
                            />
                            <img
                              src={
                                coverImageUrl
                                  ? coverImageUrl
                                  : getBrandPlaceholder("modalCover")
                              }
                              className="w-full h-full"
                            />
                            <button
                              type="button"
                              disabled={isLoading.coverImage}
                              onClick={() => handleClick("cover")}
                              className="absolute flex items-center justify-center gap-2 top-1 right-1 rounded-3xl w-9 h-9 bg-bgWhite"
                            >
                              <CameraIcon className="relative flex-grow-0 flex-shrink-0 w-6 h-6" />
                            </button>
                          </div>
                          <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-full text-sm text-left text-textMidLight dark:text-textMain ">
                              Visit{" "}
                              <a
                                className="font-medium text-primary"
                                href="https://www.iloveimg.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://www.iloveimg.com
                              </a>{" "}
                              to crop your image. For the best result on all
                              devices, use a file of{" "}
                              <span className="font-medium">10 MB</span> or
                              less.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
                        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
                          <TextInput
                            label="Program Name*"
                            type="text"
                            placeholder="Program Name"
                            name="name"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.name}
                            error={errors?.name}
                            touched={touched.name}
                          />
                        </div>
                        <div className="flex flex-col items-start justify-start w-1/2 pr-2">
                          <TextInput
                            label="Program Code*"
                            type="text"
                            placeholder="XXXX"
                            name="code"
                            disabled={editProgramModal?.data?.openAccess}
                            handleChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const { value } = e.target;
                              if (value.length > 4) {
                                e.preventDefault();
                                return;
                              }
                              handleChange(e);
                            }}
                            onKeyDown={preventNonAlphabetic}
                            handleBlur={handleBlur}
                            value={values.code}
                            error={errors?.code}
                            touched={touched.code}
                          />
                        </div>
                        <div className="mb-2">
                          <InfoLabel message="Enter a 4 letter program code, this code will be used to generate observation codes for observations made in this program." />
                        </div>
                        <div className="grid items-center w-full grid-cols-2 gap-4">
                          <InputField
                            type="date"
                            label="Start Date*"
                            placeholder="YYYY/MM/DD"
                            className={"py-[-2px] w-full"}
                            name="startDate"
                            value={values.startDate}
                            error={errors?.startDate}
                            // touched={touched.startDate}
                            onChange={(values: any) =>
                              setFieldValue("startDate", values)
                            }
                            onBlur={handleBlur}
                            // min={moment().format(DATE_FORMATS.DEFAULT)}
                            disabled={
                              editProgramModal.data.status === "active" ||
                              editProgramModal.data.status === "archived"
                            }
                          />

                          <InputField
                            type="date"
                            label="End Date"
                            placeholder="YYYY/MM/DD"
                            className={"py-[-2px] w-full"}
                            name="startDate"
                            value={values.endDate}
                            error={errors?.endDate}
                            // touched={touched.endDate}
                            onChange={(values: any) =>
                              setFieldValue("endDate", values)
                            }
                            onBlur={handleBlur}
                            // min={moment().add(1, "days").format(DATE_FORMATS.DEFAULT)}
                          />
                          {/* <TextInput
                            label="Start Date*"
                            type="date"
                            name="startDate"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.startDate}
                            error={errors?.startDate}
                            touched={touched.startDate}
                            inputClassName="uppercase"
                            min={moment().format("YYYY-MM-DD")}
                            disabled={
                              editProgramModal.data.status === "active" ||
                              editProgramModal.data.status === "archived"
                            }
                          /> */}

                          {/* <TextInput
                            label="End Date"
                            type="date"
                            // placeholder="Program Name"
                            name="endDate"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.endDate}
                            error={errors?.endDate}
                            touched={touched.endDate}
                            min={moment().add(1, "days").format("YYYY-MM-DD")}
                            inputClassName="uppercase"
                          /> */}
                        </div>
                        {/* <div className="mb-2">
                          <InfoLabel message="The program will only become active once the start date has been reached." />
                        </div> */}

                        {isSuperAdmin() ? (
                          <div className="w-full my-6">
                            <MultiSelect
                              isDisable={editProgramModal?.data}
                              inputValue={searchString}
                              onInputChange={(value: string) => {
                                setSearchString(value);
                              }}
                              className={
                                "border-lineDark border rounded-md capitalize"
                              }
                              options={appName}
                              defaultValue={{ value: "", label: "" }}
                              isMulti={false}
                              placeholder="Select An App"
                              closeOnSelect={true}
                              value={
                                values?.appName?.value ? values.appName : null
                              }
                              onChange={(option: any) => {
                                setFieldValue("appName", option);
                              }}
                            />
                          </div>
                        ) : null}
                        <div className="w-full mb-4">
                          <TextInput
                            label="Description"
                            type="text"
                            rows={3}
                            fieldAs="textarea"
                            placeholder="Add your description here..."
                            name="description"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.description}
                            error={errors?.description}
                            touched={touched.description}
                            helperText="6000 Max Characters"
                          />
                        </div>
                        {isSkipperScienceSelected && (
                          <div>
                            <CustomCheckbox
                              name="isGlobalForSkipper"
                              checked={values.isGlobalForSkipper}
                              onChange={handleChange}
                              // indeterminate={isFormSectionAllChecked? false: isFormSectionSomeChecked}
                              label={
                                <p className="text-secondaryMid font-Overpass dark:text-textMain">
                                  This program should be available to all
                                  Skipper Science observers.
                                </p>
                              }
                              labelPlacement="end"
                            />
                          </div>
                        )}
                        <div>
                          <CustomCheckbox
                            name="requireQa"
                            checked={values.requireQa}
                            onChange={handleChange}
                            label={
                              <p className="text-secondaryMid font-Overpass dark:text-textMain">
                                Observations in this program do not need to go
                                through a QA process.
                              </p>
                            }
                            labelPlacement="end"
                          />
                        </div>
                        <div className="mb-4">
                          <CustomCheckbox
                            name="isPublic"
                            checked={values.isPublic}
                            onChange={handleChange}
                            label={
                              <p className="text-secondaryMid font-Overpass dark:text-textMain">
                                Program metadata publicly accessible.
                              </p>
                            }
                            labelPlacement="end"
                          />
                        </div>
                        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
                          <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                            <div className="flex flex-col items-start self-stretch justify-start flex-grow gap-1 rounded">
                              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pt-1">
                                <p className="flex-grow w-[596px] text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ">
                                  Search to add Organization that is leading the
                                  Program{" "}
                                </p>
                              </div>
                              <div
                                className={`flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5 px-3  rounded bg-white border ${
                                  touched.leadingOrganizationId &&
                                  errors.leadingOrganizationId
                                    ? "border-accent_1Dark"
                                    : "border-lineDark dark:border-lineLight"
                                }`}
                              >
                                <svg
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                                  preserveAspectRatio="xMidYMid meet"
                                >
                                  <path
                                    d="M21.7495 20.6895L16.0855 15.0255C17.4466 13.3914 18.1253 11.2956 17.9805 9.17389C17.8356 7.05219 16.8784 5.06801 15.3079 3.6341C13.7374 2.2002 11.6745 1.42697 9.54844 1.47528C7.42236 1.52359 5.39674 2.38971 3.89298 3.89347C2.38922 5.39723 1.5231 7.42284 1.47479 9.54893C1.42648 11.675 2.19971 13.7379 3.63361 15.3084C5.06752 16.8789 7.0517 17.8361 9.1734 17.981C11.2951 18.1258 13.391 17.4471 15.025 16.086L20.689 21.75L21.7495 20.6895ZM2.99948 9.74996C2.99948 8.41494 3.39536 7.1099 4.13706 5.99987C4.87876 4.88983 5.93296 4.02467 7.16636 3.51378C8.39976 3.00289 9.75696 2.86921 11.0663 3.12966C12.3757 3.39011 13.5784 4.03299 14.5224 4.97699C15.4665 5.921 16.1093 7.12373 16.3698 8.4331C16.6302 9.74248 16.4966 11.0997 15.9857 12.3331C15.4748 13.5665 14.6096 14.6207 13.4996 15.3624C12.3895 16.1041 11.0845 16.5 9.74948 16.5C7.95987 16.498 6.24414 15.7862 4.9787 14.5207C3.71326 13.2553 3.00146 11.5396 2.99948 9.74996Z"
                                    fill="#9B9FA5"
                                  />
                                </svg>
                                <div className="flex flex-col items-start justify-start flex-grow w-full ">
                                  <MultiSelect
                                    inputValue={""}
                                    onInputChange={() => {}}
                                    options={[]}
                                    defaultValue={selectedValues}
                                    isMulti={false}
                                    value={[selectedValues]}
                                    transformY="translateY(-123%)"
                                    onChange={() => {}}
                                    isDisable={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[596px] text-sm text-left text-secondaryMidLight dark:text-textMain">
                              Any other participating organizations are chosen
                              at a later stage.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end gap-2 pb-4 pr-4">
                    <Button
                      type="button"
                      text="Cancel"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="w-24 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={handleClose}
                    />
                    <Button
                      type="submit"
                      disabled={isSubmitting || !programs.canEditProgram}
                      text="Save"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="w-24 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="filled"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
};

export function getMidnightInLocalTimeZoneToUtc(
  date: string,
  time: string,
  timeZone: string
) {
  const dateTime = `${date} ${time}`;
  console.log("dateTime", dateTime);
  // Validate date, time, and timeZone
  if (!moment(dateTime, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
    return "";
  }
  if (!moment.tz.zone(timeZone)) {
    throw new Error("Invalid time zone.");
  }

  // Create a moment object for the given date, time, and timeZone
  const localMidnight = moment.tz(dateTime, timeZone);

  // Convert to UTC and format
  const utcMidnight = localMidnight.utc().format("YYYY-MM-DD HH:mm:ss");

  return utcMidnight;
}
