import React from "react";
// Components
import { SyestemSettings } from "view/pages";
import { SystemTheme } from "./Components";
import SystemSettings from "../SystemSettings";

interface IProps {
  setFieldValue: any;
  values: any;
  errors: any;
  editMode?: boolean;
}

export const Styling: React.FC<IProps> = ({
  setFieldValue,
  values,
  errors,
  editMode
}) => {

  return (
    <div className="w-full bg-bgWhite py-4 rounded-lg">
      <div className="px-12">
        {/* System themes */}
        <SystemTheme
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          editMode={editMode}
        />
        {/* Change background image of Login and forget screen */}
        <SystemSettings
          setFieldValue={setFieldValue}
          values={values}
          text={`For the best results on all devices, use an image that's at least 1920 x 1024 pixels and less 400KB`}
          editMode={editMode}
        />
      </div>
    </div>
  );
};
