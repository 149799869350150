// themeReducer.ts
import { SET_THEME_COLORS, SET_THEME_LOADED } from "./action-types";
import { initialThemeState, ThemeState } from "./initialState";

export const themeReducer = (state = initialThemeState, action: any) => {
    switch (action.type) {
        case SET_THEME_COLORS:
            return {
                ...state,
                colors: action.payload || {}, // Update the colors
            };
        case SET_THEME_LOADED:
            return {
                ...state,
                isThemeLoaded: true,
            };
        default:
            return state; // Return the current state for unknown actions
    }
};

