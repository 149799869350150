import { apiClient } from "../config";

async function getAllOrganizations(searchQuery?: any) {
  const params = {
    searchString: searchQuery?.searchString,
    entity: "organizations",
  };

  const res = await apiClient.get(`/admin/users/drop-down`, { params });
  return res.data;
}

export default getAllOrganizations;
