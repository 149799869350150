import { baseURL } from "config";
import apiLibrary from "services/api";
import axios from "axios";
import Cookies from "universal-cookie";
function registerImageOnMap(map: any, imageUrl: any, imageName: string) {
  return new Promise((resolve, reject) => {
    // Check if the image is already registered
    if (map.hasImage(imageName)) {
      return resolve("Image already registered");
    }

    // Load and register the image if it's not already registered
    map.loadImage(imageUrl, (error: any, image: any) => {
      if (error) {
        return reject(error.message);
      }

      // Add the image to the map's style
      map.addImage(imageName, image);

      return resolve("Image successfully registered");
    });
  });
}

export default registerImageOnMap;

export function replaceS3BaseWithCloudFront(url: string) {
  if (url.includes("X-Amz-Algorithm")) {
    return url;
  }
  const s3Base = "https://s3.ap-southeast-1.amazonaws.com/isn-landscape";

  const cloudFrontBase = "https://d2p6o7c9aqqr4r.cloudfront.net";

  return url.replace(s3Base, cloudFrontBase);
}

const cookies = new Cookies();

export function detectMimeType(arrayBuffer: any) {
  const bytes = new Uint8Array(arrayBuffer.slice(0, 4));
  const hex = bytes.reduce(
    (acc, byte) => acc + byte.toString(16).padStart(2, "0"),
    ""
  );

  switch (hex) {
    case "89504e47":
      return "image/png"; // PNG
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg"; // JPEG
    case "47494638":
      return "image/gif"; // GIF
    case "49492a00":
    case "4d4d002a":
      return "image/tiff"; // TIFF
    case "424d":
      return "image/bmp"; // BMP
    default:
      return "application/octet-stream"; // Unknown, default to binary type
  }
}

const getAuthToken = async () => {
  return (await cookies.get("token")) || "";
};

export async function fetchImageViaIdAndConvertToBlobAndReturnBlobUrl(
  fileId: number
) {
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    url: `${baseURL}/admin/file/${fileId}`,
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    responseType: "arraybuffer" as const,
  };

  try {
    const response = await axios(options);
    const mimeType = detectMimeType(response.data);
    if (mimeType !== "application/octet-stream") {
      const blob = new Blob([response.data], { type: mimeType });
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
    } else {
      console.error("Unsupported file type");
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching image:", error);
    return undefined;
  }
}
