// initialState.ts

// Define the shape of the theme state
export interface ThemeState {
  colors: any; // Initially empty
  isThemeLoaded: boolean;  // to track theme load
}

// Define and export the initial state
export const initialThemeState: ThemeState = {
  colors: {
  },    // No colors initially
  isThemeLoaded: false,
};
