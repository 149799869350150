import React from "react";
// Components
import Head from "view/components/Head";

export const PageHeading = ({ brandName }: { brandName: string }) => {
  return (
    <div>
      <Head title={`Brands ${brandName}`} />
      <div>
        <p className="break-all text-[28px] font-bold text-left text-textMid dark:text-textMain">
          {brandName}
        </p>
      </div>
    </div>
  );
};
