import useDateFormatter from "hooks/formatDateWithPattern";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Statuses } from "Components/Users/Statuses";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import usePermissions from "hooks/usePermissions";
import { capitalCase } from "change-case";
import { getBrandPlaceholder } from "utils/customBranding";
import useRoles from "hooks/roles";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const navigate = useNavigate();
  const { formatDate } = useDateFormatter();
  const { users } = usePermissions();
  const { programId } = useParams();
  const { isOrganizationAdmin } = useRoles();

  // function to add ellipsis
  const imagesData = [
    { image: getBrandPlaceholder("userProfile") },
    { image: getBrandPlaceholder("userProfile") },
    { image: getBrandPlaceholder("userProfile") },
    { image: getBrandPlaceholder("userProfile") },
    { image: getBrandPlaceholder("userProfile") },
  ];
  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "name"
  ) {
    return (
      <Link
        to={
          users.canViewUsersProfile &&
          ((programId && isOrganizationAdmin()) || !programId)
            ? `/profile/${cellValue?.personId}/person`
            : ""
        }
      >
        <div className="flex justify-start w-[250px] items-center relative group">
          <img
            src={
              cellValue?.imageUrl
                ? cellValue?.imageUrl
                : getBrandPlaceholder("userProfile")
            }
            alt=""
            height="100"
            width="100"
            className="object-cover mx-2 Img_user_Data"
            style={{ flexShrink: 0 }}
          />
          <div
            style={{ flexWrap: "wrap" }}
            className="flex flex-col flex-wrap w-full justify-start items-start relative gap-0.5"
          >
            <p className="text-sm text-left break-words break-all text-textMid group-hover:text-primaryDark">
              {ellipsize(cellValue?.firstName, 20)}{" "}
              {ellipsize(cellValue?.lastName, 20)}
            </p>
            <p className="text-sm text-left break-all text-textMid group-hover:text-primaryDark">
              {cellValue?.email}
            </p>
          </div>
        </div>
      </Link>
    );
  } else if (columnDef.id === "status" && cellValue !== null) {
    return (
      <div className="pl-2 status">
        <Statuses status={cellValue} />
      </div>
    );
  } else if (
    cellValue !== null &&
    (columnDef.id === "activeOn" ||
      columnDef.id === "memberSince" ||
      columnDef.id === "lastActive")
  ) {
    return (
      <div className="relative flex items-start flex-grow w-full px-2 py-1 ">
        <p className="text-sm text-left text-textMid dark:text-textMain w-[110px]">
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "usedApps") {
    return (
      <>
        <div className="flex -space-x-2 w-[200px] py-1 px-2">
          {cellValue?.map((el: any, index: number) => {
            const zIndex = 8 - index;
            return (
              <img
                className={`inline-block Img_user_Data rounded-full`}
                height="100"
                width="100"
                src={el?.image?.thumb}
                style={{ zIndex: zIndex }}
                alt=""
              />
            );
          })}
        </div>
      </>
    );
  } else if (cellValue !== null && columnDef.id === "role") {
    return (
      <div className="flex items-start w-[250px] justify-start  py-1">
        <p className="w-full pl-3 text-sm text-left break-words text-textMid">
          {cellValue.map((role: string) => capitalCase(role)).join(", ")}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "email") {
    return (
      <div className="flex items-start justify-start w-full py-1">
        <p className="pl-3 text-sm text-left text-textMid">{cellValue}</p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address"
            ? "justify-start w-[205px] px-0"
            : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid`}>
          {ellipsize(`${cellValue}`, 70)}
        </p>
      </div>
    );
  } else {
    return null; // Default behavior if none of the conditions are met
  }
};
