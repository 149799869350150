import IsnProfilePlaceholder from "assets/images/Placeholders/isn/isnProfilePlaceholder.png";
import SignProfilePlaceholder from "assets/images/Placeholders/custom-branding/signProfilePlaceholder.png";
import IsnUserProfilePlaceholder from "assets/images/Placeholders/isn/isnUserProfilePlaceholder.png";
import SignUserProfilePlaceholder from "assets/images/Placeholders/custom-branding/signUserProfilePlaceholder.svg";
import IsnCoverPlaceholder from "assets/images/Placeholders/isn/isnCoverPlaceholder.png";
import SignCoverPlaceholder from "assets/images/Placeholders/custom-branding/signCoverPlaceholder.png";
import IsnListProfilePlaceholder from "assets/images/Placeholders/isn/isnListProfilePlaceholder.png";
import SignListProfilePlaceholder from "assets/images/Placeholders/custom-branding/signListProfilePlaceholder.png";
import IsnModuleProfilePlaceholder from "assets/images/Placeholders/isn/isnModuleProfilePlaceholder.png";
import SignModuleProfilePlaceholder from "assets/images/Placeholders/custom-branding/signModuleProfilePlaceholder.png";
import IsnModuleCoverPlaceholder from "assets/images/Placeholders/isn/isnModuleCoverPlaceholder.png";
import SignModuleCoverPlaceholder from "assets/images/Placeholders/custom-branding/signModuleCoverPlaceholder.png";
import IsnModalCoverPlaceholder from "assets/images/Placeholders/isn/isnModalCoverPlaceholder.png";
import SignModalCoverPlaceholder from "assets/images/Placeholders/custom-branding/signModalCoverPlaceholder.png";
import IsnOrganizationProfilePlaceholder from "assets/images/Placeholders/isn/isnOrganizationProfilePlaceholder.png";
import SignOrganizationProfilePlaceholder from "assets/images/Placeholders/custom-branding/signOrganizationProfilePlaceholder.png";
import IsnMessageArchivePlaceholder from "assets/images/Placeholders/isn/isnMessageArchivePlaceholder.png";
import SignMessageArchivePlaceholder from "assets/images/Placeholders/custom-branding/signMessageArchivePlaceholder.svg";
import { baseURL } from "config";

export const brandNames: any = {
  isn_admin: "Indigenous Sentinels Network",
  sign_admin: "Seacoast Indigenous Guardians Network",
};

export const getCurrentBranding = () => {
  return process.env.REACT_APP_BRAND_NAME ?? "";
};

export const invitationAppNames = {
  ISN: { label: "ISN", value: "isn" },
  SIGN: { label: "SIGN", value: "sign" },
  SKIPPER_SCIENCE: { label: "SKIPPER SCIENCE", value: "skipper_science" },
};

export const programInvitationApps = [
  { value: "isn", label: "ISN" },
  { value: "sign", label: "SIGN" },
  { value: "skipper_science", label: "SKIPPER SCIENCE" },
];

export const invitationApps = [
  { value: "isn", label: "ISN" },
  { value: "sign", label: "SIGN" },
];

export const getAppName = (isSuperAdmin: boolean) => {
  return isSuperAdmin
    ? { value: "", label: "" }
    : {
        value: process.env.REACT_APP_BRAND_NAME,
        label: process.env.REACT_APP_BRAND_NAME,
      };
};

type BrandTypes =
  | "profile"
  | "userProfile"
  | "listingProfile"
  | "moduleProfile"
  | "organizationProfile"
  | "cover"
  | "modalCover"
  | "moduleCover"
  | "messageArchive";
type ProfileKeys = "isn_admin" | "sign_admin";

const brandPlaceholders: Record<BrandTypes, Record<ProfileKeys, string>> = {
  profile: {
    isn_admin: IsnProfilePlaceholder,
    sign_admin: SignProfilePlaceholder,
  },
  userProfile: {
    isn_admin: IsnUserProfilePlaceholder,
    sign_admin: SignUserProfilePlaceholder,
  },

  listingProfile: {
    isn_admin: IsnListProfilePlaceholder,
    sign_admin: SignListProfilePlaceholder,
  },
  moduleProfile: {
    isn_admin: IsnModuleProfilePlaceholder,
    sign_admin: SignModuleProfilePlaceholder,
  },

  organizationProfile: {
    isn_admin: IsnOrganizationProfilePlaceholder,
    sign_admin: SignOrganizationProfilePlaceholder,
  },

  cover: {
    isn_admin: IsnCoverPlaceholder,
    sign_admin: SignCoverPlaceholder,
  },
  moduleCover: {
    isn_admin: IsnModuleCoverPlaceholder,
    sign_admin: SignModuleCoverPlaceholder,
  },
  modalCover: {
    isn_admin: IsnModalCoverPlaceholder,
    sign_admin: SignModalCoverPlaceholder,
  },

  messageArchive: {
    isn_admin: IsnMessageArchivePlaceholder,
    sign_admin: SignMessageArchivePlaceholder,
  },
};

export const getBrandPlaceholder = (type: BrandTypes) => {
  const brandName = process.env.REACT_APP_BRAND_NAME as ProfileKeys;

  if (type in brandPlaceholders && brandName in brandPlaceholders[type]) {
    return brandPlaceholders[type][brandName];
  }

  return undefined;
};

export const getBrandName = (key: string, searchString: string) => {
  return {
    url: `${baseURL}/common/dropdown/${key}?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&search_string=${searchString}`,
  };
};
