import { MAXIMUM_FILE_SIZE } from 'constants/FileSize';
import { useEffect, useRef, useState } from 'react';
import apiLibrary from 'services/api';
import { Header } from './Components/Header';
import { Toasts } from 'view/components/Toasts';
import TextInput from '../MyProfile/Components/Inputs/TextInput';
import usePermissions from 'hooks/usePermissions';
import _ from 'lodash';
import { capitalCase } from 'change-case';
import useRoles from 'hooks/roles';
import { getCurrentBranding } from 'utils/customBranding';
import ISNLogo from 'assets/logo/ISNLogo';
import SIGNLogo from 'assets/logo/SIGNLogo';
import ISNSidebarLogo from 'assets/logo/ISNSidebarLogo';

interface ContactNumber {
  value: string;
  description: string;
  appName: null;
  key: string;
  fileId: null;
  filePath: null;
}

interface Email {
  value: string;
  description: string;
  appName: null;
  key: string;
  fileId: null;
  filePath: null;
}

interface BackgroundImage {
  value: null;
  title: string;
  description: string;
  key: string;
  appName: string;
  fileId: number | null;
  filePath: string | null;
}

interface GracePeriod {
  value: string;
  description: string;
  key: string;
  appName: null;
  fileId: null;
  filePath: null;
}

interface Limit {
  value: string;
  description: string;
  appName: null;
  fileId: null;
  filePath: null;
}

interface ReInvitePeriod {
  value: string;
  description: string;
  key: string;
  appName: null;
  fileId: null;
  filePath: null;
}

interface SettingsState {
  contactNumber: ContactNumber;
  email: Email;
  fishMap: BackgroundImage[];
  gracePeriod: GracePeriod;
  // isn: BackgroundImage[];
  isnAdmin: BackgroundImage[];
  sign: BackgroundImage[];
  limit: Limit;
  reInvitePeriod: ReInvitePeriod;
  skipperScience: BackgroundImage[];
}


export const brandLogos: any = {
  isn_admin: <ISNSidebarLogo width={16} height={16} />,
  sign_admin: <SIGNLogo width={28} height={28} />,
};
export const SyestemSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { universalSettings, universalSettingsImages } = usePermissions();
  const currentBrand = getCurrentBranding();
  const [settings, setSettings] = useState<SettingsState>({
    // isn: [
    //   {
    //     value: null,
    //     description: "",
    //     appName: "isn",
    //     key: "login_page_image",
    //     title: "Login Page",

    //     fileId: null,
    //     filePath: null,
    //   },
    //   {
    //     value: null,
    //     description: "",
    //     appName: "isn",
    //     key: "forget_password_page_image",
    //     title: "Forgot Password Page",
    //     fileId: null,
    //     filePath: null,
    //   },
    // ],
    isnAdmin: [
      {
        value: null,
        description: '',
        appName: 'isn_admin',
        key: 'login_page_image',
        title: 'Login Page ',

        fileId: null,
        filePath: null,
      },
      {
        value: null,
        description: '',
        appName: 'isn_admin',
        key: 'forget_password_page_image',
        title: 'Forgot Password Page ',
        fileId: null,
        filePath: null,
      },
    ],
    skipperScience: [
      {
        value: null,
        description: '',
        appName: 'skipper_science',
        key: 'login_page_image',
        title: 'Login Page',

        fileId: null,
        filePath: null,
      },
      {
        value: null,
        description: '',
        appName: 'skipper_science',
        key: 'forget_password_page_image',
        title: 'Forgot Password Page',
        fileId: null,
        filePath: null,
      },
    ],
    fishMap: [
      {
        value: null,
        description: '',
        appName: 'fish_map',
        key: 'login_page_image',
        title: 'Login Page',
        fileId: null,
        filePath: null,
      },
      {
        value: null,
        description: '',
        appName: 'fish_map',
        key: 'forget_password_page_image',
        title: 'Forgot Password Page',
        fileId: null,
        filePath: null,
      },
    ],
    sign: [
      {
        value: null,
        description: '',
        appName: 'sign',
        key: 'login_page_image',
        title: 'Forgot Password Page',
        fileId: null,
        filePath: null,
      },
      {
        value: null,
        description: '',
        appName: 'sign',
        key: 'forget_password_page_image',
        title: 'Forgot Password Page',
        fileId: null,
        filePath: null,
      },
    ],
    limit: {
      value: '',
      description: 'limit',
      appName: null,
      fileId: null,
      filePath: null,
    },
    contactNumber: {
      value: '',
      description: '',
      appName: null,
      key: 'contact_number',
      fileId: null,
      filePath: null,
    },
    email: {
      value: '',
      description: '',
      appName: null,
      key: 'email',
      fileId: null,
      filePath: null,
    },
    gracePeriod: {
      value: '',
      description: '',
      appName: null,
      key: 'grace_period',
      fileId: null,
      filePath: null,
    },

    reInvitePeriod: {
      value: '',
      description: '',
      appName: null,
      key: 're_invite_period',
      fileId: null,
      filePath: null,
    },
  });
  const getUniversalSettings = async () => {
    try {
      const { data } = await apiLibrary.stystemSettings.getUniversalSettings();

      setSettings((prevSettings) => ({
        ...prevSettings,
        ...data,
        // isn: updateAndCheckBackgroundImages(data.isn, "isn"),
        isnAdmin: updateAndCheckBackgroundImages(data.isnAdmin, 'isn_admin'),
        skipperScience: updateAndCheckBackgroundImages(
          data.skipperScience,
          'skipper_science'
        ),
        fishMap: updateAndCheckBackgroundImages(data.fishMap, 'fish_map'),
        sign: updateAndCheckBackgroundImages(data?.sign, 'sign'),
      }));
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);

      console.error('Error fetching universal settings:', error);
    }
  };

  useEffect(() => {
    getUniversalSettings();
  }, []);

  const updateUniversalSettings = async (
    setting: any,
    gracePeriodValue: any,
    reInvitePeriodValue: any
  ) => {
    if (!universalSettings.canEditUniversalSettings) {
      return;
    } else {
      setIsLoading(true);
      try {
        const { data, message } =
          await apiLibrary.stystemSettings.updateUniversalSettings(
            setting,
            gracePeriodValue,
            reInvitePeriodValue
          );
        setSettings(data);
        getUniversalSettings();
        Toasts.success(message);
      } catch (error: any) {
        const errorMsg = error.response.data.message ?? error.message;
        Toasts.error(errorMsg);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className='flex flex-col items-start justify-start w-full pt-4 pb-20'>
      <div className='flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3'>
        <Header />
        <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-10 px-6 pt-6 pb-10 rounded-lg bg-bgWhite dark:bg-secondaryLight'>
          {universalSettingsImages.canViewUniversalSettingsImages && (
            <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4'>
              <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4'>
                <div className='flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5'>
                  <p className='self-stretch flex-grow-0 flex-shrink-0 w-[1472px] text-base font-semibold text-left text-secondaryMid dark:text-textMain'>
                    Background images configuration
                  </p>
                  <p className='text-sm text-left text-textMidLight dark:text-textMain'>
                    Click the Preview 1:1 button to view your page in full-scale
                    size, as the small preview is only an approximation. For the
                    best results on all devices, use an image that’s at least
                    1920 x 1024 pixels.
                  </p>
                </div>

                {/* <div className="flex flex-wrap items-start justify-start w-full gap-4">
                  {settings?.isn?.map(
                    (setting: BackgroundImage, index: number) => {
                      return (
                        <ImagePreview
                          title={setting?.title}
                          description={setting?.description}
                          fileId={setting?.fileId}
                          appName={setting?.appName}
                          filePath={setting?.filePath}
                          imageKey={setting?.key}
                          value={setting?.value}
                          getUniversalSettings={getUniversalSettings}
                        />
                      );
                    }
                  )}
                </div> */}

                {currentBrand === 'isn_admin' ? (
                  <>
                    <div className='flex flex-wrap items-start justify-start w-full gap-4'>
                      {settings?.isnAdmin?.map(
                        (setting: BackgroundImage, index: number) => {
                          return (
                            <ImagePreview
                              title={setting?.title}
                              description={setting?.description}
                              fileId={setting?.fileId}
                              appName={setting?.appName}
                              filePath={setting?.filePath}
                              imageKey={setting?.key}
                              value={setting?.value}
                              getUniversalSettings={getUniversalSettings}
                            />
                          );
                        }
                      )}
                    </div>
                    <div className='flex flex-wrap items-start justify-start w-full gap-4'>
                      {settings?.fishMap?.map(
                        (setting: BackgroundImage, index: number) => {
                          return (
                            <ImagePreview
                              title={setting?.title}
                              description={setting?.description}
                              fileId={setting?.fileId}
                              appName={setting?.appName}
                              filePath={setting?.filePath}
                              imageKey={setting?.key}
                              value={setting?.value}
                              getUniversalSettings={getUniversalSettings}
                            />
                          );
                        }
                      )}
                    </div>
                    <div className='flex flex-wrap items-start justify-start w-full gap-4'>
                      {settings?.skipperScience?.map(
                        (setting: BackgroundImage, index: number) => {
                          return (
                            <ImagePreview
                              title={setting?.title}
                              description={setting?.description}
                              fileId={setting?.fileId}
                              appName={setting?.appName}
                              filePath={setting?.filePath}
                              imageKey={setting?.key}
                              value={setting?.value}
                              getUniversalSettings={getUniversalSettings}
                            />
                          );
                        }
                      )}
                    </div>
                  </>
                ) : null}

                <div className='flex flex-wrap items-start justify-start w-full gap-4'>
                  {settings?.sign?.map(
                    (setting: BackgroundImage, index: number) => {
                      return (
                        <ImagePreview
                          title={setting?.title}
                          description={setting?.description}
                          fileId={setting?.fileId}
                          appName={setting?.appName}
                          filePath={setting?.filePath}
                          imageKey={setting?.key}
                          value={setting?.value}
                          getUniversalSettings={getUniversalSettings}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          )}
          {universalSettings.canViewUniversalSettings && (
            <>
              <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4'>
                <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4'>
                  <div className='flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5'>
                    <p className='text-base font-semibold text-left text-secondaryMid dark:text-textMain'>
                      Grace period
                    </p>
                    <p className='text-sm text-left text-textMidLight dark:text-textMain'>
                      Set the amount of time before an admin user is
                      automatically logged out.
                    </p>
                  </div>
                  <div className='flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[280px]'>
                    <TextInput
                      startAdornment={
                        <div className='mr-3'>
                          <svg
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            preserveAspectRatio='none'
                          >
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 5C10.5523 5 11 5.44772 11 6V9.58579L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L9.29289 10.7071C9.10536 10.5196 9 10.2652 9 10V6C9 5.44772 9.44771 5 10 5Z'
                              className='fill-[#2C3236] dark:fill-[#ffffff]'
                            />
                          </svg>
                        </div>
                      }
                      disabled={
                        isLoading || !universalSettings.canEditUniversalSettings
                      }
                      value={settings?.gracePeriod.value}
                      fieldAs='select'
                      label=' '
                      type='select'
                      name=''
                      data={[
                        '12 hours',
                        '24 hours',
                        '2 days',
                        '5 days',
                        '1 week',
                        '2 weeks',
                        '1 month',
                        '2 months',
                      ]}
                      handleChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        updateUniversalSettings(
                          settings?.gracePeriod.key,
                          event.target.value,
                          settings?.reInvitePeriod.value
                        );
                        // const lowerCase = event.target.value.toLocaleLowerCase();
                        // setFieldValue("occurrence.repeatUnit", lowerCase);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4'>
                <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4'>
                  <div className='flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5'>
                    <p className='text-base font-semibold text-left text-secondaryMid dark:text-textMain'>
                      Re-Invite period
                    </p>
                    <p className='text-sm text-left text-textMidLight dark:text-textMain'>
                      Set the automatic period of time before a user will
                      receive a reinvitation to join the system.
                    </p>
                  </div>
                  <div className='flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[280px]'>
                    <TextInput
                      startAdornment={
                        <div className='mr-3'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='relative flex-grow-0 flex-shrink-0 w-6 h-6'
                            preserveAspectRatio='xMidYMid meet'
                          >
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3V4H19C20.6569 4 22 5.34315 22 7V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V7C2 5.34315 3.34315 4 5 4H7V3C7 2.44772 7.44772 2 8 2ZM7 6H5C4.44772 6 4 6.44772 4 7V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V7C20 6.44772 19.5523 6 19 6H17V7C17 7.55228 16.5523 8 16 8C15.4477 8 15 7.55228 15 7V6H9V7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7V6ZM6 11C6 10.4477 6.44772 10 7 10H17C17.5523 10 18 10.4477 18 11C18 11.5523 17.5523 12 17 12H7C6.44772 12 6 11.5523 6 11Z'
                              className='fill-[#2C3236] dark:fill-[#ffffff]'
                            />
                          </svg>
                        </div>
                      }
                      disabled={
                        isLoading || !universalSettings.canEditUniversalSettings
                      }
                      value={settings?.reInvitePeriod.value}
                      fieldAs='select'
                      label=' '
                      type='select'
                      name=''
                      data={[
                        '7 days',
                        '2 weeks',
                        '1 month',
                        '2 months',
                        '3 months',
                        '4 months',
                        '6 months',
                        '1 year',
                      ]}
                      handleChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        updateUniversalSettings(
                          settings?.reInvitePeriod.key,
                          settings?.gracePeriod.value,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SyestemSettings;

interface ImageData {
  title: string;
  value: string | null;
  description: string;
  imageKey: string;
  fileId: number | null;
  filePath: string | null;
  appName: string;
  getUniversalSettings: () => void;
}

const ImagePreview: React.FC<ImageData> = ({
  title,
  value,
  description,
  imageKey,
  fileId,
  filePath,
  appName,
  getUniversalSettings,
}) => {
  const { universalSettings, universalSettingsImages } = usePermissions();
  const [isLoading, setIsLoading] = useState(false);

  const currentBrand = getCurrentBranding();
  const CURRENT_BRAND_LOGO = brandLogos[currentBrand]

  const fileInputRef = useRef<HTMLInputElement>(null);

  /**
   * Handles the upload of a user's profile image.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The event triggered by selecting a file for upload.
   */
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

        if (!validImageTypes.includes(file.type)) {
          return Toasts.error(
            'Please select a valid image file (jpeg/jpg/png)'
          );
        }
        const fileSize = file.size / 1024 / 1024; // Calculate the size of the uploaded file in megabytes.
        if (fileSize > MAXIMUM_FILE_SIZE) {
          return Toasts.error('File size must be less than 10 mb!');
        }

        setIsLoading(true);

        const uploadResponse = await apiLibrary.file.fileUpload(file);

        const { data, message } =
          await apiLibrary.stystemSettings.updateUniversalImages(
            imageKey,
            appName,
            uploadResponse.data.id
          );
        Toasts.success(message);

        getUniversalSettings();
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errorMsg);
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * Handles the click event to trigger the file input element.
   */
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className='flex flex-col justify-start items-start flex-grow w-[25%] mx-1  pb-1'>
      <div className='flex justify-start items-center self-stretch  flex-shrink-0 gap-4 pt-[9px] pb-[7px]'>
        <div className='relative flex items-center justify-between flex-grow'>
          <div className='flex items-center justify-start flex-shrink-0 gap-4'>
            <div className='flex items-center justify-center flex-shrink-0 rounded-3xl'>
              <div className='flex justify-center items-center  flex-shrink-0 relative pt-1.5 pb-2'>
                <p className='flex-shrink-0 text-sm font-semibold text-center text-secondaryMid dark:text-textMain'>
                  {`${title} (${appName === 'isn_admin' ? 'ISN' : capitalCase(appName ?? '')})`}
                </p>
              </div>
            </div>
          </div>
          <div className='flex items-center justify-start flex-shrink-0 gap-4'>
            <div className='flex items-center justify-center flex-shrink-0 rounded-3xl'>
              <div className='flex justify-center items-center  flex-shrink-0 relative pt-1.5 pb-2'>
                <p className='flex-shrink-0 text-sm font-semibold text-center text-secondaryMid dark:text-textMain'>
                  Preview 1:1
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='flex justify-start items-start self-stretch  flex-shrink-0 h-[244px] relative  rounded-lg'
        style={{
          backgroundImage: `url('${filePath ?? ''}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className='flex flex-col justify-start items-center self-stretch  flex-shrink-0 w-[116px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 gap-2 px-2 py-4 bg-bgWhite bg-opacity-90 dark:bg-secondaryLight dark:bg-opacity-90 border border-[#dcdee0] h-full'>
          {CURRENT_BRAND_LOGO}
          <div className='relative self-stretch flex-shrink-0 h-2 '>
            <div className='w-[100px] h-2 absolute left-[-1px] top-[-1px] bg-[#dcdee0]' />
          </div>
          <div className='relative self-stretch flex-shrink-0 h-2 '>
            <div className='w-[100px] h-2 absolute left-[-1px] top-[-1px] bg-[#dcdee0]' />
          </div>
          <div className='relative self-stretch flex-shrink-0 h-2 '>
            <div className='w-[100px] h-2 absolute left-[-1px] top-[-1px] rounded-lg bg-primary' />
          </div>
        </div>
        <div className='relative self-stretch flex-grow'>
          <input
            disabled={isLoading}
            type='file'
            className='absolute top-0 left-0 hidden opacity-0 cursor-pointer'
            multiple={false}
            ref={fileInputRef}
            accept='image/jpeg, image/jpg, image/png'
            onChange={handleImageUpload}
          />
          {isLoading ? (
            <div className='absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-500 bg-opacity-50'>
              <div className='inline-flex items-center'>
                <svg
                  className='w-12 h-12 mr-2 animate-spin text-secondaryMidLight dark:text-primaryExtraLight'
                  xmlns='https://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              </div>
            </div>
          ) : (
            <>
              {universalSettingsImages.canEditUniversalSettingsImages && (
                <button
                  disabled={isLoading}
                  onClick={handleClick}
                  type='button'
                  className='flex justify-start items-start absolute right-[2px] top-0 gap-2 p-0.5 rounded-3xl bg-white'
                >
                  <svg
                    width={32}
                    height={32}
                    viewBox='0 0 32 32'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='relative flex-shrink-0 w-8 h-8 '
                    preserveAspectRatio='xMidYMid meet'
                  >
                    <rect width={32} height={32} rx={16} fill='white' />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M14.0704 9C13.736 9 13.4238 9.1671 13.2383 9.4453L12.4258 10.6641C11.8694 11.4987 10.9327 12 9.92963 12H9C8.44772 12 8 12.4477 8 13V22C8 22.5523 8.44772 23 9 23H23C23.5523 23 24 22.5523 24 22V13C24 12.4477 23.5523 12 23 12H22.0704C21.0673 12 20.1306 11.4987 19.5742 10.6641L18.7617 9.4453C18.5762 9.1671 18.264 9 17.9296 9H14.0704ZM11.5742 8.3359C12.1306 7.5013 13.0673 7 14.0704 7H17.9296C18.9327 7 19.8694 7.5013 20.4258 8.3359L21.2383 9.5547C21.4238 9.8329 21.736 10 22.0704 10H23C24.6569 10 26 11.3431 26 13V22C26 23.6569 24.6569 25 23 25H9C7.34315 25 6 23.6569 6 22V13C6 11.3431 7.34315 10 9 10H9.92963C10.264 10 10.5762 9.8329 10.7617 9.5547L11.5742 8.3359ZM16 15C14.8954 15 14 15.8954 14 17C14 18.1046 14.8954 19 16 19C17.1046 19 18 18.1046 18 17C18 15.8954 17.1046 15 16 15ZM12 17C12 14.7909 13.7909 13 16 13C18.2091 13 20 14.7909 20 17C20 19.2091 18.2091 21 16 21C13.7909 21 12 19.2091 12 17Z'
                      fill='#2C3236'
                    />
                  </svg>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function updateAndCheckBackgroundImages(
  settings: BackgroundImage[],
  appName: string
): BackgroundImage[] {
  if (!Array.isArray(settings)) {
    return [
      {
        value: null,
        description: '',
        appName,
        key: 'login_page_image',
        fileId: null,
        filePath: null,
        title: 'Login Page',
      },
      {
        value: null,
        description: '',
        appName,
        key: 'forget_password_page_image',
        fileId: null,
        filePath: null,
        title: 'Forgot Password Page',
      },
    ];
  }

  const defaults = {
    login_page_image: {
      value: null,
      description: '',
      appName,
      key: 'login_page_image',
      fileId: null,
      filePath: null,
      title: 'Login Page',
    },
    forget_password_page_image: {
      value: null,
      description: '',
      appName,
      key: 'forget_password_page_image',
      fileId: null,
      filePath: null,
      title: 'Forgot Password Page',
    },
  };

  const updatedSettings = _(settings)
    .reject({ key: 'verification_page_image' })
    .map((setting) => ({
      ...setting,
      title:
        setting.key === 'login_page_image'
          ? 'Login Page'
          : setting.key === 'forget_password_page_image'
            ? 'Forgot Password Page'
            : '',
    }))
    .value();

  _.forEach(defaults, (value, key) => {
    if (!_.find(updatedSettings, { key })) {
      updatedSettings.push(value);
    }
  });

  return _.sortBy(updatedSettings, [
    (setting) => setting.key !== 'login_page_image',
    (setting) => setting.key !== 'forget_password_page_image',
  ]);
}

/* <ImagePreview
  title="Verification Code Page"
  description={settings?.verificationPageImage?.description}
  fileId={settings?.verificationPageImage?.fileId}
  filePath={settings?.verificationPageImage?.filePath}
  imageKey={settings?.verificationPageImage?.key}
  value={settings?.verificationPageImage?.value}
  getUniversalSettings={getUniversalSettings}
/> */
