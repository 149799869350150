import { useLocation } from "react-router-dom";
import { PageHeadingProps } from "../../types";
import Head from "view/components/Head";

const PageHeading = ({ communityId, name, parentName, kpiState }: PageHeadingProps) => {
  function processTitle(kpiState: any = null) {
    if (communityId) {

      if (kpiState && kpiState.isAdminRole) {
        return `${name}: Admins`
      } else {
        return `${name}: Members`
      }

    } else {
      return "People"
    }
  }
  return (
    <div className="flex flex-col justify-start items-start py-0.5">
      <Head title={`${parentName} ${processTitle(kpiState)}`} />
      <div className="relative flex items-center justify-start">
        <p className="w-full break-all text-[28px] font-bold text-left text-textMid dark:text-textMain">
          {processTitle(kpiState)}
        </p>
      </div>
    </div>
  );
};

export { PageHeading };
