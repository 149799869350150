import { sentenceCase } from "change-case";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProfileRoles } from "store/profileRoles/initialState";

const ProfileRolesListing = () => {
  const { data } = useSelector<RootState, IProfileRoles>(
    (state) => state?.profileRoles
  );

  const allRoles = [
    ...data.communityRoles,
    ...data.organizationRoles,
    ...data.programsRoles,
  ];
  return (
    <div className="flex flex-col justify-start items-start w-full gap-2 xl:px-12 px-8">
      {allRoles?.map((role: any, index: number) => {
        const name =
          "community" in role
            ? role.community
            : "organization" in role
              ? role.organization
              : role.program;
        const roleType =
          "community" in role
            ? "Community"
            : "organization" in role
              ? "Organization"
              : "Program";

        return (
          <div key={index} className="flex justify-start items-start w-full">
            <div className="flex flex-col justify-start w-full items-start flex-grow ">
              <div className="flex justify-start items-start w-full gap-4">
                <div className="flex justify-start items-center flex-grow rounded-lg w-1/2">
                  <div className="flex justify-start items-center flex-grow">
                    <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 py-2">
                      <p className="w-full text-sm break-all text-left capitalize text-secondaryMidLight dark:text-caption ">
                        {roleType}
                      </p>
                      <div className="flex justify-start items-center w-full relative">
                        <p className="w-full text-[15px] break-all text-left text-secondary dark:text-inputText">
                          {name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-start items-center flex-grow rounded-lg w-1/2">
                  <div className="flex justify-start items-center w-full flex-grow">
                    <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 py-2">
                      <p className="text-sm text-left w-full capitalize text-secondaryMidLight dark:text-caption ">
                        Role
                      </p>
                      <div className="flex justify-start items-center w-full relative">
                        <p className="w-full break-all text-[15px] text-left text-secondary dark:text-inputText capitalize">
                          {sentenceCase(role.role)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProfileRolesListing;
