import apiClient from "services/apiClient";

async function getFileViaId(fileId: number): Promise<ArrayBuffer> {
  console.log("Requesting file with ID:", fileId);
  try {
    const response = await apiClient.get(`/admin/file/${fileId}`, {
      responseType: "arraybuffer",
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export default getFileViaId;
