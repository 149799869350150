import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import App from "./App";
import "./index.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import "@reach/combobox/styles.css";
import initSentry from "utils/sentrySetup";
import { currentEnvironment } from "config";
import initHotJar from "utils/hotJarSetup";
import ThemeProvider from "utils/Branding/ThemeProvider";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (currentEnvironment !== "localhost") {
  initSentry();



}

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            closeOnClick={false}
            draggable={false}
            pauseOnHover={true}
            closeButton={false}
            pauseOnFocusLoss={false}
          />
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
