import { Dispatch } from "redux";
import {
  ERROR_LOADING_BRANDS,
  GET_ALL_BRANDS,
  RESET_DATA,
  START_LOADING_BRANDS,
  STOP_LOADING_BRANDS,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";

export const fetchBrandsAction =
  () => async (dispatch: Dispatch, getState: any) => {
    dispatch({ type: START_LOADING_BRANDS });
    try {
      const currentState = getState();
      const queryString = generateQueryStringForUsers(
        currentState.Filters.brandsFilter
      );

      const { data } = await apiLibrary.Brands.getAllBrands(queryString);
      dispatch({
        type: GET_ALL_BRANDS,
        payload: data,
      });
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({
        type: RESET_DATA,
      });
      console.log("An error occurred while fetching the user profile:", error);
      dispatch({ type: ERROR_LOADING_BRANDS });
      // dispatch(fetchError(error))
    } finally {
      dispatch({ type: STOP_LOADING_BRANDS });
      // dispatch(loadingState(false))
    }
  };
