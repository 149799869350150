// import * as turf from "@turf/turf";

// /**
//  * Calculate the appropriate zoom level based on the area of a GeoJSON shape.
//  *
//  * @param {Object} geoJsonFeature - The GeoJSON feature.
//  * @param {Object} map - The Mapbox map instance.
//  * @returns {number} - The calculated zoom level.
//  */
// function calculateZoomLevel(geoJsonFeature: any, map: any) {
//   // Calculate the area of the feature in square meters
//   const area = turf.area(geoJsonFeature);

//   // Define a heuristic to determine the zoom level based on the area
//   let zoomLevel;
//   if (area > 1000000) {
//     // Greater than 1 km²
//     zoomLevel = 10;
//   } else if (area > 100000) {
//     // Greater than 0.1 km²
//     zoomLevel = 12;
//   } else if (area > 10000) {
//     // Greater than 10000 m²
//     zoomLevel = 14;
//   } else {
//     zoomLevel = 15;
//   }

//   // Optionally, adjust the map view
//   const bbox = turf.bbox(geoJsonFeature);
//   map.fitBounds(
//     [
//       [bbox[0], bbox[1]],
//       [bbox[2], bbox[3]],
//     ],
//     {
//       maxZoom: zoomLevel,
//     }
//   );

//   return zoomLevel;
// }

import * as turf from "@turf/turf";

/**
 * Calculate an appropriate zoom level for different types of GeoJSON geometries.
 *
 * @param {Object} geoJson - The GeoJSON object.
 * @param {Object} map - The Mapbox map instance.
 * @returns {number} - The calculated zoom level.
 */
function calculateZoomLevel(layer: any, map: any) {
  const geoJson = layer ? layer.item?.mapData : null;

  if (!geoJson) {
    return;
  }
  // Calculate the bounding box for the entire GeoJSON
  const bbox = turf.bbox(geoJson);

  // Convert bbox to a Polygon to calculate its area
  const bboxPolygon = turf.bboxPolygon(bbox);
  const area = turf.area(bboxPolygon);
  // Define a heuristic to determine the zoom level
  let zoomLevel;
  if (area > 1000000) {
    // Greater than 1 km²
    zoomLevel = 10;
  } else if (area > 100000) {
    // Greater than 0.1 km²
    zoomLevel = 12;
  } else if (area > 10000) {
    // Greater than 10000 m²
    zoomLevel = 14;
  } else {
    zoomLevel = 15;
  }

  // For points and lines, you might want to set a default or calculated zoom level
  if (geoJson.type === "FeatureCollection") {
    const types = geoJson.features.map((feature: any) => feature.geometry.type);
    if (types.includes("Point") || types.includes("LineString")) {
      zoomLevel = 14;
    }
  }

  // Adjust the map view to fit the bounding box

  const bounds = [
    [bbox[0], bbox[1]],
    [bbox[2], bbox[3]],
  ];
  const maxZoom = zoomLevel;

  return {
    bounds,
    maxZoom,
  };
}

export { calculateZoomLevel };
