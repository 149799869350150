import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Box } from "@mui/material";
import DashboardCardHeading from "../DashboardCardHeading";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { filtersInitialState } from "store/filters/initialState";
import UserNoData from "../imgs/UserNoData";
import "./style.scss";
import { usersStatusAction } from "store/filters";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import usePermissions from "hooks/usePermissions";
const rows = [
  {
    avatar:
      "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg",
    stock: "name",
    price: 26224,
    observations: 30567,
  },
  {
    avatar:
      "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg",
    stock: "name",
    price: 26224,
    observations: 30567,
  },
  {
    avatar:
      "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg",
    stock: "name",
    price: 26224,
    observations: 30567,
  },
  {
    avatar:
      "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg",
    stock: "name",
    price: 26224,
    observations: 30567,
  },
  {
    avatar:
      "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg",
    stock: "name",
    price: 26224,
    observations: 30567,
  },
];

export default function ActiveUsers() {
  const {
    dashboard: { activeUsers },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  // Access the theme colors from the Redux store
  const { primary } = useSelector((state: any) => state.theme.colors);

  React.useEffect(() => {
    dispatch(dashboardActions.fetchActiveUsers());
  }, [dashboardFilter]);

  const handleNavigate = () => {
    dispatch(usersStatusAction("active"));
    navigate("/users/list");
  };
  return (
    <>
      <Box height={"100%"}>
        <DashboardCardHeading text="Active Users" widgetId="active_users" handleClick={handleNavigate} />

        {activeUsers.stateIs == "Idle" && activeUsers.data?.length === 0 ? (
          <div className="flex items-center justify-center w-full h-full">
            <UserNoData />
          </div>
        ) : activeUsers.stateIs === "Pending" ? (
          <div className="flex items-center justify-center w-full h-full loader">
            <TailSpin
              height="50"
              width="50"
              color={primary}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        ) : (
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-textMid">
              <thead
                style={{
                  background: "none",
                }}
                className="bg-none "
              >
                <tr
                  style={{
                    border: "none",
                    background: "trannsparent",
                  }}
                >
                  <th
                    style={{
                      background: "none",
                    }}
                    scope="col dark:text-textMain"
                    className="px-3 py-3 text-textMid dark:text-textMain"
                  >
                    Avatar
                  </th>
                  <th
                    style={{
                      background: "none",
                    }}
                    scope="col dark:text-textMain"
                    className="px-3 py-3 text-textMid dark:text-textMain"
                  >
                    Name
                  </th>
                  <th
                    style={{
                      background: "none",
                    }}
                    scope="col dark:text-textMain"
                    className="px-3 py-3 text-textMid dark:text-textMain"
                  >
                    Last Name
                  </th>
                  <th
                    style={{
                      background: "none",
                    }}
                    scope="col dark:text-textMain"
                    className="px-3 py-3 text-textMid dark:text-textMain"
                  >
                    Observations
                  </th>
                </tr>
              </thead>
              <tbody>
                {activeUsers.data.map((row, index) => (
                  <tr
                    style={{
                      border: "none",
                      background: "trannsparent",
                    }}
                    key={index}
                  >
                    <td className=" flex justify-center px-3 py-2">
                      <Avatar
                        alt={row.firstName}
                        src={row.personImage?.original}
                        sx={{
                          width: "36px",
                          height: "36px",
                          boxShadow: "1px 1px 14px 0px rgba(51, 51, 51, 0.18)",
                        }}
                      />
                    </td>

                    <td className="dark:text-caption" align="left">
                      {row.firstName}
                    </td>
                    <td className="dark:text-caption" align="left">
                      {row.lastName}
                    </td>
                    <td className="dark:text-caption" align="center">
                      {row.observationCount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Box>
    </>
  );
}
