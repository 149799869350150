import { BrandForm } from "Components/BrandProfile/BrandForm";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchSingleBrandAction } from "store/brandDetail";
import { resetBrandDetails } from "store/brandDetail/reducer.actions";

export const AddEditBrand = () => {
  const { brandId } = useParams();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  useEffect(() => {
    if (brandId) {
      dispatch(fetchSingleBrandAction(brandId))
    }

    return () => {
      dispatch(resetBrandDetails())
    }
  }, [brandId])

  return <BrandForm />;
};
