import { useDispatch } from "react-redux";
import { LayerBox } from "../index";
import {
  displayPointLayerOnMap,
  doFetchAllLayers,
  openAddPointsLayerScreen,
  openEditPointLayerScreen,
  openViewPointLayerScreen,
  updateDrawShapeCoordinates,
  updateSelectedItemToZoom,
  updateSelectedLayersItems,
} from "store/geography";
import { useSelector } from "react-redux";
import { RootState } from "store";

import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Toasts } from "view/components/Toasts";
import { CurrentOpenToggleType, T_PointLayerData } from "../LayersListing";
import usePermissions from "hooks/usePermissions";
import axios from "axios";

interface I_PointLayerBox {
  data: T_PointLayerData[] | undefined;
  actionsList: {
    label: string;
  }[];
  global?: boolean;
  setEntityType?: Dispatch<SetStateAction<string | null>>;
  setPage?: Dispatch<SetStateAction<any>>;
  entityType?: null | string;
  page?: any;
  totalPages?: number;
  totalItems?: number;
  loading: boolean;
  currentOpenToggle: CurrentOpenToggleType;
  // setCurrentOpenToggle: Dispatch<SetStateAction<CurrentOpenToggleType>>;
}

const PointLayerBox = ({
  data,
  actionsList,
  setEntityType,
  setPage,
  entityType,
  totalPages,
  page,
  totalItems,
  loading,
  currentOpenToggle,
  // setCurrentOpenToggle
}: I_PointLayerBox) => {
  const [isScrollEndEventTriggered, setIsScrollEndEventTriggered] =
    useState(false);
  const [layers, setLayers] = useState<T_PointLayerData[] | undefined>([]);
  const [doShowDataList, setDoShowDataList] = useState(false);
  const [inLoadingLayer, setInLoadingLayer] = useState<null | any>(null);

  const { geographies, communities } = usePermissions();
  const dispatch = useDispatch();
  const { selectedLayersItems } = useSelector(
    (state: RootState) => state.geography
  );
  const { communityId } = useParams();

  const heading = `${!totalItems || totalItems === 0 ? "" : totalItems} Points`;

  useEffect(() => {
    setDoShowDataList(currentOpenToggle === "point");
  }, [currentOpenToggle]);

  useEffect(() => {
    setLayers(data);
  }, [data]);

  useEffect(() => {
    if (isScrollEndEventTriggered) {
      if (
        setPage &&
        setEntityType &&
        totalPages &&
        page &&
        totalPages >= page.point + 1
      ) {
        setPage((prev: any) => {
          return { ...prev, point: prev.point + 1 };
        });

        if (entityType !== "point") {
          setEntityType("point");
        }
      }

      setIsScrollEndEventTriggered(false);
    }
  }, [isScrollEndEventTriggered]);

  // handlers
  const handleClickOnMenuAction = async (action: any, dataItem: any) => {
    const currentAction = action.label.toLowerCase();
    const selectedItem = selectedLayersItems?.find(
      (itm: any) => itm?.item?.id === dataItem?.id
    );
    switch (currentAction) {
      case "edit":
        // open edit screen of raster layer
        dispatch(openEditPointLayerScreen(dataItem));
        break;
      case "view information":
        // open detail screen of raster layer
        dispatch(openViewPointLayerScreen(dataItem));
        break;

      case "zoom to selection":
        // update selected item to be dominant on map
        dispatch(updateSelectedItemToZoom({ ...(selectedItem ?? {}) }));
        break;

      case "archive":
      case "restore":
        if (communityId) {
          const isArchive = currentAction === "archive";

          try {
            // Update layer status
            await apiLibrary.geography.updateLayerStatus(
              communityId,
              dataItem.id,
              isArchive
            );
            dispatch(doFetchAllLayers(true));

            // Update selected layers items
            const layersItems = selectedLayersItems || [];
            const itemIndex = layersItems.findIndex(
              (itm: any) => itm.item.id === dataItem.id
            );

            if (itemIndex !== -1) {
              layersItems[itemIndex].status = false;
              dispatch(updateSelectedLayersItems([...layersItems]));
            }
          } catch (error: any) {
            console.error("Failed to update layer status:", error);
            Toasts.error(error.response.data.message ?? error.message);
            // Optionally, handle the error more specifically, such as informing the user
          }
        }

        break;
    }
  };
  const handleClickOnAddButton = () => {
    dispatch(openAddPointsLayerScreen());
    dispatch(displayPointLayerOnMap({}));
    dispatch(updateDrawShapeCoordinates(null));
  };
  const handleClickOnCheckBox = async (e: any, item: any) => {
    const isChecked = e.target.checked;
    const layersItems = selectedLayersItems ?? [];
    const itemIndex = layersItems.findIndex(
      (itm: any) => itm.item.id === item.id
    );
    const alreadyExistedItem = layersItems[itemIndex];
    let upadatedItems;

    if (isChecked) {
      if (alreadyExistedItem) {
        // update item status to true
        alreadyExistedItem.status = true;

        layersItems[itemIndex] = alreadyExistedItem;
        upadatedItems = layersItems;
      } else {
        // if(communityId){
        //   await apiLibrary.geography
        //     .getSingleLayer(communityId, item.id)
        //     .then((data) => {
        //       const newItem = {
        //         item: data.data,
        //         itemType:"point",
        //         status: true,
        //       };
        //       upadatedItems = [...layersItems, newItem];

        //       setLayers((prev:any)=>{

        //         const itemIndex = prev.findIndex((itm:any)=>itm?.id === item?.id);
        //         if(itemIndex >= 0){
        //           prev[itemIndex] = newItem.item;
        //         };

        //         return prev;
        //       });
        //     })
        //     .catch((error) => {
        //       Toasts.error(error.message)
        //     });
        // }
        // // add item
        // upadatedItems = [
        //   ...layersItems,
        //   { item, itemType: "point", status: true },
        // ];

        if (communityId && !global) {
          await fetchLayerData(communityId, item, setInLoadingLayer);
        } else {
          await fetchLayerData(null, item, setInLoadingLayer, true);
        }
      }
    } else {
      // update item status to false on unchecking the checkbox
      alreadyExistedItem.status = false;

      layersItems[itemIndex] = alreadyExistedItem;
      upadatedItems = layersItems;
    }

    dispatch(updateSelectedLayersItems([...upadatedItems]));
  };
  const handleOnScrollEnd = () => {
    setIsScrollEndEventTriggered(true);
  };
  const handleClickOnTogglerBtn = () => {
    setDoShowDataList(!doShowDataList);
    // if (currentOpenToggle && currentOpenToggle !== "point") {
    //   setCurrentOpenToggle("point");
    // };
  };

  return (
    <LayerBox
      heading={heading}
      dataList={communities.canViewGeographyCommunities ? layers : null}
      actionList={actionsList}
      hideItemThumb={true}
      blockMarginB={true}
      loading={loading}
      hideAddBtn={!geographies.canViewVectorLayers}
      doShowDataList={doShowDataList}
      layerType="point"
      handleClickOnMenuAction={handleClickOnMenuAction}
      handleClickOnAddButton={handleClickOnAddButton}
      handleClickOnCheckBox={handleClickOnCheckBox}
      handleOnScrollEnd={handleOnScrollEnd}
      handleClickOnTogglerBtn={handleClickOnTogglerBtn}
    />
  );
};

export { PointLayerBox };

async function fetchLayerData(
  communityId: any,
  item: any,
  setInLoadingLayer: any,
  global: boolean = false
) {
  try {
    setInLoadingLayer({ id: item.id, status: true });

    const apiCall = global
      ? apiLibrary.geography.getSingleGlobalLayer(item.id)
      : apiLibrary.geography.getSingleLayer(communityId, item.id);

    const data = await apiCall;

    let mapData = null;

    if (!data.data.mapData && item.type === "point") {
      mapData = await fetchMapData(data.data.geoFilePath);
    }

    const newItem: any = {
      item: data?.data,
      itemType: item.type,
      status: true,
    };

    // Attach mapData if it was fetched
    if (mapData) {
      newItem.item.mapData = mapData;
    }

    return newItem;
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    Toasts.error(error.message);
  } finally {
    setInLoadingLayer({ id: item.id, status: false });
  }
}

async function fetchMapData(url: string) {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch map data:", error);
    return null;
  }
}
