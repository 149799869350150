import registerImageOnMap, {
  fetchImageViaIdAndConvertToBlobAndReturnBlobUrl,
} from "utils/registerImageOnMap";
import { generatingLayerId } from "../utils";

import pointIconRed from "assets/images/location-marker-red.png";
import pointIconOrange from "assets/images/location-marker-orange.png";

import mapboxgl from "mapbox-gl";
import generateUniqueId from "view/pages/Forms/EditFormDetails/Components/SurveyJs/utils/generateUniqueId";

import * as turf from "@turf/turf";
import { includes, mapValues } from "lodash";
import { screens } from "store/geography/initialState";
import { getFilteredOrCompleteProperties } from "../utils/getFilteredOrCompleteProperties";
import { sentenceCase } from "change-case";

// function handleCreationOfMarkerOnMap(
//   item: any,
//   selectedMarkers: any,
//   setSelectedMarkers: any,
//   map: any,
//   mapboxgl: any,
//   popups?: any,
//   setPopups?: any,
//   cb?:any
// ) {
//   // Function to process a single feature
//   const processFeature = (feature: any, featureId: any) => {
//     if (feature.geometry && feature.geometry.type === "Point") {
//       const coordinates = feature.geometry.coordinates;

//       if (!selectedMarkers.find((m: any) => m.id === featureId)) {
//         const marker = new mapboxgl.Marker({ draggable: false,color:"red" })
//           .setLngLat(coordinates)
//           .addTo(map);

//         marker.getElement().addEventListener("click", (e: any) => {
//           e.stopPropagation();

//           if(item.clickable){
//             cb({marker,id:featureId});
//             return;
//           };

//           // removing existing popups
//           const existingPopups = document.querySelectorAll(".mapboxgl-popup");
//           existingPopups?.forEach((popup) => popup?.remove());

//           // creating new popup
//           const newPopup = new mapboxgl.Popup({ closeOnClick: true })
//             .setLngLat([1, 1])
//             .setHTML(
//               `<div
//                 class="flex justify-center items-start rounded-lg bg-bgWhite font-Overpass w-[256px]"
//               >
//                 <div class="flex flex-col justify-start items-start gap-1 p-1 w-full">
//                   <div
//                     class="flex justify-start items-start relative pr-3"
//                   >
//                     <p class=" text-sm font-medium text-left capitalize text-[#202123]">
//                       ${item.item.name}
//                     </p>
//                   </div>
//                   ${
//                     item.item.description &&
//                     `<div
//                   class="flex justify-start items-center relative gap-2 pb-0.5"
//                 >
//                   <p class="text-xs text-left text-secondaryMid">
//                    ${item.item.description}
//                   </p>
//                 </div>`
//                   }
//                   <div class="flex justify-start items-center gap-3">
//                     <div
//                       class="flex justify-start items-center relative gap-2 pr-1"
//                     >
//                       <p class=" text-xs text-left text-secondaryMid">Latitude: ${
//                         coordinates[1]
//                       }</p>
//                       <p class=" text-xs text-left text-secondaryMid">Longitude: ${
//                         coordinates[0]
//                       }</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>`
//             )
//             .addTo(map);

//           const isPopupAlreadyAppended = popups?.find(
//             (p: any) => p.id === item.item.id
//           );

//           if (!isPopupAlreadyAppended && setPopups) {
//             setPopups((prev: any) => [
//               ...popups,
//               { id: item.item.id, item: newPopup },
//             ]);
//           }
//         });

//         setSelectedMarkers((prev: any) => [...prev, { marker, id: featureId }]);
//       }
//     }
//   };

//   if (item.status === false) {
//     const markerToBeRemoved = selectedMarkers.filter((m: any) => {
//       return m.id?.toString()?.includes(item.item.id);
//     });
//     if (markerToBeRemoved.length > 0) {
//       markerToBeRemoved.forEach((item: any) => {
//         item.marker.remove();
//       });

//       setSelectedMarkers((prev: any) =>
//         prev.filter((m: any) => !m.id?.toString()?.includes(item.item.id))
//       );

//       const popup = popups?.find((p: any) => p.id === item.item.id);
//       if (popup && setPopups) {
//         popup.item.remove();

//         const updatedPopups = popups?.filter((p: any) => p.id !== popup.id);

//         setPopups((prev: any) => [...updatedPopups]);
//       }
//     }
//     return;
//   }

//   if (selectedMarkers.find((m: any) => m.id === item.item.id)) {
//     return;
//   }

//   const geojsonData = convertGeoJSON(item.item?.mapData);

//   if (geojsonData?.type === "Feature") {
//     processFeature(geojsonData, item.item.id);
//   }
//   else if (geojsonData?.type === "FeatureCollection") {

//     geojsonData.features.forEach((feature: any) =>{
//       let id = item.item.id;

//       if(item.clickable){
//         id = generateUniqueId(id+".",".point");
//       };

//       processFeature(feature, id)
//     });
//   }
//   else {
//     console.error("Invalid GeoJSON data");
//   }
// }

// async function handleCreationOfMarkerOnMap(
//   item: any,
//   map: any,
//   popups: any,
//   setPopups: any,
//   setNewPopup: any,
//   isEditMode?: string,
//   cb?: any
// ) {
//   const { sourceId, layerId } = generatingLayerId(item.itemType, item.item.id);
//   // stop the layer painting if its already painted
//   if (item.status === false) {
//     // if layer item is unchecked then unpaint it from the map
//     const layers = map.getStyle().layers;

//     for (let index = layers.length - 1; index > 0; index--) {
//       const pointLayer = layers[index];

//       const pointLayerId = pointLayer.id;

//       if (
//         pointLayerId.includes("point#") ||
//         pointLayerId.includes("vector#") ||
//         pointLayerId.includes("region#") ||
//         pointLayerId.includes("raster#") ||
//         pointLayerId.includes("shape#")
//       ) {
//         if (pointLayer.source.includes(sourceId)) {
//           map.removeLayer(pointLayer.id);
//           map.removeSource(pointLayer.source);
//         }
//       } else {
//         break;
//       }
//     }

//     // if (isEditMode) {
//     const popup = popups?.find((p: any) => p.id === item.item.id);

//     if (popup && setPopups) {
//       popup.item.remove();

//       const updatedPopups = popups?.filter((p: any) => p.id !== popup.id);

//       setPopups((prev: any) => [...updatedPopups]);
//     }
//     // }
//   } else if (item.status) {
//     // const loadImageWithFetch = async (url: string) => {
//     //   try {
//     //     const response = await fetch(url);
//     //     if (!response.ok) {
//     //       throw new Error(`Failed to fetch image: ${url}`);
//     //     }
//     //     const blob = await response.blob();
//     //     const img = new Image();
//     //     img.src = URL.createObjectURL(blob);
//     //     return new Promise((resolve, reject) => {
//     //       img.onload = () => resolve(img);
//     //       img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
//     //     });
//     //   } catch (error: any) {
//     //     throw new Error(`Error fetching image: ${error.message}`);
//     //   }
//     // };
//     if (item && item.item && item.item.markerStyle) {
//       if (!map.hasImage(item.item.markerStyle.markerStyleId)) {
//         // const imageee = await loadImageWithFetch(
//         //   item.item.markerStyle.styleImage
//         // );

//         await registerImageOnMap(
//           map,
//           item.item.markerStyle.styleImage,
//           item.item.markerStyle.markerStyleId
//         );
//       }
//       if (!map.hasImage("marker-orange")) {
//         await registerImageOnMap(map, pointIconOrange, "marker-orange");
//       }
//     }

//     if (!map.hasImage("marker-orange")) {
//       await registerImageOnMap(map, pointIconOrange, "marker-orange");
//     }

//     const features = item.item.mapData.features;

//     if (features?.length > 0) {
//       features?.forEach((f: any, i: number) => {
//         const newSourceId = sourceId + "-child#" + i;
//         const newLayerId = newSourceId + "-layer";
//         f.id = newLayerId;

//         if (!map.getLayer(newLayerId)) {
//           const featureCollection = turf.featureCollection([f]);

//           // paint the point
//           map.addSource(newSourceId, {
//             type: "geojson",
//             data: featureCollection,
//           });

//           map.addLayer({
//             id: newLayerId,
//             type: "symbol",
//             source: newSourceId,
//             layout: {
//               "icon-image": item.item.markerStyle.markerStyleId ?? "marker-red",
//               "icon-size": 0.5,
//             },
//           });

//           if (isEditMode === screens.ALL_LAYERS) {
//             map.off("click", newLayerId, handleClickOnLayer);

//             // register click event listener on point
//             map.on("click", newLayerId, handleClickOnLayer);
//           } else {
//             map.on("click", newLayerId, (event: any) => {
//               cb(
//                 event,
//                 newLayerId,
//                 item.item.markerStyle.markerStyleId ?? "marker-red"
//               );
//             });
//           }

//           function handleClickOnLayer(e: any) {
//             cb(e, newLayerId);
//             // create a popup
//             if (e.features.length > 0 && isEditMode && setNewPopup) {
//               const feature = e.features[0];
//               const name = feature.properties.name ?? item.item.name;
//               const description =
//                 feature.properties.description ?? item.item.description;

//               const coordinates =
//                 item.item.mapData.features[0].geometry.coordinates;

//               if (isEditMode === screens.ALL_LAYERS) {
//                 setNewPopup({
//                   coordinates: e.lngLat,
//                   html: getPopupHtml(name, description, coordinates),
//                   isAlreadyAdded: popups?.find(
//                     (p: any) => p.id === item.item.id
//                   ),
//                   id: item.item.id,
//                 });
//                 // console.log(
//                 //   "do you want to display the popup of the item?",
//                 //   isEditMode
//                 // );
//                 // const popup = new mapboxgl.Popup()
//                 //   .setLngLat(e.lngLat)
//                 //   .setHTML(getPopupHtml(name, description, coordinates))
//                 //   .addTo(map);

//                 // const isPopupAlreadyAppended = popups?.find(
//                 //   (p: any) => p.id === item.item.id
//                 // );

//                 // if (!isPopupAlreadyAppended && setPopups) {
//                 //   setPopups((prev: any) => [
//                 //     ...popups,
//                 //     { id: item.item.id, item: popup },
//                 //   ]);
//                 // }
//               }
//             }
//           }
//         }
//       });
//     }
//   }
//   // function hanldeClickOnLayer(e: any) {
//   //   cb(e, newLayerId);
//   //   // create a popup
//   //   if (e.features.length > 0 && isEditMode) {
//   //     const feature = e.features[0];
//   //     const name = feature.properties.name ?? item.item.name;
//   //     const description = feature.properties.description ?? item.item.description;

//   //     const coordinates = item.item.mapData.features[0].geometry.coordinates;

//   //     if (isEditMode) {
//   //       const popup = new mapboxgl.Popup()
//   //         .setLngLat(e.lngLat)
//   //         .setHTML(getPopupHtml(name, description, coordinates))
//   //         .addTo(map);

//   //       const isPopupAlreadyAppended = popups?.find(
//   //         (p: any) => p.id === item.item.id
//   //       );

//   //       if (!isPopupAlreadyAppended && setPopups) {
//   //         setPopups((prev: any) => [
//   //           ...popups,
//   //           { id: item.item.id, item: popup },
//   //         ]);
//   //       }
//   //     }
//   //   }
//   // }
// }

// async function handleCreationOfMarkerOnMap(
//   item: any,
//   map: any,
//   popups: any,
//   setPopups: any,
//   setNewPopup: any,
//   isEditMode?: boolean,
//   cb?: any,
//   LAYER_DEFAULT_COLOR?: any,
//   markers?: any,
//   setMarkers?: any
// ) {
//   const { sourceId, layerId } = generatingLayerId(item.itemType, item.item.id);
//   let newMarkers: any[] = [];
//   // stop the layer painting if its already painted
//   if (item.status === false) {
//     // if layer item is unchecked then unpaint it from the map
//     // const layers = map.getStyle().layers;

//     // for (let index = layers.length - 1; index > 0; index--) {
//     //   const pointLayer = layers[index];

//     //   const pointLayerId = pointLayer.id;

//     //   if (
//     //     pointLayerId.includes("point#") ||
//     //     pointLayerId.includes("vector#") ||
//     //     pointLayerId.includes("region#") ||
//     //     pointLayerId.includes("raster#") ||
//     //     pointLayerId.includes("shape#")
//     //   ) {
//     //     if (pointLayer.source.includes(sourceId)) {
//     //       map.removeLayer(pointLayer.id);
//     //       map.removeSource(pointLayer.source);
//     //     }
//     //   } else {
//     //     break;
//     //   }
//     // }

//     setMarkers((pre: any[]) => {
//       const markersToBeRemoved: any[] =
//         pre?.filter((p: any) => p.id === item.item.id) ?? [];
//       markersToBeRemoved.forEach((item: any) => {
//         item?.marker?.remove();
//         item?.popup?.remove();
//       });
//       return pre?.filter((p: any) => p.id !== item.item.id);
//     });

//     // if (isEditMode) {
//     const popup = popups?.find((p: any) => p.id === item.item.id);

//     if (popup && setPopups) {
//       popup.item.remove();

//       const updatedPopups = popups?.filter((p: any) => p.id !== popup.id);

//       setPopups((prev: any) => [...updatedPopups]);
//     }
//     // }
//   } else if (item.status) {
//     // const loadImageWithFetch = async (url: string) => {
//     //   try {
//     //     const response = await fetch(url);
//     //     if (!response.ok) {
//     //       throw new Error(`Failed to fetch image: ${url}`);
//     //     }
//     //     const blob = await response.blob();
//     //     const img = new Image();
//     //     img.src = URL.createObjectURL(blob);
//     //     return new Promise((resolve, reject) => {
//     //       img.onload = () => resolve(img);
//     //       img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
//     //     });
//     //   } catch (error: any) {
//     //     throw new Error(`Error fetching image: ${error.message}`);
//     //   }
//     // };
//     // if (item && item.item && item.item.markerStyle) {
//     //   if (!map.hasImage(item.item.markerStyle.markerStyleId)) {
//     //     // const imageee = await loadImageWithFetch(
//     //     //   item.item.markerStyle.styleImage
//     //     // );

//     //     await registerImageOnMap(
//     //       map,
//     //       item.item.markerStyle.styleImage,
//     //       item.item.markerStyle.markerStyleId
//     //     );
//     //   }
//     //   if (!map.hasImage("marker-orange")) {
//     //     await registerImageOnMap(map, pointIconOrange, "marker-orange");
//     //   }
//     // }

//     // if (!map.hasImage("marker-orange")) {
//     //   await registerImageOnMap(map, pointIconOrange, "marker-orange");
//     // }

//     const features = item?.item?.mapData?.features;

//     if (features?.length > 0) {
//       features?.forEach((f: any, i: number) => {
//         const markerId = layerId + "-point" + f.id;

//         const coordinates = f.geometry?.coordinates;

//         const isMarkerHighlighted = false;

//         const markerColor = LAYER_DEFAULT_COLOR;

//         if (coordinates) {
//           const [longitude, latitude] = coordinates;
//           const markerElement = document.createElement("div");
//           markerElement.className = "custom-marker-point-picker";

//           markerElement.innerHTML = `
//           <div class="marker-content-point-picker" style="
//             width: 50px;
//             height: 50px;
//             border-radius: 50%;
//             border: 3px solid ${isEditMode === false ? "transparent" : "transparent"};
//             overflow: hidden;
//             background: transparent;
//           ">
//             <img src="${item.item?.markerStyle?.styleImage ?? pointIconRed}" alt="Marker Image" class="marker-image" style="width: 100%; height: 100%; object-fit: cover; background: transparent;"/>
//           </div>
//         `;

//           const marker = new mapboxgl.Marker(markerElement)
//             .setLngLat([longitude, latitude])
//             .addTo(map);
//           // const name = f.properties.name ?? item.item.name;
//           // const description = f.properties.description ?? item.item.description;
//           const feature = f;
//           const name = feature.properties.name ?? item.item.name;
//           const properties = feature.properties ?? {};

//           const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
//             getPopupHtml(name, properties, item.item.uniqueProps)
//           );

//           // Attach the popup to the marker

//           if (isEditMode === false) {
//             marker.setPopup(popup);
//           } else {
//             // marker.getElement().addEventListener("click", (e) => {
//             //   cb(f);
//             // });
//           }

//           newMarkers.push({
//             marker,
//             id: item.item.id,
//             item: item.item,
//             data: f,
//             popup: popup,
//             markerId,
//           });
//         }

//         setMarkers((pre: any) => {
//           return [...pre, ...newMarkers];
//         });
//         // if (!map.getLayer(newLayerId)) {
//         //   const featureCollection = turf.featureCollection([f]);

//         //   // paint the point
//         //   map.addSource(newSourceId, {
//         //     type: "geojson",
//         //     data: featureCollection,
//         //   });

//         //   map.addLayer({
//         //     id: newLayerId,
//         //     type: "symbol",
//         //     source: newSourceId,
//         //     layout: {
//         //       "icon-image": item.item.markerStyle.markerStyleId ?? "marker-red",
//         //       "icon-size": 0.5,
//         //     },
//         //   });

//         //   if (isEditMode === screens.ALL_LAYERS) {
//         //     map.off("click", newLayerId, handleClickOnLayer);

//         //     // register click event listener on point
//         //     map.on("click", newLayerId, handleClickOnLayer);
//         //   } else {
//         //     map.on("click", newLayerId, (event: any) => {
//         //       cb(
//         //         event,
//         //         newLayerId,
//         //         item.item.markerStyle.markerStyleId ?? "marker-red"
//         //       );
//         //     });
//         //   }

//         // function handleClickOnLayer(e: any, feature?: any) {
//         //   // cb(e, newLayerId);
//         //   // create a popup
//         //   if (features.length > 0 && isEditMode && setNewPopup) {
//         //     const name = feature.properties.name ?? item.item.name;
//         //     const description =
//         //       feature.properties.description ?? item.item.description;

//         //     const coordinates = feature.geometry.coordinates;

//         //     if (isEditMode === screens.ALL_LAYERS) {
//         //       setNewPopup({
//         //         coordinates: coordinates,
//         //         html: getPopupHtml(name, description, coordinates),
//         //         isAlreadyAdded: popups?.find((p: any) => p.id === item.item.id),
//         //         id: item.item.id,
//         //       });
//         //       // console.log(
//         //       //   "do you want to display the popup of the item?",
//         //       //   isEditMode
//         //       // );
//         //       // const popup = new mapboxgl.Popup()
//         //       //   .setLngLat(e.lngLat)
//         //       //   .setHTML(getPopupHtml(name, description, coordinates))
//         //       //   .addTo(map);

//         //       // const isPopupAlreadyAppended = popups?.find(
//         //       //   (p: any) => p.id === item.item.id
//         //       // );

//         //       // if (!isPopupAlreadyAppended && setPopups) {
//         //       //   setPopups((prev: any) => [
//         //       //     ...popups,
//         //       //     { id: item.item.id, item: popup },
//         //       //   ]);
//         //       // }
//         //     }
//         //   }
//         // }
//         // }
//       });
//     }
//   }
//   // function hanldeClickOnLayer(e: any) {
//   //   cb(e, newLayerId);
//   //   // create a popup
//   //   if (e.features.length > 0 && isEditMode) {
//   //     const feature = e.features[0];
//   //     const name = feature.properties.name ?? item.item.name;
//   //     const description = feature.properties.description ?? item.item.description;

//   //     const coordinates = item.item.mapData.features[0].geometry.coordinates;

//   //     if (isEditMode) {
//   //       const popup = new mapboxgl.Popup()
//   //         .setLngLat(e.lngLat)
//   //         .setHTML(getPopupHtml(name, description, coordinates))
//   //         .addTo(map);

//   //       const isPopupAlreadyAppended = popups?.find(
//   //         (p: any) => p.id === item.item.id
//   //       );

//   //       if (!isPopupAlreadyAppended && setPopups) {
//   //         setPopups((prev: any) => [
//   //           ...popups,
//   //           { id: item.item.id, item: popup },
//   //         ]);
//   //       }
//   //     }
//   //   }
//   // }
// }

async function handleCreationOfMarkerOnMap(
  item: any,
  map: any,
  popups: any,
  setPopups: any,
  setNewPopup: any,
  isEditMode?: boolean,
  cb?: any,
  LAYER_DEFAULT_COLOR?: any,
  markers?: any,
  setMarkers?: any
) {
  const { sourceId, layerId } = generatingLayerId(item.itemType, item.item.id);

  if (item.status === false) {
    // Remove the layer if it exists
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
    }

    // Remove the source if it exists
    if (map.getSource(sourceId)) {
      map.removeSource(sourceId);
    }

    // Remove all popups associated with the layer
    const associatedPopups = popups.filter((p: any) => p.layerId === layerId);
    associatedPopups.forEach((popup: any) => {
      popup.popup.remove(); // Remove the popup from the map
    });

    // Update the state to remove these popups
    setPopups((prev: any) => prev.filter((p: any) => p.layerId !== layerId));

    console.log("Removed all popups associated with layer:", layerId);
    return;
  }

  // Add marker images if not already loaded
  if (item?.item?.markerStyle) {
    const { markerStyleId, styleImage, styleImageId } = item.item.markerStyle;
    if (!map.hasImage(markerStyleId)) {
      const image =
        await fetchImageViaIdAndConvertToBlobAndReturnBlobUrl(styleImageId);
      if (image !== undefined) {
        await registerImageOnMap(map, image, markerStyleId);
      }
    }
  }

  if (!map.hasImage("marker-orange")) {
    await registerImageOnMap(map, pointIconOrange, "marker-orange");
  }

  // Add the GeoJSON source and layer

  if (!map.getSource(sourceId)) {
    map.addSource(sourceId, {
      type: "geojson",
      data: item.item.mapData ?? item.item.geoFilePath,
    });
  }

  if (!map.getLayer(layerId)) {
    map.addLayer({
      id: layerId,
      type: "symbol",
      source: sourceId,
      layout: {
        "icon-image": item?.item?.markerStyle?.markerStyleId ?? "marker-orange",
        "icon-size": 1,
      },
    });
  }

  // Add click event to show popups with coordinates
  // map.on("click", layerId, (e: any) => {
  //   if (!isEditMode) {
  //     const feature = e.features[0];
  //     const name = item.item.name;

  //     const newPopUp = new mapboxgl.Popup()
  //       .setLngLat(e.lngLat)
  //       .setHTML(getPopupHtml(name, feature.properties, item.item.uniqueProps))
  //       .addTo(map);

  //     const isPopupAlreadyAppended = popups?.find(
  //       (p: any) => p.id === item.item.id
  //     );
  //     console.log("isPopupAlreadyAppended", isPopupAlreadyAppended);

  //     if (!isPopupAlreadyAppended && setPopups) {
  //       setPopups((prev: any) => [
  //         ...popups,
  //         { id: item.item.id, item: newPopUp },
  //       ]);
  //     }
  //   } else {
  //     // const feature = e.features[0];
  //     // cb(feature);
  //   }
  // });

  map.on("click", layerId, (e: any) => {
    if (!isEditMode) {
      const feature = e.features[0];
      const name = item.item.name;

      const newPopup = new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(getPopupHtml(name, feature.properties, item.item.uniqueProps))
        .addTo(map);

      const isPopupAlreadyAppended = popups.find(
        (p: any) => p.id === item.item.id
      );

      if (!isPopupAlreadyAppended && setPopups) {
        setPopups((prev: any) => [
          ...prev,
          { id: item.item.id, layerId: layerId, popup: newPopup },
        ]);
      }
    }
  });

  // Change cursor on hover over the layer
  map.on("mouseenter", layerId, () => {
    map.getCanvas().style.cursor = "pointer";
  });

  // Reset cursor when leaving the layer
  map.on("mouseleave", layerId, () => {
    map.getCanvas().style.cursor = "";
  });
}
export { handleCreationOfMarkerOnMap };

const getPopupHtml = (
  name: string,
  properties: { [key: string]: any } = {},
  uniqueProps: { [key: string]: any } = {}
): string => {
  const filteredData = getFilteredOrCompleteProperties(properties, uniqueProps);

  return `<div class="min-w-[200px]  max-h-[200px]   relative overflow-auto rounded bg-bgWhite">
    <div class="flex flex-col justify-start items-start gap-[9px]">
      <p class="self-stretch  text-left text-secondaryMid">
        ${name}
      </p>
      <div class="min-w-[200px]  max-h-[120px]   relative overflow-auto rounded bg-bgWhite">
      ${Object.entries(filteredData)
        .map(
          ([key, value]) =>
            `<p class=text-secondaryMid">
          ${sentenceCase(key ?? "")}: ${value}
        </p>`
        )
        .join("")}
    </div>
  </div>`;
};
