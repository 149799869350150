import { panelsName } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panelsTitle";
import questionTypes from "view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes";
import {
  GET_OBSERVATION_PROFILE,
  RESET_OBSERVATION_PROFILE,
  START_LOADING_OBSERVATION,
  STOP_LOADING_OBSERVATION,
  SWITCH_TO_LOCAL_TIME_ZONE,
} from "./action-types";

import { initialState } from "./initialState";

export const observationProfileReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    // Loading states
    case START_LOADING_OBSERVATION:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_LOADING_OBSERVATION:
      return {
        ...state,
        isLoading: false,
      };

    // case GET_OBSERVATION_PROFILE:
    //   const data = action.payload;
    // const isGeoSpetialComponentExit = verifyExistenceOfGeospatialQuestions(data.sections)
    //   data.sections.forEach((section: any) => {
    //     if (section.templateElements) {
    //       section.templateElements.forEach((element: any) => {
    //         element.isUpdated = false;
    //         element.isTranscriptionUpdated = false;
    //         element.isAudioUpdated = false;
    //       });
    //     }

    //     if (section.elements) {
    //       section.elements.forEach((element: any) => {
    //         element.isUpdated = false;
    //         element.isTranscriptionUpdated = false;
    //         element.isAudioUpdated = false;
    //       });
    //     }

    //     // Remove 'isUpdated' key from the section itself
    //     section.isUpdated = false;
    //   });

    //   return {
    //     ...data,
    //     isLoading: false,
    //   };

    case GET_OBSERVATION_PROFILE:
      const originalData = action.payload;

  
      // Function to update element properties immutably
      const createUpdatedElements = (elements: any) =>
        elements.map((element: any) => ({
          ...element,
          isUpdated: false,
          isTranscriptionUpdated: false,
          isAudioUpdated: false,
        }));

      const newData = {
        ...originalData,
        sections: originalData.sections.map((section: any) => ({
          ...section,
          templateElements: section.templateElements
            ? createUpdatedElements(section.templateElements)
            : section.templateElements,
          elements: section.elements
            ? createUpdatedElements(section.elements)
            : section.elements,
          isUpdated: false,
        })),
        isLoading: false,
      };

      return newData;

    case RESET_OBSERVATION_PROFILE:
      return initialState;

    case SWITCH_TO_LOCAL_TIME_ZONE:
      return {
        ...state,
        useLocalTimeZone: action.payload,
      };
    default:
      return state;
  }
};


