import { SET_THEME_COLORS, SET_THEME_LOADED } from "./action-types";
import { Dispatch } from "redux";

// Theme mapping for different apps
const themes: any = {
    sign_admin: {                                   // These are sign app colors
        primary: '#8D2A25',
        primaryDark: '#7C241F',
        primaryMid: '#8D2A25',
        primaryExtraLight: '#F6EDDD',
        primaryMidLight: '#8D2A25',
        bgBluish_2: '#F6EDDD',
        textLink: '#8D2A25',
        bgHighMidlight: '#ededed3b'
    },
    isn_admin: {                                   // These are default (ISN) app colors
        primary: '#005C89',
        primaryDark: '#024C6F',
        primaryMid: '#0A7BAC',
        primaryExtraLight: '#EBF2F6',
        primaryMidLight: '#11A3E2',
        bgBluish_2: '#E6EFF4',
        textLink: '#005C89',
        bgHighMidlight: '#F2F7F9'
    },
    // Define additional themes as needed
};

export const getCurrentBranding = () => {
    const currentBranding = process.env.REACT_APP_BRAND_NAME ?? 'default';
    return currentBranding.toLocaleLowerCase();
};

export const applyTheme = (theme: Record<string, string>) => {
    const root = document.documentElement;
    Object.keys(theme).forEach(key => {
        // Use camelCase keys directly as CSS variable names
        const cssVariableName = `--${key}`;
        root.style.setProperty(cssVariableName, theme[key]);
    });
};

export const fetchAndApplyTheme = () => async (dispatch: Dispatch) => {
    try {
        const brand = getCurrentBranding();
        const theme = themes[brand] || themes.default;

        applyTheme(theme); // Apply CSS variables to the root

        // Dispatch normalized theme colors to Redux state
        dispatch({
            type: SET_THEME_COLORS,
            payload: theme, // Send normalized theme colors to Redux
        });
    } catch (error) {
        console.error("Failed to fetch theme colors:", error);
        dispatch({
            type: SET_THEME_COLORS,
            payload: {}, // Send empty payload on error
        });
    }
};

export const setThemeLoaded = () => (dispatch: Dispatch) => {
    dispatch({
        type: SET_THEME_LOADED,
    });
};
