import React, { useState } from "react";
// Components
import MultiSelect from "view/components/Multiselect";

export const AddOrganizations: React.FC<any> = ({
  name,
  handleAddOrganizationsOnChange,
  values,
  data = [],
  handleSearchOrganization,
  isMulti,
  error,
  touched,
  handleBlur
}) => {
  const [inputValue, setInputValue] = useState("");
  return (
    <div className="items-center py-2">
      <p className="font-Overpass flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption text-accent_1Dark dark:text-accent_1Dark">Select Organization<span className="text-accent_1Dark">*</span></p>
      <div className="w-full relative mt-1">
        <div
          className={`flex justify-start items-center w-full relative gap-1.5 pl-3  rounded bg-white ${touched && error ? `border-accent_1Dark ` : `border-lineDark`}  border `}
        >
          <div className="flex flex-col items-start justify-start w-full">
            <MultiSelect
              name={name}
              inputValue={inputValue}
              onInputChange={(value: string) => {
                setInputValue(value);
                handleSearchOrganization(value);
              }}
              options={data}
              defaultValue={{ value: "", label: "" }}
              isMulti={isMulti}
              closeOnSelect={true}
              placeholder="Organization"
              value={values?.organizationId}
              onChange={(option: any) => {
                handleAddOrganizationsOnChange(option);
              }}
              handleBlur={handleBlur}
              sx={{
                valueContainer: (provided: any, state: any) => ({
                  ...provided,
                  padding: 0,
                }),
                placeholder: (provided: any, state: any) => ({
                  ...provided,
                  opacity: '80%',
                }),
              }}
            />
          </div>
        </div>
        {/* Error if organization is not selected */}
        {error && touched && (
          <div>
            <p className="flex-grow text-xs text-left text-accent_1Dark dark:text-accent_1Dark">
              Organization is required
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
