import React, { useEffect, useState } from "react";
/************ Assets ************/
import { CheveronDownIcon } from "assets/icons/HeroIcons";
/************ External Library ************/
import { Select as MaterialUiSelect } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";
import apiClient from "services/apiClient";
import mapToDropdownValues from "utils/mapDropdownValues";
import { ItemValue } from "survey-core";
import { conditionalityForObservations } from "utils/conditionalityForObservations";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

/**
 * A custom icon component.
 * @param {any} props - Component props containing 'open' and 'onClick' properties.
 */

const CustomIcon = (props: any) => {
  const { open, onClick } = props;

  return (
    <CheveronDownIcon
      height={16}
      width={16}
      onClick={onClick}
      className={`-0 -0 w-6 h-6 relative ${open ? "transform rotate-180" : ""}`}
      preserveAspectRatio="xMidYMid meet"
      style={{ marginRight: "12px", cursor: "pointer" }}
    />
  );
};

const CustomOutlinedInput = styled(OutlinedInput)`
  &:hover:not($disabled):not($focused):not($error) $notchedOutline {
    border-color: transparent;
  }
  &.Mui-focused $notchedOutline {
    border-color: transparent;
  }
`;

interface DropdownProps {
  label?: string;
  error?: any;
  name: string;
  editMode?: boolean;
  value?: any;
  data?: any[];
  handleChange?: any;
  handleBlur?: any;
  touched?: any;
  placeholder?: any;
  required?: boolean;
  choicesByUrl: {
    url: string;
    valueName: string;
    titleName: string;
  };
  choices?: any[];
  visibleIf?: any;
  enableIf?: any;
  setValueIf?: any;
  setValueExpression?: any;
  requiredIf?: any;
  answers?: any;
  labelSize?: string;
  panel?: any;
  element?: any;
}

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  error,
  name,
  editMode = true,
  value,
  data,
  handleChange,
  handleBlur,
  touched,
  placeholder,
  required,
  choicesByUrl = null,
  choices = [],
  visibleIf = true,
  enableIf = false,
  setValueIf,
  setValueExpression,
  requiredIf,
  answers,
  labelSize,
  panel,
  element
}) => {
  const isVisible = true;

  const isEnabled = false;

  if (!isVisible) {
    return;
  }

  const [open, setOpen] = useState(false);
  const [option, setOption] = useState([]);
  const handleToggle = () => {
    setOpen(!open);
  };

  const getDropdownOptions = async (choicesByUrl: any) => {
    try {
      const { data } = await apiClient.get(choicesByUrl.url);
      const dropdownOptions = mapToDropdownValues(
        data,
        choicesByUrl.valueName,
        choicesByUrl.titleName,
        choicesByUrl.valueName,
        choicesByUrl.titleName
      );
      setOption(dropdownOptions);
    } catch (error: any) {
      return [];
    }
  };
  const itemValue =
    value !== null && typeof value === "object"
      ? choicesByUrl !== null
        ? getItemValue(value, choicesByUrl)
        : getItemValue(value, null)
      : value;
  useEffect(() => {
    if (choicesByUrl !== null && editMode) {
      getDropdownOptions(choicesByUrl);
    }
  }, [editMode]);
  return (
    <div className={`flex flex-col flex-grow w-full rounded-lg dark:bg-secondaryLight bg-bgPrimaryLight h-full ${labelSize ? "p-0" : "p-4"}`} id={`${element?.groupQuestionName || element?.name}${panel?.sectionId}`}>
      <div className="relative flex flex-col py-2">
        {editMode ? (
          <p className={"w-full pb-1 text-left capitalize font-Overpass text-primary dark:text-caption font-medium " + (labelSize ? `text-${labelSize}` : "text-md")}>
            {label || name} {required && <span>*</span>}
          </p>
        ) : (
          <p className="w-full pb-1 text-left capitalize text-md font-Overpass font-regular text-primary dark:text-caption ">
            {label || name} {required && <span>*</span>}
          </p>
        )}
        {editMode ? (
          <>
            <FormControl className="">
              <MaterialUiSelect
                disabled={isEnabled}
                className={`w-full h-9 !font-Overpass selectremove_active border-none bg-bgWhite ${!value ? "!text-textLightExtra" : "!text-textNormal"
                  }`}
                displayEmpty
                value={itemValue || ""}
                // onChange={(e: any) => {
                //   const { value } = e.target;
                //   const item =
                //     choicesByUrl !== null
                //       ? option.find(
                //           (item: any) =>
                //             getItemValue(item, choicesByUrl) ==
                //             getItemValue(value, choicesByUrl)
                //         )
                //       : choices.find(
                //           (item: any) =>
                //             getItemValue(item, null) ===
                //             getItemValue(value, null)
                //         );
                //   handleChange(item);
                // }}
                name={name}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={CustomIcon}
                open={open}
                onClick={handleToggle}
                input={<CustomOutlinedInput />}
                required={required}
                onBlur={handleBlur}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    // Add your custom styles here
                    borderColor:
                      error && touched ? "#BF200B" : "rgba(0, 0, 0, 0.23)",
                  },
                  // You can add other styles for different parts of the Select component
                }}
              >
                <MenuItem
                  value=""
                  disabled
                  className="menu_items !text-textNormal !font-Overpass dark:bg-secondaryLight dark:text-caption "
                >
                  {placeholder || "Select"}
                </MenuItem>
                {choicesByUrl !== null
                  ? renderMenuItems(option, choicesByUrl, handleChange)
                  : renderSimpleMenuItems(choices, handleChange)}
              </MaterialUiSelect>
            </FormControl>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {touched && error && (
                <p className="flex-grow text-xs text-left text-accent_1Dark">
                  {error}
                </p>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              {value ?
                <p className="w-full max-w-full max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] font-Overpass text-left font-normal text-textAnswer">
                  {getItemLabel(value, choicesByUrl)}
                  {/* {value && typeof value === "object"
                  ? choicesByUrl !== null ? value[choicesByUrl.titleName] : value?.text
                  : value} */}
                </p>
                :
                <p className="text-textNoSelection text-[15px] font-normal italic font-Overpass">
                  (No Selection)
                </p>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Dropdown;

const renderMenuItems = (
  items: any[],
  choicesByUrl: {
    url: string;
    valueName: string;
    titleName: string;
  },
  handleChange: any
) => {
  const updateDropDownValue = (value: any) => {
    const item = items.find(
      (item: any) => getItemValue(item, null) === getItemValue(value, null)
    );
    handleChange(item);
  };

  return items.map((item) => {
    const itemLabel = getItemLabel(item, choicesByUrl);
    const itemValue = getItemValue(item, choicesByUrl);
    return (
      <MenuItem
        onClick={() => {
          updateDropDownValue(itemValue);
        }}
        key={itemValue}
        value={itemValue}
        className="menu_items !text-textNormal !font-Overpass dark:bg-secondaryLight dark:text-caption "
      >
        {itemLabel}
      </MenuItem>
    );
  });
};

const renderSimpleMenuItems = (items: any[], handleChange: any) => {
  const updateDropDownValue = (value: any) => {
    const item = items.find(
      (item: any) => getItemValue(item, null) === getItemValue(value, null)
    );
    handleChange(item);
  };
  return items.map((item) => {
    const itemLabel = getItemLabel(item, null);
    const itemValue = getItemValue(item, null);
    return (
      <MenuItem
        key={itemValue}
        value={itemValue}
        onClick={() => {
          updateDropDownValue(itemValue);
        }}
        className="menu_items !text-textNormal !font-Overpass dark:bg-secondaryLight dark:text-caption "
      >
        {itemLabel}
      </MenuItem>
    );
  });
};

function getItemLabel(item: any, choicesByUrl: any) {
  if (item && typeof item === "object") {
    return choicesByUrl !== null ? item[choicesByUrl.titleName] : item?.text;
  }
  return item;
}

function getItemValue(item: any, choicesByUrl: any) {
  if (item && typeof item === "object") {
    return choicesByUrl !== null ? item[choicesByUrl.valueName] : item?.value;
  }
  return item;
}
