// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { AddBrandBtn, PageHeading } from "./components";

// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";

import { filtersInitialState } from "store/filters/initialState";
import { openAddOrEditCategoryModalAction } from "store/modals";
import { useNavigate } from "react-router-dom";

// Generating URLs
const generateDynamicBreadcrumbLinks = (label: any) => {
  switch (true) {
    default:
      return [{ path: `/brands/list`, label: `Brands` }];
  }
};
export const Header = () => {
  const { brandsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const dynamicCategoryLinks = generateDynamicBreadcrumbLinks(
    brandsFilter.status
  );

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={dynamicCategoryLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            <div className="relative flex flex-col items-end justify-start flex-grow-0 flex-shrink-0 gap-2 cursor-pointer">
              <AddBrandBtn
                handleAddBrand={() => navigate("/brands/add")}
                label={"Add Brand"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
