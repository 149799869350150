import React, { useState } from "react";
/************ Assets ************/
import { CheveronDownIcon } from "assets/icons/HeroIcons";
/************ External Library ************/
import { Select as MaterialUiSelect } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";
import { DarkMode } from "store/darkMode/initialState";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 200,
    },
  },
};

/**
 * A custom icon component.
 * @param {any} props - Component props containing 'open' and 'onClick' properties.
 */

const CustomIcon = (props: any) => {
  const { open, onClick } = props;

  return (
    <CheveronDownIcon
      height={12}
      width={12}
      onClick={onClick}
      className={`-0 -0 w-4 h-4 absolute top-[-28px] left-[75px] ${
        open ? "transform rotate-180" : ""
      }`}
      preserveAspectRatio="xMidYMid meet"
      style={{ marginRight: "12px", cursor: "pointer" }}
    />
  );
};

const CustomOutlinedInput = styled(OutlinedInput)`
  &:hover:not($disabled):not($focused):not($error) $notchedOutline {
    border-color: transparent;
  }
  &.Mui-focused $notchedOutline {
    border-color: transparent;
  }
  ,
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  ,
  & .MuiSelect-select {
    width: 100px;
    padding: 0 !important;
    position: relative;
    top: -20px;
  }
`;

interface ReviewersDropdownProps {
  name?: string;
  value?: any;
  data?: any[];
  handleChange?: any;
  handleBlur?: any;
}

export const ReviewersDropdown: React.FC<ReviewersDropdownProps> = ({
  name,
  value,
  data,
  handleChange,
}) => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className="flex flex-col flex-grow w-full rounded-lg absolute">
      <div className="relative flex flex-col py-2">
        <FormControl className="">
          <MaterialUiSelect
            className={`w-full observerDropdown h-0 absolute top-[25px] !font-Overpass selectremove_active dark:!bg-[transparent] ${
              !value ? "!text-textLightExtra" : "!text-textNormal"
            }`}
            displayEmpty
            value={value}
            name={name}
            onChange={handleChange}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
            IconComponent={CustomIcon}
            open={open}
            onClick={handleToggle}
            input={<CustomOutlinedInput />}
          >
            {data && data.length > 0 ? (
              data.map((option: any) => (
                <MenuItem
                  key={typeof option === "object" ? option?.id : option}
                  value={typeof option === "object" ? option?.id : option}
                  className="menu_items !text-textNormal !font-Overpass dark:bg-secondaryLight dark:!text-caption break-words"
                  style={{whiteSpace: "break-spaces"}}
                >
                  {typeof option === "object" ? option.name : option}
                </MenuItem>
              ))
            ) : (
              <p className="italic text-sm font-Overpass font-regular text-secondaryMidLight p-2 text-center dark:!text-caption">
                No reviwers found
              </p>
            )}
          </MaterialUiSelect>
        </FormControl>
      </div>
    </div>
  );
};
export default ReviewersDropdown;
