export const RoutesConstants = {
  login: "signIn",
  forgetPassword: "forgetPassword",
  createPassword: "createPassword",
  person: "person",
  account: "account",
  changePassword: "changePassword",
  roles: "roles",
  settings: "settings",
  notifications: "notifications",
  notificationId: ":notificationId",
  deleteAccount: "deleteAccount",
  communities: "communities",
  list: "list",
  communityId: ":communityId",
  users: "users",
  organizations: "organizations",
  organizationsId: ":organizationId",
  profile: "profile",
  programs: "programs",
  programId: ":programId",
  manageMember: "manageMembers",
  files: "files",
  protocolId: ":protocolId",
  geography: "geography",
  dataAccessPolicy: "dataAccessPolicy/:dapId",
  management: "management",
  observations: "observations",
  observationId: ":observationId",
  messages: "messages",
  markerGroups: "marker-groups",
  markerStyles: "marker-styles",
  qualityAssuranceId: ":qualityAssuranceId",
  recipients: "recipients",
  specieId: ":specieId",
  objectId: ":objectId",
  addNotification: "add",
  editNotification: "edit",
  addImport: "add",
  status: "status",
  dashboard: "dashboard",
  reportsId: ":reportsId",
  summary: "summary",
  addReport: "add",
  editReport: "edit",
  result: "result",
  analytics: "analytics",
  versionId: ":versionId",
  stystemSettings: "system-settings",
  viewImportsData: "view-data/:importId",
  runReport: "run-report",
  reportVersion: "report-version/:versionId/result",
  editSurveyId: ":surveyId/edit",
  submitSurveyId: ":surveyId",
  viewSurveyResults: ":pollId/view-results",
  memberRequest: "members-request",
  brands: "brands",
  addBrand: "add",
  brandId: ":brandId",
  editBrand: "edit",
  viewBrand: "brand",
  default: "/",
};
