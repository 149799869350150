import { Dispatch } from "redux";
import {
    ERROR_LOADING_BRAND,
    GET_BRAND,
    RESET_DATA,
    START_LOADING_BRAND,
    STOP_LOADING_BRAND,
} from "./action-types";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";

export const fetchSingleBrandAction =
    (brandId: string) => async (dispatch: Dispatch, getState: any) => {

        dispatch({ type: START_LOADING_BRAND });
        try {
            const { data } = await apiLibrary.Brands.getBrand(brandId);
            dispatch({
                type: GET_BRAND,
                payload: data,
            });
        } catch (error: any) {
            const errorMsg = error?.response?.data?.message ?? error?.message;
            Toasts.error(errorMsg);
            dispatch({
                type: RESET_DATA,
            });
            console.log("An error occurred while fetching the brand: ", error);
            dispatch({ type: ERROR_LOADING_BRAND });
            // dispatch(fetchError(error))
        } finally {
            dispatch({ type: STOP_LOADING_BRAND });
            // dispatch(loadingState(false))
        }
    };

export const resetBrandDetails = (): { type: string } => {
    return {
        type: RESET_DATA,
    };
};
